import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Modal, Dropdown } from "react-bootstrap";
import Detaildatatable from "./DataTable/Detaildatatable";
import axios from "axios";
import { Api_Url } from "../../utils/ApiUrl";
import { CopyToClipboard } from "react-copy-to-clipboard";

const ViewDetail = ({
  setRoutes,
  user,
  userDel,
  setUserDel,
  setShow,
  setBlockUnblock,
  blockUnblock,
  tomiPrice,
  usdPrice,
  btcPrice,
  pepePrice,
  linkPrice,
  uniPrice,
  dopPrice,
  gemsPrice,
  privateSaleFilter,
}) => {
  console.log("user del: ", userDel);

  const history = useHistory();
  const [loader, setLoader] = useState(false);

  const [transactions, setTransactions] = useState([]);
  const [specialAccessData, setSpecialAccessData] = useState([]);

  const [purchases, setPurchases] = useState(null);
  const [leaderscount, setleaderscount] = useState(null);
  const [accessCodeToggle, setAccessCodeToggle] = useState(true);

  const [dops, setDops] = useState(null);
  const [search, setSearch] = useState("");
  let tok = localStorage.getItem("accessToken");
  let project = localStorage.getItem("project");

  // const [usdPrice, setUsdPrice] = useState(0);
  const [page, setPage] = useState(0);
  const [pageCountAgent, setPageCountAgent] = useState(0);

  const [earnings, setEarnings] = useState(null);

  const [agents, setAgents] = useState([]);

  const [offset, setOffset] = useState(1);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(1);
  const [pages, setPages] = useState([]);
  const [code, setCode] = useState([]);
  const [page1, setPage1] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [leaderNames, setLeaderNames] = useState([]);
  const [leaderFilter, setLeaderFilter] = useState("");
  const [roleTypeFilter, setRoleTypeFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryNames, setSearchQueryNames] = useState("");

  const [orderField, setOrderField] = useState("transactionTime");
  const [orderDirection, setOrderDirection] = useState("-1");

  const [orderFieldAgents, setOrderFieldAgents] = useState("createdAt");
  const [orderDirectionAgents, setOrderDirectionAgents] = useState("-1");
  const [tabKey, setTabKey] = useState("transactions");

  const [specialAccess, setSpecialAccess] = useState([]);
  const [addSpecialAccess, setAddSpecialAccess] = useState([]);

  const [removeSpecialAccess, setRemoveSpecialAccess] = useState([]);

  const [godLeadersList, setGodLeadersList] = useState([]);
  const [minimumBuy, setMinimumBuy] = useState("");

  const [newWalletAddress, setNewWalletAddress] = useState("");

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);

  const [show123, setShow123] = useState(false);
  const handleClose123 = () => setShow123(false);

  // useEffect(() => {
  //   if (userDel) {
  //     getDashboardData();
  //   }
  // }, [userDel]);

  const changeMinimumBuy = () => {
    const val = localStorage.getItem("accessToken");

    var config = {
      method: "patch",
      url: `${Api_Url}/users/${userDel?._id}/buying-limit`,

      headers: {
        Authorization: "Bearer " + val,
      },
      data: {
        leaderRole: userDel?.role,
        minimumBuy: minimumBuy,
      },
    };
    axios(config)
      .then(function (response) {
        getData();
        handleClose123();
      })
      .catch(function (error) {
        // toast.error(error?.response?.data?.message);
        if (error?.response?.status == 401) {
          // localStorage.removeItem("accessToken");
          // history.push("/");
        }
      });
  };

  const specialAccessList = () => {
    const val = localStorage.getItem("accessToken");

    var config = {
      method: "get",
      url: `${Api_Url}/users/god-leaders?limit=1000`,

      headers: {
        Authorization: "Bearer " + val,
      },
    };
    axios(config)
      .then(function (response) {
        setGodLeadersList(response?.data?.data);
      })
      .catch(function (error) {
        // toast.error(error?.response?.data?.message);
        if (error?.response?.status == 401) {
          // localStorage.removeItem("accessToken");
          // history.push("/");
        }
      });
  };

  const editSpecialAccessList = () => {
    setLoader(true);
    const val = localStorage.getItem("accessToken");

    var config = {
      method: "patch",
      url: `${Api_Url}/users/${userDel?._id}/god-leader`,
      data: {
        role: userDel?.role,
        addSpecialAccess: addSpecialAccess,
        removeSpecialAccess: removeSpecialAccess,
      },
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    axios(config)
      .then(function (response) {
        getData();
        handleClose1();
        setAddSpecialAccess([]);
        setRemoveSpecialAccess([]);
        setLoader(false);
      })
      .catch(function (error) {
        // toast.error(error?.response?.data?.message);
        setLoader(false);
        if (error?.response?.status == 401) {
          // localStorage.removeItem("accessToken");
          // history.push("/");
        }
      });
  };

  const getDashboardData = () => {
    var config = {
      method: "get",
      url: `${Api_Url}/users/${userDel?._id}/admin/leaders?limit=15&offset=1&&role=${userDel?.role}`,

      headers: {
        Authorization: "Bearer " + tok,
      },
    };

    let dynamicQuery = config.url;

    if (search) {
      dynamicQuery += `&by=${search}`;
    }

    config.url = dynamicQuery;
    axios(config)
      .then(function (res) {
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
      });
  };

  const toggleAccessCode = async () => {
    var config = {
      method: "patch",
      url: `${Api_Url}/users/${userDel?._id}/toggle-access-code`,

      headers: {
        Authorization: "Bearer " + tok,
      },
    };

    await axios(config)
      .then(function (res) {
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
      });
  };

  const handlePageChange = (e) => {
    const selectedPage = e.selected;

    setPage1(selectedPage);
  };

  const getTransactionsData = () => {
    var config = {
      method: "post",
      url: `${Api_Url}/referral/admin/transactions`,

      data: {
        limit: 10,
        offset: page + 1,
        referralWalletAddress: userDel?.referralWalletAddress,
        ...(roleTypeFilter && { leaderType: roleTypeFilter }),
        // id: userDel?._id,

        ...(privateSaleFilter?.length > 0 && {
          privateSaleFilter: privateSaleFilter,
        }),

        ...(search && { searchFilter: search }),

        orderField: orderField,
        orderDirection: orderDirection,
      },

      headers: {
        Authorization: "Bearer " + tok,
      },
    };

    axios(config)
      .then(function (res) {
        // console.log("trx data: ", res?.data?.data?.transactions);
        setTransactions(res?.data?.data?.alltransactions);
        setPageCount(res?.data?.data?.pages);
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
      });
  };

  const handleAgentsPageChange = (e) => {
    const selectedPage = e.selected;

    setPage(selectedPage);
  };

  const getData = async () => {
    var config = {
      method: "post",
      url: `${Api_Url}/users/admin/leader`,
      data: {
        id: userDel?._id,
        ...(privateSaleFilter?.length > 0 && {
          privateSaleFilter: privateSaleFilter,
        }),
      },
      headers: {
        authorization: `Bearer ` + tok,
      },
    };
    axios(config)
      .then(function (response) {
        // console.log("user coount eth", response);
        const resData = response?.data?.data;
        setleaderscount(resData);
        setMinimumBuy(resData?.minimumBuy);
        setAccessCodeToggle(resData?.accessCodeEnabled);
        setSpecialAccess(resData?.specialAccess);
      })
      .catch(function (error) {});
  };

  // useEffect(() => {
  //   if (userDel) {
  //     getData();
  //   }
  // }, [userDel]);

  function convertToTitleCase(input) {
    if (input) {
      let words = input
        .split("_")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );
      if (words[0] === "mini" || words[0] === "Mini") {
        return "Ambassador";
      }
      if (
        words[words.length - 1].toLowerCase() === "agent" &&
        (words[0] != "mini" || words[0] != "Mini")
      ) {
        words[words.length - 1] = "Leader";
      }
      if (words[0] === "super" || words[0] === "Super") {
        return "Super";
      } else if (words[0] !== "agent") {
        return words.join(" ");
      } else {
        return "Leader";
      }
    } else {
      return "";
    }
  }

  useEffect(() => {
    if (usdPrice) {
      getTransactionsData();
    }
  }, [
    usdPrice,
    userDel,
    search,
    orderDirection,
    orderField,
    tabKey,
    page1,
    roleTypeFilter,
  ]);

  const [copy, setCopy] = useState(false);
  const [copy1, setCopy1] = useState(false);
  const [copy2, setCopy2] = useState(false);

  const textCopiedFun1 = (index) => {
    setCopy1(index);

    setTimeout(() => {
      setCopy1(false);
    }, 1000);
  };

  const textCopiedFun = (index) => {
    setCopy(index);

    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };

  const textCopiedFun2 = (index) => {
    setCopy2(index);

    setTimeout(() => {
      setCopy2(false);
    }, 1000);
  };
  return (
    <>
      <section className="viewdetailmain">
        <div className="custom-container">
          <button
            onClick={() => {
              setRoutes(false);
              setUserDel(null);
            }}
            className="btn-back mb-3"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
            >
              <path
                d="M7.88916 1.93555L5.38776 1.93555L1.99471 7.99682L4.49612 7.99682L7.88916 1.93555Z"
                fill="#FFF"
              />
              <path
                d="M7.88916 14.0703L5.38776 14.0703L1.99471 8.00904L3.5712 6.96117L4.49612 8.00904L7.88916 14.0703Z"
                fill="#FFF"
              />
              <path
                d="M8.01642 6.85742H14.9615L13.6868 9.14313H6.7417L8.01642 6.85742Z"
                fill="#FFF"
              />
            </svg>
            Back
          </button>
          <div className="bottom-cards">
            <div className="single-card">
              <img
                src="/assets/dashboard/noofpurchases.svg"
                alt="img"
                className="img-fluid"
              />
              <div className="single-card-texts">
                <p>Purchases </p>

                <h6>
                  {userDel?.dopPurchased?.toFixed(4)}
                  {/* <span className="greentext">
                    +2.3%
                  </span> */}
                </h6>
              </div>
            </div>
            <div className="single-card">
              <img
                src="/assets/dashboard/doppurchasesss.svg"
                alt="img"
                className="img-fluid"
              />
              <div className="single-card-texts">
                <p>Earnings </p>

                <h6>
                  ${userDel?.referralComissionUsd?.toFixed(4)}
                  {/* <span className="greentext">+2.3%</span> */}
                </h6>
              </div>
            </div>
            <div className="single-card">
              <img
                src="/assets/dashboard/netrevenue.svg"
                alt="img"
                className="img-fluid"
              />
              <div className="single-card-texts">
                <p>Total Sales </p>

                <h6>
                  {userDel?.totalSaleConverted?.toFixed(4)}
                  {/* 1,500,000 */}
                  {/* <span className="greentext">+0.8%</span> */}
                </h6>
              </div>
            </div>
          </div>
          <div className="parent-new-card">
            <div className="main-headingg">
              <h6>General Information</h6>
            </div>
            <div className="bottom-cards1">
              <div className="inner-text">
                <p>Email</p>
                <CopyToClipboard
                  text={userDel?.referral[0]?.email}
                  onCopy={(e) => textCopiedFun(true)}
                >
                  <h6>
                    {userDel?.referral[0]?.email}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ cursor: "pointer" }}
                      className={copy && "copy-button "}
                      width="15"
                      height="16"
                      viewBox="0 0 15 16"
                      fill="none"
                    >
                      <path
                        d="M9.41377 8.49972L9.44015 10.9496C9.46213 12.9911 8.65431 13.8165 6.61276 13.8385L4.1629 13.8649C2.12135 13.8869 1.29594 13.0791 1.27396 11.0375L1.24757 8.58766C1.22559 6.54611 2.03342 5.7207 4.07496 5.69871L6.52482 5.67233C8.56637 5.65035 9.39178 6.45817 9.41377 8.49972Z"
                        stroke="#77868B"
                        stroke-width="1.2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12.8759 4.96213L12.9023 7.41198C12.9243 9.45353 12.1165 10.2789 10.0749 10.3009L9.43329 10.3078L9.41382 8.49961C9.39183 6.45806 8.56642 5.65024 6.52487 5.67222L4.71664 5.69169L4.70973 5.05006C4.68775 3.00851 5.49557 2.1831 7.53712 2.16112L9.98698 2.13474C12.0285 2.11275 12.8539 2.92058 12.8759 4.96213Z"
                        stroke="#77868B"
                        stroke-width="1.2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    {copy === true && <p className="copy-text">Copied</p>}
                  </h6>
                </CopyToClipboard>
              </div>
              <div className="inner-text">
                <p>Wallet Address</p>
                <CopyToClipboard
                  text={userDel?.referralWalletAddress}
                  onCopy={(e) => textCopiedFun1(true)}
                >
                  <h6>
                    {userDel?.referralWalletAddress?.slice(0, 6)}...
                    {userDel?.referralWalletAddress?.slice(
                      userDel?.referralWalletAddress?.length - 5,
                      userDel?.referralWalletAddress?.length
                    )}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ cursor: "pointer" }}
                      className={copy1 && "copy-button "}
                      width="15"
                      height="16"
                      viewBox="0 0 15 16"
                      fill="none"
                    >
                      <path
                        d="M9.41377 8.49972L9.44015 10.9496C9.46213 12.9911 8.65431 13.8165 6.61276 13.8385L4.1629 13.8649C2.12135 13.8869 1.29594 13.0791 1.27396 11.0375L1.24757 8.58766C1.22559 6.54611 2.03342 5.7207 4.07496 5.69871L6.52482 5.67233C8.56637 5.65035 9.39178 6.45817 9.41377 8.49972Z"
                        stroke="#77868B"
                        stroke-width="1.2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12.8759 4.96213L12.9023 7.41198C12.9243 9.45353 12.1165 10.2789 10.0749 10.3009L9.43329 10.3078L9.41382 8.49961C9.39183 6.45806 8.56642 5.65024 6.52487 5.67222L4.71664 5.69169L4.70973 5.05006C4.68775 3.00851 5.49557 2.1831 7.53712 2.16112L9.98698 2.13474C12.0285 2.11275 12.8539 2.92058 12.8759 4.96213Z"
                        stroke="#77868B"
                        stroke-width="1.2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    {copy1 && <p className="copy-text">Copied</p>}
                  </h6>
                </CopyToClipboard>
              </div>
              <div className="inner-text">
                <p>Referral Link</p>
                {userDel?.referral?.length > 0 && (
                  <CopyToClipboard
                    text={`gems.vip/project/${userDel?.referral[0]?.referralCode}...`}
                    onCopy={(e) => textCopiedFun2(true)}
                  >
                    <h6>
                      gems.vip/project/{userDel?.referral[0]?.referralCode}...
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ cursor: "pointer" }}
                        className={copy2 && "copy-button "}
                        width="15"
                        height="16"
                        viewBox="0 0 15 16"
                        fill="none"
                      >
                        <path
                          d="M9.41377 8.49972L9.44015 10.9496C9.46213 12.9911 8.65431 13.8165 6.61276 13.8385L4.1629 13.8649C2.12135 13.8869 1.29594 13.0791 1.27396 11.0375L1.24757 8.58766C1.22559 6.54611 2.03342 5.7207 4.07496 5.69871L6.52482 5.67233C8.56637 5.65035 9.39178 6.45817 9.41377 8.49972Z"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.8759 4.96213L12.9023 7.41198C12.9243 9.45353 12.1165 10.2789 10.0749 10.3009L9.43329 10.3078L9.41382 8.49961C9.39183 6.45806 8.56642 5.65024 6.52487 5.67222L4.71664 5.69169L4.70973 5.05006C4.68775 3.00851 5.49557 2.1831 7.53712 2.16112L9.98698 2.13474C12.0285 2.11275 12.8539 2.92058 12.8759 4.96213Z"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      {copy2 && <p className="copy-text">Copied</p>}
                    </h6>
                  </CopyToClipboard>
                )}
              </div>
              <div className="inner-text">
                <p>Total Referrals</p>
                <h6>{userDel?.totalReferrals}</h6>
              </div>
            </div>
          </div>
          <div className="innerreferrerheading">
            <div>
              <h6 className="refferhead">All Transactions</h6>
            </div>
            <div className="multi-items">
              <div className="rightside-content">
                <div className="custom-option-field">
                  <Dropdown className="positionset">
                    <Dropdown.Toggle id="dropdown-basic">
                      By Hash
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="7"
                        viewBox="0 0 13 7"
                        fill="none"
                      >
                        <path
                          d="M0 0.5L2.22397e-07 3.0462L6.2 6.5L6.2 3.9538L0 0.5Z"
                          fill="#02171D"
                        />
                        <path
                          d="M12.3984 0.5L12.3984 3.0462L6.19844 6.5L6.19844 3.9538L12.3984 0.5Z"
                          fill="#02171D"
                        />
                      </svg> */}
                    </Dropdown.Toggle>

                    {/* <Dropdown.Menu>
                      <a className="dropdown-item drop">By Referral Link</a>
                      <a className="dropdown-item drop">By Hash</a>
                      <a className="dropdown-item drop">Wallet Address</a>
                    </Dropdown.Menu> */}
                  </Dropdown>
                  <input
                    style={{ paddingLeft: "120px" }}
                    type="search"
                    placeholder="Search..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="19"
                    viewBox="0 0 18 19"
                    fill="none"
                    className="img-fluid search-icon"
                  >
                    <path
                      d="M10.0008 9.23257C9.37556 10.2088 8.2814 10.8558 7.03612 10.8558C5.09321 10.8558 3.51818 9.28077 3.51818 7.33787C3.51818 5.39497 5.09321 3.81994 7.03612 3.81994C8.97902 3.81994 10.5541 5.39497 10.5541 7.33787C10.5541 7.89647 10.4239 8.42465 10.1922 8.89374L12.5736 8.89374C12.7124 8.39889 12.7866 7.87705 12.7866 7.33787C12.7866 4.16198 10.212 1.5874 7.03612 1.5874C3.86022 1.5874 1.28564 4.16198 1.28564 7.33787C1.28564 10.5138 3.86022 13.0883 7.03612 13.0883C8.66134 13.0883 10.1291 12.4141 11.1749 11.3301L10.0008 9.23257Z"
                      fill="#0A2931"
                    />
                    <path
                      d="M14.9076 17.3347L15.636 14.6165L10.0373 9.16457L9.30901 11.8828L14.9076 17.3347Z"
                      fill="#0A2931"
                    />
                  </svg>
                </div>

                <button className="whitnewbtn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                  >
                    <path
                      d="M6 7.8335V11.8335L7.33333 10.5002"
                      stroke="black"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.00008 11.8333L4.66675 10.5"
                      stroke="black"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14.6666 7.16683V10.5002C14.6666 13.8335 13.3333 15.1668 9.99992 15.1668H5.99992C2.66659 15.1668 1.33325 13.8335 1.33325 10.5002V6.50016C1.33325 3.16683 2.66659 1.8335 5.99992 1.8335H9.33325"
                      stroke="black"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14.6666 7.16683H11.9999C9.99992 7.16683 9.33325 6.50016 9.33325 4.50016V1.8335L14.6666 7.16683Z"
                      stroke="black"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Download CSV
                </button>
              </div>
            </div>
          </div>
          <Detaildatatable
            tomiPrice={tomiPrice}
            transactions={transactions}
            setTransactions={setTransactions}
            user={user}
            usdPrice={usdPrice}
            page={page1}
            pageCount={pageCount}
            setOrderDirection={setOrderDirection}
            setOrderField={setOrderField}
          />
        </div>
      </section>
    </>
  );
};

export default ViewDetail;
