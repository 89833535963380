import React, { useState, useEffect } from "react";
import "./datatable.scss";
import { Link } from "react-router-dom";
import tableup from "../../../../assets/tableup.svg";
import tabledown from "../../../../assets/tabledown.svg";
import { Accordion } from "react-bootstrap";
import axios from "axios";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactPaginate from "react-paginate";

const DataTable = ({
  setRoutes,
  setShow,
  agents,
  setAgents,
  pages,
  handlePageChange,
  page,
  pageCount,
  currentPage,
  GetAllRounds,
  getNextData,
  getPrevData,
  setUserDel,
  sub,
  setOrderDirection,
  setOrderField,
  setBlockUnblock,
  blockUnblock,
  tomiPrice,
  usdPrice,
  btcPrice,
  pepePrice,
  linkPrice,
  uniPrice,
  dopPrice,
  gemsPrice,
}) => {
  // const [usdPrice, setUsdPrice] = useState(0);
  const [rend, setRend] = useState(false);

  const [copy, setCopy] = useState(false);
  const [copy2, setCopy2] = useState(false);

  const textCopiedFun = () => {
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };

  const textCopiedFun2 = () => {
    setCopy2(true);
    setTimeout(() => {
      setCopy2(false);
    }, 1000);
  };

  function convertToTitleCase(input) {
    if (input) {
      let words = input
        .split("_")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );

      if (words[0] === "mini" || words[0] === "Mini") {
        return "Ambassador";
      }
      if (
        words[words.length - 1].toLowerCase() === "agent" &&
        (words[0] != "mini" || words[0] != "Mini")
      ) {
        words[words.length - 1] = "Leader";
      }
      if (words[0] === "super" || words[0] === "Super") {
        return "Super";
      } else if (words[0] !== "agent") {
        return words.join(" ");
      } else {
        return "Leader";
      }
    } else {
      return "";
    }
  }

  function convertDateFormat(input) {
    const date = new Date(input);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    return `${day}/${month}/${year}\n${hours}:${minutes} UTC`;
  }

  function formatTokenValue(tokenValue, decimals) {
    const num = parseFloat(tokenValue);
    if (isNaN(num) || num === 0) return null; // Hide zero values

    if (num >= 1000000) {
      return (num / 1000000)?.toFixed(2) + "M";
    } else if (num >= 1000) {
      return (num / 1000)?.toFixed(2) + "K";
    } else {
      return num?.toFixed(decimals); // Apply specific decimal formatting
    }
  }

  return (
    <>
      <section className="datatable">
        <div className="table-responsive foroverflowvisibleclasss">
          <table class="table">
            <thead>
              <tr>
                <th>
                  <div className="parent-tag">
                    Date/Time <div className="filter-btn"></div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Name/Wallet <div className="filter-btn"></div>
                  </div>
                </th>
                {/* <th>
                  <div className="parent-tag">
                    Leader's Email{" "}
                  
                  </div>
                </th> */}
                <th>
                  <div className="parent-tag">
                    Role{" "}
                    {/* <div className="filter-btn">
                      <img src={tableup} alt="img" className="img-fluid" />
                      <img src={tabledown} alt="img" className="img-fluid" />
                    </div> */}
                  </div>
                </th>

                {/* <th>
                  <div className="parent-tag">
                    Plan
                    <div className="filter-btn"></div>
                  </div>
                </th> */}
                <th>
                  <div className="parent-tag">Miners Sold</div>
                </th>
                <th>
                  <div className="parent-tag">Total Sold</div>
                </th>
                <th>
                  <div className="parent-tag">
                    Leader Earning <div className="filter-btn"></div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {agents?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div className="twice-text">
                        <h6>
                          {convertDateFormat(item?.createdAt)?.slice(0, 10)}
                        </h6>
                        <p>
                          {convertDateFormat(item?.createdAt)?.slice(12, 20)}
                        </p>
                      </div>
                    </td>
                    <td>
                      {item?.name}
                      <div className="walletaddresspara">
                        <p>
                          {copy ? (
                            <span className="adadasdasdasdasdasdsad">
                              {item?.walletAddress?.slice(0, 8)}...
                              {item?.walletAddress?.slice(
                                item?.walletAddress?.length - 5,
                                item?.walletAddress?.length
                              )}{" "}
                              <svg
                                style={{ cursor: "pointer" }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                className={copy === index && "copy-button "}
                              >
                                <path
                                  d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                                  stroke="#77868B"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                                  stroke="#77868B"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              {copy === index && (
                                <p className="copy-text">Copied</p>
                              )}
                            </span>
                          ) : (
                            <>
                              {item?.walletAddress && (
                                <>
                                  <CopyToClipboard
                                    text={item?.walletAddress}
                                    onCopy={(e) => textCopiedFun(index)}
                                  >
                                    <span className="adadasdasdasdasdasdsad">
                                      {item?.walletAddress?.slice(0, 8)}...
                                      {item?.walletAddress?.slice(
                                        item?.walletAddress?.length - 5,
                                        item?.walletAddress?.length
                                      )}{" "}
                                      <svg
                                        style={{ cursor: "pointer" }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        className={
                                          copy === index && "copy-button "
                                        }
                                      >
                                        <path
                                          d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                                          stroke="#77868B"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                                          stroke="#77868B"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </span>
                                  </CopyToClipboard>
                                  {copy === index && (
                                    <p className="copy-text">Copied</p>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </p>
                      </div>
                    </td>
                    {/* <td>{item?.email}</td> */}
                    <td>{convertToTitleCase(item?.role)}</td>

                    {/* <td>{item?.UserPremiumPlan ? "Premium" : "Basic"} </td> */}
                    <td>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        {parseFloat(
                          item?.summedArray[0] +
                            item?.summedArray[1] +
                            item?.summedArray[2]
                        )?.toLocaleString()}
                        <div class="dropdown dropdownnodesminers">
                          <button
                            class="dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="19"
                              viewBox="0 0 18 19"
                              fill="none"
                            >
                              <path
                                d="M9 17.249C13.1421 17.249 16.5 13.8912 16.5 9.74902C16.5 5.60689 13.1421 2.24902 9 2.24902C4.85786 2.24902 1.5 5.60689 1.5 9.74902C1.5 13.8912 4.85786 17.249 9 17.249Z"
                                stroke="#77868B"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M9 12.749V9.74902"
                                stroke="#77868B"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M9 6.74902H9.00833"
                                stroke="#77868B"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </button>
                          <ul
                            class="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <div className="twice">
                              <p>Mini</p>
                              <h6>{item?.summedArray[0]}</h6>
                            </div>
                            <div className="twice">
                              <p>Standard</p>
                              <h6>{item?.summedArray[1]}</h6>
                            </div>
                            <div className="twice">
                              <p>Mega</p>
                              <h6>{item?.summedArray[2]}</h6>
                            </div>
                          </ul>
                        </div>
                      </div>
                    </td>

                    <td>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        ${formatTokenValue(item?.totalSaleConverted, 2)}
                        <div class="dropdown dropdownnodesminers">
                          <button
                            class="dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="19"
                              viewBox="0 0 18 19"
                              fill="none"
                            >
                              <path
                                d="M9 17.249C13.1421 17.249 16.5 13.8912 16.5 9.74902C16.5 5.60689 13.1421 2.24902 9 2.24902C4.85786 2.24902 1.5 5.60689 1.5 9.74902C1.5 13.8912 4.85786 17.249 9 17.249Z"
                                stroke="#77868B"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M9 12.749V9.74902"
                                stroke="#77868B"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M9 6.74902H9.00833"
                                stroke="#77868B"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </button>
                          <ul
                            class="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <div className="py-2">
                              {item?.purchaseInEth != 0 ? (
                                <div className="twice">
                                  <p>ETH</p>
                                  <h6>
                                    {item?.purchaseInEth
                                      ? parseFloat(item.purchaseInEth).toFixed(
                                          5
                                        )
                                      : "0"}
                                  </h6>
                                </div>
                              ) : null}
                              {item?.purchaseInUsdt != 0 ? (
                                <div className="twice">
                                  <p>USDT</p>
                                  <h6>
                                    {item?.purchaseInUsdt
                                      ? parseFloat(item.purchaseInUsdt).toFixed(
                                          2
                                        )
                                      : "0"}
                                  </h6>
                                </div>
                              ) : null}
                              {item?.purchaseInUsdc != 0 ? (
                                <div className="twice">
                                  <p>USDC</p>
                                  <h6>
                                    {item?.purchaseInUsdc
                                      ? parseFloat(item.purchaseInUsdc).toFixed(
                                          2
                                        )
                                      : "0"}
                                  </h6>
                                </div>
                              ) : null}
                              {/* {item?.purchaseInWbtc != 0 ? (
                                  <div className="twice">
                                    <p>WBTC</p>
                                    <h6>
                                      {item?.purchaseInWbtc
                                        ? parseFloat(item.purchaseInWbtc).toFixed(6)
                                        : "0"}
                                    </h6>
                                  </div>
                                ) : null} */}
                              {item?.purchaseInGems != 0 ? (
                                <div className="twice">
                                  <p>GEMS</p>
                                  <h6>
                                    {item?.purchaseInGems
                                      ? parseFloat(item.purchaseInGems).toFixed(
                                          1
                                        )
                                      : "0"}
                                  </h6>
                                </div>
                              ) : null}
                            </div>

                            {/* <a
                                style={{
                                  color: "#329879",
                                  justifyContent: "center",
                                }}
                                onClick={() => {
                                  setModalDetail(item);
                                  setShow1(true);
                                }}
                              >
                                View All
                              </a> */}
                          </ul>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        ${formatTokenValue(item?.myEarningConverted, 2)}
                        <div class="dropdown dropdownnodesminers">
                          <button
                            class="dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="19"
                              viewBox="0 0 18 19"
                              fill="none"
                            >
                              <path
                                d="M9 17.249C13.1421 17.249 16.5 13.8912 16.5 9.74902C16.5 5.60689 13.1421 2.24902 9 2.24902C4.85786 2.24902 1.5 5.60689 1.5 9.74902C1.5 13.8912 4.85786 17.249 9 17.249Z"
                                stroke="#77868B"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M9 12.749V9.74902"
                                stroke="#77868B"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M9 6.74902H9.00833"
                                stroke="#77868B"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </button>
                          <ul
                            class="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <div className="py-2">
                              {item?.leaderEarningInEth != 0 ? (
                                <div className="twice">
                                  <p>ETH</p>
                                  <h6>
                                    {item?.leaderEarningInEth
                                      ? parseFloat(
                                          item.leaderEarningInEth
                                        ).toFixed(5)
                                      : "0"}
                                  </h6>
                                </div>
                              ) : null}
                              {item?.leaderEarningInUsdt != 0 ? (
                                <div className="twice">
                                  <p>USDT</p>
                                  <h6>
                                    {item?.leaderEarningInUsdt
                                      ? parseFloat(
                                          item.leaderEarningInUsdt
                                        ).toFixed(2)
                                      : "0"}
                                  </h6>
                                </div>
                              ) : null}
                              {item?.leaderEarningInUsdc != 0 ? (
                                <div className="twice">
                                  <p>USDC</p>
                                  <h6>
                                    {item?.leaderEarningInUsdc
                                      ? parseFloat(
                                          item.leaderEarningInUsdc
                                        ).toFixed(2)
                                      : "0"}
                                  </h6>
                                </div>
                              ) : null}
                              {/* {item?.leaderEarningInWbtc != 0 ? (
                                  <div className="twice">
                                    <p>WBTC</p>
                                    <h6>
                                      {item?.leaderEarningInWbtc
                                        ? parseFloat(
                                            item.leaderEarningInWbtc
                                          ).toFixed(6)
                                        : "0"}
                                    </h6>
                                  </div>
                                ) : null} */}
                              {item?.leaderEarningInGems != 0 ? (
                                <div className="twice">
                                  <p>GEMS</p>
                                  <h6>
                                    {item?.leaderEarningInGems
                                      ? parseFloat(
                                          item.leaderEarningInGems
                                        ).toFixed(1)
                                      : "0"}
                                  </h6>
                                </div>
                              ) : null}
                            </div>

                            {/* <a
                                style={{
                                  color: "#329879",
                                  justifyContent: "center",
                                }}
                                onClick={() => {
                                  setModalDetail(item);
                                  setShow2(true);
                                }}
                              >
                                View All
                              </a> */}
                          </ul>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          {sub != true ? (
            <div className="footer-content mt-2">
              <div className="left-f">
                {/* <h6>SHOWING 1-10 OF 145</h6> */}
                {/* <h6>SHOWING 1-10 OF 145</h6> */}
              </div>
              <div className="right-f">
                <nav aria-label="Page navigation example">
                  <ul class="pagination">
                    <nav aria-label="Page navigation example">
                      <ul class="text-center">
                        <ReactPaginate
                          previousLabel={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                            >
                              <path
                                d="M10 13L5 8L10 3"
                                stroke="#8C8C8C"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          }
                          nextLabel={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                            >
                              <path
                                d="M5 13L10 8L5 3"
                                stroke="#8C8C8C"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          }
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={handlePageChange}
                          containerClassName="pagination"
                          activeClassName="active"
                          forcePage={page}
                        ></ReactPaginate>
                      </ul>
                    </nav>
                  </ul>
                </nav>
              </div>
            </div>
          ) : null}
        </div>
        <div className="mobiledesignss  d-none">
          {agents?.map((item, index) => {
            return (
              <div className="new-accordionmobile dsuygfdsyufgsdyuf d-none">
                <div className="upper-body">
                  <div className="inner-text">
                    <h6>Date and time</h6>
                    <p> {convertDateFormat(item?.createdAt)?.slice(0, 10)}</p>
                  </div>
                  <div className="inner-text">
                    <h6>Leader</h6>
                    <p>{item?.name}</p>
                  </div>
                  <div className="inner-text">
                    <h6>Role</h6>
                    <p>{convertToTitleCase(item?.role)}</p>
                  </div>
                  <div className="inner-text">
                    <h6>Wallet Address</h6>
                    {copy ? (
                      <p className="adadasdasdasdasdasdsad">
                        {item?.walletAddress?.slice(0, 11)}...
                        {item?.walletAddress?.slice(
                          item?.walletAddress?.length - 3,
                          item?.walletAddress?.length
                        )}
                        <svg
                          style={{ cursor: "pointer" }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          className=""
                        >
                          <path
                            d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </p>
                    ) : (
                      <>
                        {item?.walletAddress && (
                          <CopyToClipboard
                            text={item?.walletAddress}
                            onCopy={textCopiedFun2}
                          >
                            <p className="adadasdasdasdasdasdsad">
                              {item?.walletAddress?.slice(0, 11)}...
                              {item?.walletAddress?.slice(
                                item?.walletAddress?.length - 3,
                                item?.walletAddress?.length
                              )}
                              <svg
                                style={{ cursor: "pointer" }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                className=""
                              >
                                <path
                                  d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                                  stroke="white"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                                  stroke="white"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </p>
                          </CopyToClipboard>
                        )}
                      </>
                    )}
                  </div>
                  {/* <div className="inner-text">
                    <h6>Wallet Address</h6>
                    <p className="textright">
                      {item?.walletAddress?.slice(0, 11)}...
                      {item?.walletAddress?.slice(
                        item?.walletAddress?.length - 3,
                        item?.walletAddress?.length
                      )}
                      <img
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigator.clipboard.writeText(item?.walletAddress);
                        }}
                        src="\assets\copy.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </p>
                  </div> */}
                </div>
                <div className="bottom-body">
                  <Accordion>
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>
                        More Info{" "}
                        <img
                          src="\assets\arrow-down.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="inner-textt">
                          <p>Tokens Purchased</p>
                          {item?.dopPurchased ? (
                            <h6 className="textright">
                              {item?.dopPurchased?.toFixed(4)} Tokens
                            </h6>
                          ) : (
                            <h6 className="textright">{0} Tokens</h6>
                          )}
                        </div>
                        <div className="inner-textt">
                          <p>Leader Earnings</p>

                          <div className="twice-text">
                            <h6>${item?.myEarningConverted?.toFixed(4)}</h6>
                            <p>
                              (
                              {item?.leaderEarningInEth
                                ? item?.leaderEarningInEth?.toFixed(5)
                                : 0}{" "}
                              ETH +{" "}
                              {item?.leaderEarningInUsdt
                                ? item?.leaderEarningInUsdt?.toFixed(2)
                                : 0}{" "}
                              USDT +{" "}
                              {item?.leaderEarningInDopToken
                                ? item?.leaderEarningInDopToken?.toFixed(4)
                                : 0}{" "}
                              DOP +{" "}
                              {item?.leaderEarningInWbtc
                                ? item?.leaderEarningInWbtc?.toFixed(4)
                                : 0}{" "}
                              WBTC +{" "}
                              {item?.leaderEarningInUsdc
                                ? item?.leaderEarningInUsdc?.toFixed(2)
                                : 0}{" "}
                              USDC +{" "}
                              {item?.leaderEarningInPepe
                                ? item?.leaderEarningInPepe?.toFixed(2)
                                : 0}{" "}
                              PEPE +{" "}
                              {item?.leaderEarningInLink
                                ? item?.leaderEarningInLink?.toFixed(2)
                                : 0}{" "}
                              LINK +{" "}
                              {item?.leaderEarningInUni
                                ? item?.leaderEarningInUni?.toFixed(2)
                                : 0}{" "}
                              UNI +{" "}
                              {item?.leaderEarningInTomi
                                ? item?.leaderEarningInTomi?.toFixed(2)
                                : 0}{" "}
                              TOMI +{" "}
                              {item?.leaderEarningInGems
                                ? item?.leaderEarningInGems?.toFixed(2)
                                : 0}{" "}
                              GEMS)
                            </p>
                          </div>
                        </div>
                        {/* totalPurchaseUsdt */}
                        <div className="inner-textt">
                          <p>Total Purchases</p>
                          {item?.totalSaleConverted ? (
                            <div className="twice-text">
                              <h6>${item?.totalSaleConverted?.toFixed(4)}</h6>
                              <p>
                                ({item?.purchaseInEth?.toFixed(5)} ETH +{" "}
                                {item?.purchaseInUsdt?.toFixed(2)} USDT +{" "}
                                {item?.purchaseInDopToken?.toFixed(4)} DOP +{" "}
                                {item?.purchaseInWbtc?.toFixed(4)} WBTC +{" "}
                                {item?.purchaseInUsdc?.toFixed(2)} USDC +{" "}
                                {item?.purchaseInPepe?.toFixed(2)} PEPE +{" "}
                                {item?.purchaseInLink?.toFixed(2)} LINK +{" "}
                                {item?.purchaseInUni?.toFixed(2)} UNI +{" "}
                                {item?.purchaseInTomi?.toFixed(2)} TOMI +{" "}
                                {item?.purchaseInGems?.toFixed(2)} GEMS)
                              </p>
                            </div>
                          ) : (
                            <div className="twice-text">
                              <>
                                <h6>$0</h6>
                                <p>
                                  (0 ETH + 0 USDT + 0 WBTC + 0 USDC + 0 PEPE + 0
                                  LINK + 0 UNI + 0 TOMI + 0 GEMS)
                                </p>
                              </>
                            </div>
                          )}
                        </div>
                        {/* {sub != true ? (
                          <div className="inner-textt">
                            <button
                              onClick={() => {
                                setRoutes(true);
                                setUserDel(item);
                                setBlockUnblock(item?.isDeleted);
                              }}
                              className="view-btn"
                            >
                              View Details
                            </button>
                          </div>
                        ) : null} */}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
              // <Accordion.Item key={index} eventKey={index}>
              //   <Accordion.Header>
              //     <div className="accheadermain">
              //       <p className="acctext">
              //         {convertDateFormat(item?.createdAt)?.slice(0, 10)}
              //       </p>
              //       <p className="acctext">{item?.name}</p>
              //       <p className="acctext">
              //         {convertToTitleCase(item?.role)}
              //       </p>
              //     </div>
              //   </Accordion.Header>
              //   <Accordion.Body>
              //     <div className="acctexts">
              //       <h6 className="textleft">Wallet Address</h6>
              //       <p className="textright">
              //         {item?.walletAddress?.slice(0, 11)}...
              //         {item?.walletAddress?.slice(
              //           item?.walletAddress?.length - 3,
              //           item?.walletAddress?.length
              //         )}
              //         <img
              //           style={{ cursor: "pointer" }}
              //           onClick={() => {
              //             navigator.clipboard.writeText(item?.walletAddress);
              //           }}
              //           src="\assets\copy.svg"
              //           alt="img"
              //           className="img-fluid"
              //         />
              //       </p>
              //     </div>
              //     <div className="acctexts">
              //       <h6 className="textleft">DOP Purchased</h6>
              //       <p className="textright">{item?.totalDop} DOP</p>
              //     </div>
              //     <div className="acctexts">
              //       <h6 className="textleft">Leader Earning</h6>
              //       <div className="twice-text">
              //         <h6>
              //           $
              //           {(
              //             item?.myEarningsEth * usdPrice +
              //             item?.myEarningsUsdt
              //           )?.toFixed(4)}
              //         </h6>
              //         <p>
              //           ({item?.myEarningsEth} ETH + {item?.myEarningsUsdt}{" "}
              //           USDT)
              //         </p>
              //       </div>
              //     </div>
              //     <div className="acctexts">
              //       <h6 className="textleft">Total Purchases</h6>
              //       <div className="twice-text">
              //         <h6>
              //           {(
              //             item?.totalEth * usdPrice +
              //             item?.totalUsdt
              //           )?.toFixed(4)}{" "}
              //         </h6>
              //         <p>
              //           ({item?.totalEth} ETH + {item?.totalUsdt} USDT){" "}
              //         </p>
              //       </div>
              //     </div>
              //     <div className="acctexts">
              //       <button
              //          onClick={() => {
              //           setRoutes(true);
              //           setUserDel(item);
              //         }}
              //         className="view-btn"
              //       >
              //         View Details
              //       </button>
              //     </div>
              //   </Accordion.Body>
              // </Accordion.Item>
            );
          })}
          {sub != true ? (
            <div className="footer-content mt-2">
              <div className="left-f">
                {/* <h6>SHOWING 1-10 OF 145</h6> */}
                {/* <h6>SHOWING 1-10 OF 145</h6> */}
              </div>
              <div className="right-f">
                <nav aria-label="Page navigation example">
                  <ul class="pagination">
                    <nav aria-label="Page navigation example">
                      <ul class="text-center">
                        <ReactPaginate
                          previousLabel={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                            >
                              <path
                                d="M10 13L5 8L10 3"
                                stroke="#8C8C8C"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          }
                          nextLabel={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                            >
                              <path
                                d="M5 13L10 8L5 3"
                                stroke="#8C8C8C"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          }
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={handlePageChange}
                          containerClassName="pagination"
                          activeClassName="active"
                          forcePage={page}
                        ></ReactPaginate>
                      </ul>
                    </nav>
                  </ul>
                </nav>
              </div>
            </div>
          ) : null}
        </div>
      </section>
    </>
  );
};

export default DataTable;
