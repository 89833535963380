import React, { useEffect, useState } from "react";
import DataTable from "./DataTable/DataTable";
import { Dropdown, Modal } from "react-bootstrap";
import axios from "axios";
import { Api_Url } from "../../../utils/ApiUrl";
import Web3 from "web3";
import { toast } from "react-toastify";
import Loader from "../../../hooks/loader";
import useWeb3 from "../../../hooks/useWeb3";
import { getERC20Token } from "../../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
// import { getdoproundcreatecontract } from "../../../utils/contractHelpers";
import { getPreSaleContract } from "../../../utils/contractHelpers";
import {
    getinstitutionpresalecontract,
    getLEIAInstitutionPreSaleContract,
    getOlympusInstitutionPreSaleContract,
} from "../../../utils/contractHelpers";
import { getLEIAPreSaleContract } from "../../../utils/contractHelpers";
import {
    getOlympusPreSaleContract,
    getNFAPreSaleContract,
    getAstroNovaPreSaleContract,
} from "../../../utils/contractHelpers";

import Environment from "../../../utils/Environment";
import TimerNFT from "../../../hooks/dataFetchers/timerNft";

const UserManagementSetting = () => {
    const val = localStorage.getItem("accessToken");
    const { account } = useWeb3React();
    const { timerNFT } = TimerNFT();
    const web3 = useWeb3();
    const [project, setProject] = useState("ASTRANOVA");
    const [selectedItem, setSelectedItem] = useState({
        imgSrc: "/assets/astronova.svg",
        text: "ASTRANOVA",
    });

    const [loader, setLoader] = useState(false);

    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        setTokenDecimals("");
        setTokenName("");
        setTokenPriceFeed("");
        setNormalisationFactorNFT("");
        setNormalisationFactorToken("");
        setTokenSymbol("");
        setContractAddress("");
    };
    const handleShow = () => {
        setShow(true);
    };

    const [show1, setShow1] = useState(false);

    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const [tokenData, setTokenData] = useState([]);
    const [search, setSearch] = useState("");

    const [orderField, setOrderField] = useState("");
    const [orderDirection, setOrderDirection] = useState("");
    const [statusFilter, setStatusFilter] = useState("");

    const [contractAddress, setContractAddress] = useState([]);
    const [tokenName, setTokenName] = useState("");

    const [tokenSymbol, setTokenSymbol] = useState("");
    const [tokenDecimals, setTokenDecimals] = useState("");
    const [slippageTolerance, setSlippageTolerance] = useState("");
    const [normalisationFactorNFT, setNormalisationFactorNFT] = useState("");
    const [normalisationFactorToken, setNormalisationFactorToken] = useState("");
    const [tokenPriceFeed, setTokenPriceFeed] = useState("");
    const [tolerance, setTolerance] = useState("");

    const [errors, setErrors] = useState({});

    const handleItemClick = (imgSrc, text) => {
        setSelectedItem({ imgSrc, text });
        setProject(text);
    };

    const handleContractChange = async (e) => {
        try {
            let address = [e.target.value];
            setContractAddress(address);
            const contract = getERC20Token(e.target.value, web3);
            const name = await contract.methods.name().call();
            const symbol = await contract.methods.symbol().call();
            const decimals = await contract.methods.decimals().call();
            setTokenName(name);
            setTokenSymbol(symbol);
            setTokenDecimals(decimals);
        } catch (error) {
            throw error;
        }

        // await axios
        //   .get("https://api.coingecko.com/api/v3/coins/list")
        //   .then((response) => {
        //     // Data contains an array of tokens with their details
        //     console.log(response?.data);
        //     // Access each token's name, symbol, and icon using data[i].name, data[i].symbol, data[i].image
        //   })
        //   .catch((error) => console.error("Error fetching data:", error));
    };

    const connectwalletfirst = () => {
        toast.error("Please connect your wallet first ", {
            position: "top-right",
            autoClose: 3000,
        });
    };

    // useEffect(() => {
    //   tokenDataRegistry();
    // }, []);

    // const tokenDataRegistry = async () => {
    //   const tokdata = await timerNFT(
    //     "0x25762231808F040410586504fDF08Df259A2163c"
    //   );
    //   console.log("token data from reg: ", tokdata);
    // };
    useEffect(() => {
        getTokenData();
    }, [search, orderField, orderDirection, statusFilter, project]);

    const getTokenData = () => {
        var config = {
            method: "get",
            url: `${Api_Url}/tokens?limit=25&offset=1&privateSale=${project}`,

            headers: {
                Authorization: "Bearer " + val,
            },
        };
        let dynamicQuery = config.url;

        if (search) {
            dynamicQuery += `&search=${search}`;
        }
        if (orderField) {
            dynamicQuery += `&orderField=${orderField}&orderDirection=${orderDirection}`;
        }
        if (statusFilter == "active") {
            dynamicQuery += `&status=${true}`;
        } else if (statusFilter == "inactive") {
            dynamicQuery += `&status=${false}`;
        }
        config.url = dynamicQuery;
        axios(config)
            .then(function (res) {
                setTokenData(res?.data?.data?.tokens);

                setLoader(false);
            })
            .catch(function (error) {
                setLoader(false);
            });
    };

    const toggleStatus = async (id) => {
        var config = {
            method: "patch",
            url: `${Api_Url}/tokens/${id}/toggle-status`,

            headers: {
                Authorization: "Bearer " + val,
            },
        };

        await axios(config)
            .then(function (res) {
                getTokenData();
                setLoader(false);
            })
            .catch(function (error) {
                setLoader(false);
            });
    };

    const addToken = async () => {
        if (account) {
            if (ValidateForm()) {
                const data = {
                    ...(tokenName && { name: tokenName }),
                    address: contractAddress[0],
                    symbol: tokenSymbol,
                    decimal: tokenDecimals,
                    privateSale: project,
                };
                var config = {
                    method: "post",
                    url: `${Api_Url}/tokens`,
                    data: data,
                    headers: {
                        Authorization: "Bearer " + val,
                    },
                };
                setLoader(true);
                await axios(config)
                    .then((res) => {
                        const resData = res?.data?.data;
                        setPriceFeed();
                        getTokenData();
                    })
                    .catch((err) => {
                        console.log("err response: ", err?.response);
                        if (err?.response?.status == 409) {
                            setPriceFeed();
                            handleClose();
                            handleShow1();
                            // setLoader(false);
                            // toast.error(err?.response?.message);
                        } else {
                            setLoader(false);
                        }
                        toast.error(err?.response?.message);
                    });
            }
        } else {
            connectwalletfirst();
        }
    };

    const setPriceFeed = async () => {
        let contract, contractInstitution;
        if (project == "ASTRANOVA") {
            contract = getAstroNovaPreSaleContract(
                Environment.AstroNovaPreSale,
                web3
            );
            // contractInstitution = getNFAInstitutionPreSaleContract(
            //   Environment.NFAInstitutionPreSale,
            //   web3
            // );
        } else if (project == "NFA") {
            contract = getNFAPreSaleContract(Environment.NFAPreSale, web3);
            // contractInstitution = getNFAInstitutionPreSaleContract(
            //   Environment.NFAInstitutionPreSale,
            //   web3
            // );
        } else if (project == "LEIA") {
            contract = getLEIAPreSaleContract(Environment.LEIAPreSale, web3);
            contractInstitution = getLEIAInstitutionPreSaleContract(
                Environment.LEIAInstitutionPreSale,
                web3
            );
        } else if (project == "OLYMPUS") {
            contract = getOlympusPreSaleContract(Environment.OlympusPreSale, web3);
            contractInstitution = getOlympusInstitutionPreSaleContract(
                Environment.OlympusInstitutionPreSale,
                web3
            );
        } else if (project == "INCENTIV") {
            contract = getPreSaleContract(Environment.PreSale, web3);
            contractInstitution = getinstitutionpresalecontract(
                Environment.InstitutionPreSale,
                web3
            );
        }

        try {
            let data = [
                [
                    tokenPriceFeed,
                    parseInt(normalisationFactorToken),
                    parseInt(normalisationFactorNFT),
                    parseInt(tolerance),
                ],
            ];
            let dataInstitution = [
                [
                    tokenPriceFeed,
                    parseInt(normalisationFactorToken),
                    parseInt(tolerance),
                ],
            ];
            const createround1 = await contract.methods
                .setTokenPriceFeed(contractAddress, data)
                .send({ from: account })
                .on("transactionHash", (tx) => {
                    return tx.transactionHash;
                });

            if (createround1) {
                console.log(contractAddress, dataInstitution, "data institution");

                const createroundInstitution = await contractInstitution.methods
                    .setTokenPriceFeed(contractAddress, dataInstitution)
                    .send({ from: account })
                    .on("transactionHash", (tx) => {
                        return tx.transactionHash;
                    });
                if (createroundInstitution) {
                    handleClose();
                    handleShow1();
                    setLoader(false);
                }
            }
        } catch (e) {
            handleClose();
            handleShow1();
            setLoader(false);
            console.log(e);
        }
    };

    const ValidateForm = () => {
        // let input = userInput;
        let errors = {};
        let isValid = true;

        if (!contractAddress) {
            isValid = false;
            errors["address"] = "*Please enter a Contract Address";
        }

        if (!tokenSymbol) {
            isValid = false;
            errors["symbol"] = "*Please enter a Symbol";
        }

        if (!tokenDecimals) {
            isValid = false;
            errors["decimals"] = "*Please enter a Token Decimals";
        }

        // if (!slippageTolerance) {
        //   isValid = false;
        //   errors["tolerance"] = "*Please enter a Slippage Tolerance";
        // }

        if (
            (tokenSymbol == "eth" ||
                tokenSymbol == "ETH" ||
                contractAddress == "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE") &&
            !normalisationFactorToken
        ) {
            isValid = false;
            errors["NFToken"] = "*Please enter a Normalisation Factor for token";
        }
        if (
            (tokenSymbol == "eth" ||
                tokenSymbol == "ETH" ||
                contractAddress == "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE") &&
            !normalisationFactorNFT
        ) {
            isValid = false;
            errors["NFNFT"] = "*Please enter a Normalisation Factor for NFT";
        }

        if (
            (tokenSymbol == "eth" ||
                tokenSymbol == "ETH" ||
                contractAddress == "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE") &&
            !tolerance
        ) {
            isValid = false;
            errors["tolerance"] = "*Please enter a tolerance";
        }

        if (
            (tokenSymbol == "eth" ||
                tokenSymbol == "ETH" ||
                contractAddress == "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE") &&
            !tokenPriceFeed
        ) {
            isValid = false;
            errors["priceFeed"] = "*Please enter a Normalisation Factor for NFT";
        }

        setErrors(errors);
        return isValid;
    };

    return (
        <>
            {loader && <Loader />}
            <section className="salesround">
                <div className="custom-container">
                    <div className="main-heading">
                        <div>
                            <p className="selfhead">ADMIN Settings</p>
                            <h6>User Management</h6>
                        </div>
                        <button className="btn-savechanges" onClick={handleShow}>Add User</button>
                    </div>
                    <DataTable
                        tokenData={tokenData}
                        toggleStatus={toggleStatus}
                        setOrderDirection={setOrderDirection}
                        setOrderField={setOrderField}
                    />
                </div>
            </section>

            <Modal
                className="add-mega-modal global-modal-scss"
                show={show}
                onHide={handleClose}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="add-mega-div">
                        <div className="option-field">
                            <div className="material-textfield">
                                <input
                                    type="text"
                                    placeholder="Name"
                                    value={contractAddress}
                                    onChange={(e) => {
                                        handleContractChange(e);
                                    }}
                                />
                                {/* <label>Contract Address</label> */}
                            </div>
                        </div>
                        <div style={{ position: "unset" }} className="text-danger mb-4">
                            <small>{errors.address}</small>
                        </div>
                        <div className="option-field">
                            <div className="material-textfield">
                                <input
                                    type="text"
                                    placeholder="User Email"
                                    value={tokenSymbol}
                                    onChange={(e) => {
                                        setTokenSymbol(e.target.value);
                                    }}
                                />
                                {/* <label>Token Symbol</label> */}
                            </div>
                        </div>
                        <div style={{ position: "unset" }} className="text-danger mb-4">
                            <small>{errors.symbol}</small>
                        </div>
                        <div className="option-field">
                            <Dropdown>
                                <Dropdown.Toggle
                                    variant="success"
                                    id="dropdown-basic"
                                    className="mybtnsforinput"
                                >
                                    Select Access Level
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15"
                                        height="8"
                                        viewBox="0 0 15 8"
                                        fill="none"
                                    >
                                        <path
                                            d="M0.535156 0.5L0.535157 3.47056L7.76848 7.5L7.76848 4.52943L0.535156 0.5Z"
                                            fill="black"
                                        />
                                        <path
                                            d="M15 0.500244L15 3.47081L7.76667 7.50024L7.76667 4.52968L15 0.500244Z"
                                            fill="black"
                                        />
                                    </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="menu-my" style={{ height: "auto" }}>
                                    <li>
                                        <a
                                            class="dropdown-item"

                                        >
                                            Read Only
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            class="dropdown-item"

                                        >
                                            Read & Write
                                        </a>
                                    </li>


                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div style={{ position: "unset" }} className="text-danger mb-4">
                            <small>{errors.decimals}</small>
                        </div>

                    </div>
                    <div className="twice-btn">
                        <button onClick={handleClose} className="btn-cancel">
                            Cancel
                        </button>
                        <button
                            className="btn-add"
                            onClick={() => {
                                addToken();
                            }}
                        >
                            Add User
                        </button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                className="connectwallet-modal buy-modal"
                show={show1}
                onHide={handleClose1}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Success</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="buy-modal">
                        <svg xmlns="http://www.w3.org/2000/svg" width="81" height="80" viewBox="0 0 81 80" fill="none">
                            <path d="M40.4997 77.8945C61.4284 77.8945 78.3945 60.9284 78.3945 39.9997C78.3945 19.071 61.4284 2.10498 40.4997 2.10498C19.571 2.10498 2.60498 19.071 2.60498 39.9997C2.60498 60.9284 19.571 77.8945 40.4997 77.8945Z" fill="#329879" />
                            <path d="M36.6264 54.4C36.0791 54.4 35.5317 54.1894 35.1528 53.7684L23.4475 42.0631C22.6054 41.221 22.6054 39.9157 23.4475 39.0736C24.2896 38.2315 25.5949 38.2315 26.437 39.0736L36.5001 49.1368L54.5212 26.4C55.237 25.4736 56.5843 25.3473 57.4685 26.0631C58.3949 26.7789 58.5212 28.1263 57.8054 29.0105L38.3107 53.6C37.9317 54.0631 37.3843 54.3579 36.7949 54.4C36.7107 54.4 36.6686 54.4 36.6264 54.4Z" fill="#E2E9EC" />
                        </svg>
                        <h6 style={{marginTop: "11px"}}>User Successfully Added!</h6>
                        <p>An email has been sent to the user. They will be able to set their password and login to the admin panel using their new password.</p>
                        <button onClick={handleClose1} className="btn-common">
                            Okay
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default UserManagementSetting;
