import "../../App.scss";
import React, { useState, useEffect } from "react";
import Navbar from "./header/Navbar";
import Dashboard from "./Dashboard/Dashboard";
import DashboardRef from "./DashboardRef/Dashboard";
import DashboardNodesMiners from "./DashboardNodesMiners/Dashboard";

import AllAgents from "./AllAgents/AllAgents";
import AllAgentsNodesAndMiners from "./AllAgentsNodesAndMiners/AllAgents";
import AllTransaction from "./AllTransaction/AllTransaction";
import AllTransactionRef from "./AllTransactionRef/AllTransaction";

import AllTransactionNodesMiners from "./TransactionNodesMiners/AllTransaction";

import SalesRound from "./SalesRound/SalesRound";
import SalesRoundRef from "./SalesRoundRef/SalesRound";

import Claim from "./AllClaim/Claim";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { Api_Url } from "../../utils/ApiUrl";
import LeadersAdmin from "./LeaderAdmin/LeadersAdmin";
import Accordion from "react-bootstrap/Accordion";
import InstitutionalStat from "./Institutions/InstitutionalStat";
import AllInstitution from "./AllInstitution/AllInstitution";
import InstituteTransaction from "./InstituteTransaction/InstituteTransaction";
import Tokensettings from "./Tokensettings/Tokensettings";
import TestnetRound from "./TestnetRound/TestnetRound";
import LowCreditError from "./LowCreditError/LowCreditError";
import Stats from "./Stats/Stats";
import InsuranceDetail from "./InsuranceDetail/InsuranceDetail";
import Topccountry from "./Dashboard/Topccountry";
import Topamount from "./Dashboard/Topamount";
import { Offcanvas } from "react-bootstrap";
import Allreferrers from "../Allreferrers/Allreferrers";
import TokenSettingsNodesAndMinors from "./TokenSettingsNodesAndMinors/TokenSettingsNodesAndMinors";
import Allprojects from "./Allprojects/Allprojects";
import UserManagementSetting from "./UserManagementSetting/UserManagementSetting";
import ActivityLog from "./ActivityLog/ActivityLog";

function Landing() {
  const indexvv = localStorage.getItem("indexvalue");
  const [indexwait, setindexwait] = useState(0);
  const [routes, setRoutes] = useState(false);
  const [user, setUser] = useState(null);

  const val = localStorage.getItem("accessToken");
  const [usdPrice, setUsdPrice] = useState("");
  const [tomiPrice, setTomiPrice] = useState("");
  const [btcPrice, setBtcPrice] = useState("");
  const [pepePrice, setPepePrice] = useState("");
  const [linkPrice, setLinkPrice] = useState("");
  const [uniPrice, setUniPrice] = useState("");
  const [dopPrice, setDopPrice] = useState("");
  const [gemsPrice, setGemsPrice] = useState("");

  useEffect(() => {
    if (!tomiPrice) {
      getTomiPrice();
    }
    if (!usdPrice) {
      axios
        .get("https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD")
        .then((res) => {
          setUsdPrice(res?.data?.USD);
          // setEthUsd(res.data);
        });
    }
    if (!btcPrice) {
      axios
        .get("https://min-api.cryptocompare.com/data/price?fsym=BTC&tsyms=USD")
        .then((res) => {
          setBtcPrice(res?.data?.USD);
          // setEthUsd(res.data);
        });
    }

    if (!pepePrice) {
      axios
        .get("https://min-api.cryptocompare.com/data/price?fsym=PEPE&tsyms=USD")
        .then((res) => {
          setPepePrice(res?.data?.USD);
          // setEthUsd(res.data);
        });
    }
    if (!linkPrice) {
      axios
        .get("https://min-api.cryptocompare.com/data/price?fsym=LINK&tsyms=USD")
        .then((res) => {
          setLinkPrice(res?.data?.USD);
          // setEthUsd(res.data);
        });
    }
    if (!uniPrice) {
      axios
        .get("https://min-api.cryptocompare.com/data/price?fsym=UNI&tsyms=USD")
        .then((res) => {
          setUniPrice(res?.data?.USD);
          // setEthUsd(res.data);
        });
    }
    if (!gemsPrice) {
      axios
        .get("https://min-api.cryptocompare.com/data/price?fsym=GEMS&tsyms=USD")
        .then((res) => {
          setGemsPrice(res?.data?.USD);
          // setEthUsd(res.data);
        });
    }
    if (!dopPrice) {
      axios
        .get(
          `https://mainnet-api-prod.dop.org/transactions/get-price?coinId=data-ownership-protocol`
        )
        .then((res) => {
          const data = res?.data?.data?.market_data?.current_price?.usd;

          setDopPrice(data);
          // setEthUsd(res.data);
        });
    }
  }, []);

  const getTomiPrice = async () => {
    try {
      var config = {
        method: "get",
        url: `${Api_Url}/users/tomi-price`,
      };

      axios(config)
        .then(function (res) {
          setTomiPrice(res?.data?.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
      // throw error;
    }
  };

  useEffect(() => {
    const checkTokenExpiry = () => {
      if (!val) {
        // Token is not present, user is not logged in
        return;
      }

      // Split the JWT into its parts (header, payload, and signature)
      const parts = val?.split(".");
      if (parts.length !== 3) {
        // Invalid JWT format
        return;
      }

      // Decode the payload
      const payload = JSON.parse(atob(parts[1]));

      // Get the expiration time from the payload
      const expirationTime = payload.exp * 1000; // Convert to milliseconds
      const currentTime = Date.now();

      if (expirationTime < currentTime) {
        // Token has expired
        Logout(); // Call the logout function
      }
    };

    // Check token expiry every minute (you can adjust the interval as needed)
    const intervalId = setInterval(checkTokenExpiry, 60000);
    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const history = useHistory();
  useEffect(() => {
    if (val) {
    } else {
      history.push("/login");
    }
    if (indexvv == "0") {
      setindexwait(0);
    } else if (indexvv == "1") {
      setindexwait(1);
    } else if (indexvv == "2") {
      setindexwait(2);
    } else if (indexvv == "3") {
      setindexwait(3);
    } else if (indexvv == "4") {
      setindexwait(4);
    } else if (indexvv == "5") {
      setindexwait(5);
    } else if (indexvv == "6") {
      setindexwait(6);
    } else if (indexvv == "7") {
      setindexwait(7);
    } else if (indexvv == "8") {
      setindexwait(8);
    } else if (indexvv == "9") {
      setindexwait(9);
    } else if (indexvv == "10") {
      setindexwait(10);
    } else if (indexvv == "11") {
      setindexwait(11);
    } else if (indexvv == "12") {
      setindexwait(12);
    } else if (indexvv == "19") {
      setindexwait(19);
    }
    // else if (indexvv == "16") {
    //   setindexwait(16);
    // }
  }, []);
  const hitfunctionss = (asd) => {
    setindexwait(asd);
    localStorage.setItem("indexvalue", asd);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    let res = localStorage.getItem("userId");
    res = JSON.parse(res);
    GetMyData();
    // setUser(res);
  }, []);

  const GetMyData = async () => {
    const tok = localStorage?.getItem("accessToken");
    // if (account) {
    var config = {
      method: "get",
      url: `${Api_Url}/users/my/stats?privateSale=INCENTIV`,
      // remove the private sale url from here
      headers: {
        authorization: `Bearer ` + tok,
      },
    };
    axios(config)
      .then(function (response) {
        setUser(response?.data?.data);
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          // history.push("/login");
        }
        console.log(error);
      });
    // }
  };
  // console.log("user?.role", user?.role);
  const [sidebar, setSidebar] = useState(false);
  const handleClosesidebar = () => setSidebar(false);

  const Logout = async () => {
    const val = localStorage.getItem("accessToken");
    var config = {
      method: "delete",
      url: `${Api_Url}/auth/users/logout`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    axios(config)
      .then(function (response) {
        if (response?.data?.statusCode == 200) {
          localStorage?.removeItem("accessToken");
          localStorage?.removeItem("refreshToken");
          localStorage?.removeItem("userName");
          history.push("/login");
        }
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/login");
        }
      });
  };

  return (
    <>
      <div style={{ position: "relative", overflow: "hidden" }}>
        <Navbar
          sidebar={sidebar}
          setSidebar={setSidebar}
          user={user}
          GetMyData={GetMyData}
          setindexwait={setindexwait}
          indexwait={indexwait}
        />

        <img src="/leftshade.png" alt="leftshade" className="leftshade" />
        <img src="/midshade.png" alt="leftshade" className="midshade" />
        <img
          src="/leftshadembl.png"
          alt="leftshade"
          className="leftshadembl d-none"
        />
        <img
          src="/rightshadembl.png"
          alt="leftshade"
          className="midshadembl d-none"
        />

        {indexwait == 0 ? (
          <>
            <Dashboard
              setUser={setUser}
              user={user}
              tomiPrice={tomiPrice}
              usdPrice={usdPrice}
              btcPrice={btcPrice}
              pepePrice={pepePrice}
              linkPrice={linkPrice}
              uniPrice={uniPrice}
              dopPrice={dopPrice}
              gemsPrice={gemsPrice}
              hitfunctionss={hitfunctionss}
            />
          </>
        ) : indexwait == 1 ? (
          <>
            <AllAgents
              GetMyData={GetMyData}
              setindexwait={setindexwait}
              user={user}
              setRoutes={setRoutes}
              routes={routes}
              tomiPrice={tomiPrice}
              usdPrice={usdPrice}
              btcPrice={btcPrice}
              pepePrice={pepePrice}
              linkPrice={linkPrice}
              uniPrice={uniPrice}
              dopPrice={dopPrice}
              gemsPrice={gemsPrice}
            />
          </>
        ) : indexwait == 2 ? (
          <AllTransaction
            setindexwait={setindexwait}
            user={user}
            tomiPrice={tomiPrice}
            usdPrice={usdPrice}
            btcPrice={btcPrice}
            pepePrice={pepePrice}
            linkPrice={linkPrice}
            uniPrice={uniPrice}
            dopPrice={dopPrice}
            gemsPrice={gemsPrice}
          />
        ) : indexwait == 3 ? (
          <>
            {user?.role === "admin" && (
              <SalesRound
                setindexwait={setindexwait}
                tomiPrice={tomiPrice}
                usdPrice={usdPrice}
                btcPrice={btcPrice}
                pepePrice={pepePrice}
                linkPrice={linkPrice}
                uniPrice={uniPrice}
                dopPrice={dopPrice}
                gemsPrice={gemsPrice}
              />
            )}
          </>
        ) : indexwait == 4 ? (
          <>
            {
              user?.role === "admin" ? (
                <Claim
                  setindexwait={setindexwait}
                  tomiPrice={tomiPrice}
                  usdPrice={usdPrice}
                  btcPrice={btcPrice}
                  pepePrice={pepePrice}
                  linkPrice={linkPrice}
                  uniPrice={uniPrice}
                  dopPrice={dopPrice}
                  gemsPrice={gemsPrice}
                />
              ) : null
              // <LeadersAdmin
              //   setindexwait={setindexwait}
              //   tomiPrice={tomiPrice}
              //   usdPrice={usdPrice}
              //   btcPrice={btcPrice}
              //   user={user}
              // />
            }
          </>
        ) : indexwait == 5 ? (
          <>
            <InstitutionalStat
              user={user}
              tomiPrice={tomiPrice}
              usdPrice={usdPrice}
              btcPrice={btcPrice}
              pepePrice={pepePrice}
              linkPrice={linkPrice}
              uniPrice={uniPrice}
              dopPrice={dopPrice}
              gemsPrice={gemsPrice}
            />
          </>
        ) : indexwait == 6 ? (
          <>
            <AllInstitution
              user={user}
              routes={routes}
              setRoutes={setRoutes}
              setindexwait={setindexwait}
              tomiPrice={tomiPrice}
              usdPrice={usdPrice}
              btcPrice={btcPrice}
              pepePrice={pepePrice}
              linkPrice={linkPrice}
              uniPrice={uniPrice}
              dopPrice={dopPrice}
              gemsPrice={gemsPrice}
            />
          </>
        ) : indexwait == 7 ? (
          <>
            <InstituteTransaction
              setindexwait={setindexwait}
              user={user}
              usdPrice={usdPrice}
              tomiPrice={tomiPrice}
              btcPrice={btcPrice}
              pepePrice={pepePrice}
              linkPrice={linkPrice}
              uniPrice={uniPrice}
              dopPrice={dopPrice}
              gemsPrice={gemsPrice}
            />
          </>
        ) : indexwait == 8 ? (
          <>
            <Tokensettings
              user={user}
              routes={routes}
              setRoutes={setRoutes}
              setindexwait={setindexwait}
              tomiPrice={tomiPrice}
              usdPrice={usdPrice}
              btcPrice={btcPrice}
              pepePrice={pepePrice}
              linkPrice={linkPrice}
              uniPrice={uniPrice}
              dopPrice={dopPrice}
              gemsPrice={gemsPrice}
            />
          </>
        ) : indexwait == 10 ? (
          <>
            <LowCreditError setindexwait={setindexwait} />
          </>
        ) : indexwait == 11 ? (
          <>
            <Stats user={user} usdPrice={usdPrice} />
          </>
        ) : indexwait == 12 ? (
          <>
            <InsuranceDetail
              setindexwait={setindexwait}
              user={user}
              tomiPrice={tomiPrice}
              usdPrice={usdPrice}
              btcPrice={btcPrice}
              pepePrice={pepePrice}
              linkPrice={linkPrice}
              uniPrice={uniPrice}
              dopPrice={dopPrice}
              gemsPrice={gemsPrice}
            />
          </>
        ) : indexwait == 13 ? (
          <>
            <Topccountry />
          </>
        ) : indexwait == 14 ? (
          <>
            <Topamount />
          </>
        ) : indexwait == 19 ? (
          <>
            <Allreferrers
              setRoutes={setRoutes}
              routes={routes}
              GetMyData={GetMyData}
              setindexwait={setindexwait}
              user={user}
              tomiPrice={tomiPrice}
              usdPrice={usdPrice}
              btcPrice={btcPrice}
              pepePrice={pepePrice}
              linkPrice={linkPrice}
              uniPrice={uniPrice}
              dopPrice={dopPrice}
              gemsPrice={gemsPrice}
            />
          </>
        ) : indexwait == 20 ? (
          <>
            <DashboardRef
              setRoutes={setRoutes}
              routes={routes}
              setUser={setUser}
              user={user}
              tomiPrice={tomiPrice}
              usdPrice={usdPrice}
              btcPrice={btcPrice}
              pepePrice={pepePrice}
              linkPrice={linkPrice}
              uniPrice={uniPrice}
              dopPrice={dopPrice}
              gemsPrice={gemsPrice}
              hitfunctionss={hitfunctionss}
            />
          </>
        ) : indexwait == 21 ? (
          <>
            <AllTransactionRef
              setRoutes={setRoutes}
              routes={routes}
              setUser={setUser}
              user={user}
              tomiPrice={tomiPrice}
              usdPrice={usdPrice}
              btcPrice={btcPrice}
              pepePrice={pepePrice}
              linkPrice={linkPrice}
              uniPrice={uniPrice}
              dopPrice={dopPrice}
              gemsPrice={gemsPrice}
            />
          </>
        ) : indexwait == 22 ? (
          <>
            <SalesRoundRef
              setRoutes={setRoutes}
              routes={routes}
              setUser={setUser}
              user={user}
              tomiPrice={tomiPrice}
              usdPrice={usdPrice}
              btcPrice={btcPrice}
              pepePrice={pepePrice}
              linkPrice={linkPrice}
              uniPrice={uniPrice}
              dopPrice={dopPrice}
              gemsPrice={gemsPrice}
            />
          </>
        ) : indexwait == 24 ? (
          <>
            <DashboardNodesMiners
              setRoutes={setRoutes}
              routes={routes}
              setUser={setUser}
              user={user}
              tomiPrice={tomiPrice}
              usdPrice={usdPrice}
              btcPrice={btcPrice}
              pepePrice={pepePrice}
              linkPrice={linkPrice}
              uniPrice={uniPrice}
              dopPrice={dopPrice}
              gemsPrice={gemsPrice}
              hitfunctionss={hitfunctionss}
            />
          </>
        ) : indexwait == 25 ? (
          <>
            <AllTransactionNodesMiners
              setRoutes={setRoutes}
              routes={routes}
              setUser={setUser}
              user={user}
              tomiPrice={tomiPrice}
              usdPrice={usdPrice}
              btcPrice={btcPrice}
              pepePrice={pepePrice}
              linkPrice={linkPrice}
              uniPrice={uniPrice}
              dopPrice={dopPrice}
              gemsPrice={gemsPrice}
            />
          </>
        ) : indexwait == 26 ? (
          <>
            <TokenSettingsNodesAndMinors
              user={user}
              routes={routes}
              setRoutes={setRoutes}
              setindexwait={setindexwait}
              tomiPrice={tomiPrice}
              usdPrice={usdPrice}
              btcPrice={btcPrice}
              pepePrice={pepePrice}
              linkPrice={linkPrice}
              uniPrice={uniPrice}
              dopPrice={dopPrice}
              gemsPrice={gemsPrice}
            />
          </>
        ) : indexwait == 27 ? (
          <>
            <AllAgentsNodesAndMiners
              GetMyData={GetMyData}
              setindexwait={setindexwait}
              user={user}
              setRoutes={setRoutes}
              routes={routes}
              tomiPrice={tomiPrice}
              usdPrice={usdPrice}
              btcPrice={btcPrice}
              pepePrice={pepePrice}
              linkPrice={linkPrice}
              uniPrice={uniPrice}
              dopPrice={dopPrice}
              gemsPrice={gemsPrice}
            />
          </>
        ) : indexwait == 28 ? (
          <>
            <UserManagementSetting
              user={user}
              routes={routes}
              setRoutes={setRoutes}
              setindexwait={setindexwait}
              tomiPrice={tomiPrice}
              usdPrice={usdPrice}
              btcPrice={btcPrice}
              pepePrice={pepePrice}
              linkPrice={linkPrice}
              uniPrice={uniPrice}
              dopPrice={dopPrice}
              gemsPrice={gemsPrice}
            />
          </>
        ) : indexwait == 29 ? (
          <>
            <ActivityLog
              user={user}
              routes={routes}
              setRoutes={setRoutes}
              setindexwait={setindexwait}
              tomiPrice={tomiPrice}
              usdPrice={usdPrice}
              btcPrice={btcPrice}
              pepePrice={pepePrice}
              linkPrice={linkPrice}
              uniPrice={uniPrice}
              dopPrice={dopPrice}
              gemsPrice={gemsPrice}
            />
          </>
        ) : (
          // : indexwait == 28 ? (
          //   <>
          //     <Allprojects
          //       GetMyData={GetMyData}
          //       setindexwait={setindexwait}
          //       user={user}
          //       setRoutes={setRoutes}
          //       routes={routes}
          //       tomiPrice={tomiPrice}
          //       usdPrice={usdPrice}
          //       btcPrice={btcPrice}
          //       pepePrice={pepePrice}
          //       linkPrice={linkPrice}
          //       uniPrice={uniPrice}
          //       dopPrice={dopPrice}
          //       gemsPrice={gemsPrice}
          //     />
          //   </>
          // )
          // : indexwait == 9 ? (
          //   <>
          //     <TestnetRound
          //       user={user}
          //       tomiPrice={tomiPrice}
          //       btcPrice={btcPrice}
          //       usdPrice={usdPrice}
          //     />
          //   </>
          // )
          ""
        )}
      </div>
      {/* {sidebar && ( */}
      <Offcanvas
        show={sidebar}
        onHide={handleClosesidebar}
        className="custom-sidebar"
      >
        <span className="brdrtop"></span>
        <div className="custom-sidebar-top-menu">
          <a
            class="navbar-brand"
            onClick={() => {
              setindexwait(0);
              setSidebar(false);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="115"
              height="35"
              viewBox="0 0 115 35"
              fill="none"
            >
              <path
                d="M8.62224 0L0 9.32505V15.3733L18.9998 34.3507L37.9911 15.3834V12.9411H14.7931L16.4007 17.9835H27.1942L18.9981 26.1739L5.97903 13.165L12.5896 6.02448H25.4066L28.2315 9.07912H36.925V8.17341L29.3672 0H8.62224Z"
                fill="#02171D"
              />
              <path
                d="M54.5786 17.9068V15.322H62.1195V24.428H59.7956L59.345 22.1989C58.1119 23.9537 56.286 24.8074 53.8435 24.8074C51.401 24.8074 49.4565 24.0011 47.9862 22.3886C46.516 20.7761 45.7809 18.6419 45.7809 15.986C45.7809 13.3301 46.516 11.1958 48.0337 9.55961C49.5276 7.97081 51.567 7.16455 54.128 7.16455C56.2385 7.16455 57.9933 7.70996 59.4161 8.77707C60.7915 9.86789 61.6452 11.3144 61.9298 13.1641H58.6336C58.3965 12.1681 57.8748 11.3856 57.0685 10.8164C56.2622 10.2473 55.29 9.96274 54.1517 9.96274C52.5867 9.96274 51.3773 10.5082 50.4762 11.5753C49.575 12.6661 49.1245 14.1363 49.1245 15.986C49.1245 17.8593 49.575 19.3296 50.4999 20.3967C51.401 21.4875 52.6578 22.0092 54.2703 22.0092C55.5271 22.0092 56.618 21.6298 57.5191 20.871C58.4202 20.1121 58.8944 19.1399 58.9893 17.9068H54.5786Z"
                fill="#02171D"
              />
              <path
                d="M77.5486 7.54397V10.4133H68.3004V14.3972H76.6712V17.1479H68.3004V21.5586H77.5012V24.428H65.099V7.54397H77.5486Z"
                fill="#02171D"
              />
              <path
                d="M90.5056 15.5828L93.3276 7.54397H97.9754V24.428H94.9164V19.2821C94.9164 16.1283 94.9401 13.3301 95.0112 10.9113C94.6555 12.097 94.2287 13.4012 93.7307 14.8003L90.3159 24.428H87.6837L84.2453 14.8003C83.6287 13.0455 83.2256 11.765 82.9885 10.9587C83.0359 13.3775 83.0833 16.152 83.0833 19.2821V24.428H80.0006V7.54397H84.6484L87.5652 15.7488C87.7549 16.318 88.2292 17.8356 89.0117 20.3255C89.5097 18.5707 90.0077 17.0057 90.5056 15.5828Z"
                fill="#02171D"
              />
              <path
                d="M107.651 24.8074C105.35 24.8074 103.572 24.3094 102.315 23.266C101.058 22.2463 100.418 20.7761 100.418 18.8553H103.572C103.572 20.0647 103.927 20.9184 104.639 21.4401C105.35 21.9618 106.37 22.2226 107.745 22.2226C108.836 22.2226 109.69 22.0329 110.283 21.6061C110.852 21.1792 111.16 20.5864 111.16 19.7801C111.16 19.1162 110.899 18.6182 110.401 18.2625C109.903 17.9305 109.002 17.6222 107.698 17.3376L106.275 17.0531C102.647 16.3891 100.845 14.6817 100.845 11.931C100.845 10.4844 101.39 9.32248 102.505 8.46879C103.595 7.61511 105.137 7.16455 107.105 7.16455C109.334 7.16455 111.018 7.63882 112.156 8.58736C113.294 9.5359 113.911 10.8639 113.982 12.5712H110.876C110.804 11.6227 110.472 10.9113 109.903 10.437C109.334 9.98646 108.409 9.74932 107.153 9.74932C106.157 9.74932 105.421 9.93903 104.9 10.2947C104.378 10.6741 104.117 11.1721 104.117 11.8361C104.117 12.5238 104.354 13.0455 104.829 13.4012C105.303 13.7806 106.18 14.0889 107.413 14.326L108.884 14.6106C110.852 15.0137 112.275 15.6066 113.152 16.3891C114.029 17.1716 114.48 18.2388 114.48 19.6141C114.48 21.2741 113.863 22.5546 112.678 23.4557C111.468 24.3568 109.785 24.8074 107.651 24.8074Z"
                fill="#02171D"
              />
            </svg>
          </a>
          <a onClick={() => setSidebar(!sidebar)} class="navbar-brand">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
            >
              <path
                d="M19.1049 2.65918L15.6654 2.65918L11 10.9934L14.4394 10.9934L19.1049 2.65918Z"
                fill="#01141A"
              />
              <path
                d="M19.1049 19.3452L15.6654 19.3452L11 11.011L13.1677 9.57014L14.4394 11.011L19.1049 19.3452Z"
                fill="#01141A"
              />
              <path
                d="M2.90112 2.65918L6.34056 2.65918L11.006 10.9934L7.56656 10.9934L2.90112 2.65918Z"
                fill="#01141A"
              />
              <path
                d="M2.90112 19.3452L6.34056 19.3452L11.006 11.011L8.83832 9.57014L7.56656 11.011L2.90112 19.3452Z"
                fill="#01141A"
              />
            </svg>
          </a>
        </div>

        <ul>
          <p className="innerlistpara">Premium Projects</p>
          <li>
            <a
              className={indexwait === 0 ? "active" : ""}
              onClick={() => {
                hitfunctionss(0);
                setSidebar(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
              >
                <path
                  d="M3.46754 17.4928L1.37785 19.3011L1.37785 11.5772L3.46754 11.5772L3.46754 17.4928Z"
                  fill="#02171D"
                  stroke="#02171D"
                  stroke-width="0.0835874"
                />
                <path
                  d="M3.46754 7.29501L1.37785 9.10339L1.37785 1.37941L3.46754 1.37941L3.46754 7.29501Z"
                  fill="#02171D"
                  stroke="#02171D"
                  stroke-width="0.0835874"
                />
                <rect
                  x="2.38121"
                  y="11.5769"
                  width="7.18851"
                  height="2.08968"
                  fill="#02171D"
                  stroke="#02171D"
                  stroke-width="0.0835874"
                />
                <rect
                  x="2.38121"
                  y="1.37968"
                  width="7.18851"
                  height="2.08968"
                  fill="#02171D"
                  stroke="#02171D"
                  stroke-width="0.0835874"
                />
                <path
                  d="M13.7505 17.4928L11.6608 19.3011L11.6608 11.5772L13.7505 11.5772L13.7505 17.4928Z"
                  fill="#02171D"
                  stroke="#02171D"
                  stroke-width="0.0835874"
                />
                <path
                  d="M13.7505 7.29501L11.6608 9.10339L11.6608 1.37941L13.7505 1.37941L13.7505 7.29501Z"
                  fill="#02171D"
                  stroke="#02171D"
                  stroke-width="0.0835874"
                />
                <rect
                  x="12.6642"
                  y="11.5769"
                  width="7.18851"
                  height="2.08968"
                  fill="#02171D"
                  stroke="#02171D"
                  stroke-width="0.0835874"
                />
                <rect
                  x="17.6793"
                  y="17.261"
                  width="2.17327"
                  height="2.08968"
                  fill="#02171D"
                  stroke="#02171D"
                  stroke-width="0.0835874"
                />
                <rect
                  x="17.6793"
                  y="6.97978"
                  width="2.17327"
                  height="2.08968"
                  fill="#02171D"
                  stroke="#02171D"
                  stroke-width="0.0835874"
                />
                <rect
                  x="7.39818"
                  y="17.261"
                  width="2.17327"
                  height="2.08968"
                  fill="#02171D"
                  stroke="#02171D"
                  stroke-width="0.0835874"
                />
                <rect
                  x="7.39818"
                  y="6.97978"
                  width="2.17327"
                  height="2.08968"
                  fill="#02171D"
                  stroke="#02171D"
                  stroke-width="0.0835874"
                />
                <rect
                  x="12.6642"
                  y="1.37968"
                  width="7.18851"
                  height="2.08968"
                  fill="#02171D"
                  stroke="#02171D"
                  stroke-width="0.0835874"
                />
              </svg>
              Dashboard
            </a>
          </li>
          {user?.role !== "mini_agent" && (
            <li>
              <a
                className={indexwait === 1 ? "active" : ""}
                onClick={() => {
                  hitfunctionss(1);
                  setSidebar(false);
                  setRoutes(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                >
                  <path
                    d="M15.2256 18.7794L15.2256 16.4011L9.46249 13.1749L9.46249 15.5533L15.2256 18.7794Z"
                    fill="#02171D"
                  />
                  <path
                    d="M0 18.7794L7.27727e-07 16.4011L5.7631 13.1749L5.7631 15.5533L0 18.7794Z"
                    fill="#02171D"
                  />
                  <path
                    d="M20.814 18.274L20.814 15.8956L15.4745 12.9241L15.4745 15.3025L20.814 18.274Z"
                    fill="#02171D"
                  />
                  <circle
                    cx="7.56421"
                    cy="7.11484"
                    r="3.13453"
                    stroke="#02171D"
                    stroke-width="1.88685"
                  />
                  <path
                    d="M13.833 10.2494C15.5642 10.2494 16.9675 8.84602 16.9675 7.11487C16.9675 5.38372 15.5642 3.98035 13.833 3.98035"
                    stroke="#02171D"
                    stroke-width="1.88685"
                  />
                </svg>
                All Leaders
              </a>
            </li>
          )}
          <li>
            <a
              className={indexwait === 2 ? "active" : ""}
              onClick={() => {
                hitfunctionss(2);
                setSidebar(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
              >
                <path
                  d="M8.27731 7.89946L9.39371 5.89855L15.7566 5.8896L14.6402 7.89052L8.27731 7.89946Z"
                  fill="#02171D"
                />
                <path
                  d="M16.8687 1.06111L20.1704 6.77983L17.6823 6.81682L14.3806 1.09811L16.8687 1.06111Z"
                  fill="#02171D"
                />
                <path
                  d="M16.8218 12.4998L20.1235 6.78112L17.6354 6.74412L14.3337 12.4628L16.8218 12.4998Z"
                  fill="#02171D"
                />
                <path
                  d="M11.8936 13.1007L10.7772 15.1016L4.41433 15.1105L5.53074 13.1096L11.8936 13.1007Z"
                  fill="#02171D"
                />
                <path
                  d="M7.47926 1.93826L6.36285 3.93917L0 3.94812L1.1164 1.9472L7.47926 1.93826Z"
                  fill="#02171D"
                />
                <path
                  d="M20.1843 17.0634L19.0679 19.0643L12.7051 19.0733L13.8215 17.0724L20.1843 17.0634Z"
                  fill="#02171D"
                />
                <path
                  d="M3.30219 19.939L0.000495173 14.2203L2.48861 14.1833L5.79031 19.902L3.30219 19.939Z"
                  fill="#02171D"
                />
                <path
                  d="M3.32319 8.50041L0.0214899 14.2191L2.50961 14.2561L5.81131 8.53741L3.32319 8.50041Z"
                  fill="#02171D"
                />
              </svg>
              All Transactions
            </a>
          </li>
          {user?.role === "admin" && (
            <>
              <li>
                <a
                  className={indexwait === 3 ? "active" : ""}
                  onClick={() => {
                    hitfunctionss(3);
                    setSidebar(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                  >
                    <path
                      d="M12.7051 3.89658L8.27495 3.89658L8.27495 1.7233L12.7051 1.7233L12.7051 3.89658Z"
                      fill="#02171D"
                    />
                    <path
                      d="M12.7051 18.8587L8.27495 18.8587L8.27495 16.6854L12.7051 16.6854L12.7051 18.8587Z"
                      fill="#02171D"
                    />
                    <path
                      d="M16.8848 12.4225L16.8848 7.99242L19.058 7.99242L19.058 12.4225L16.8848 12.4225Z"
                      fill="#02171D"
                    />
                    <path
                      d="M1.92236 12.4225L1.92236 7.99242L4.09563 7.99242L4.09563 12.4225L1.92236 12.4225Z"
                      fill="#02171D"
                    />
                    <path
                      d="M16.5776 7.33575L13.4451 4.20318L14.9818 2.66644L18.1144 5.79902L16.5776 7.33575Z"
                      fill="#02171D"
                    />
                    <path
                      d="M5.99854 17.9156L2.86596 14.7831L4.4027 13.2463L7.53527 16.3789L5.99854 17.9156Z"
                      fill="#02171D"
                    />
                    <path
                      d="M13.5044 16.3198L16.637 13.1872L18.1737 14.724L15.0411 17.8565L13.5044 16.3198Z"
                      fill="#02171D"
                    />
                    <path
                      d="M2.9248 5.73987L6.05738 2.60729L7.59411 4.14403L4.46154 7.2766L2.9248 5.73987Z"
                      fill="#02171D"
                    />
                    <rect
                      x="8.27539"
                      y="7.9924"
                      width="4.43013"
                      height="4.43013"
                      fill="#02171D"
                    />
                  </svg>
                  Sale Rounds
                </a>
              </li>
            </>
          )}
          {
            user?.role === "admin" ? (
              <li>
                <a
                  className={indexwait === 4 ? "active" : ""}
                  onClick={() => {
                    hitfunctionss(4);
                    setSidebar(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                  >
                    <path
                      d="M17.4692 18.9424L17.4692 7.82526L19.6425 7.82526L19.6425 18.9424L17.4692 18.9424Z"
                      fill="#02171D"
                    />
                    <path
                      d="M1.25439 16.7691L19.6436 16.7691V18.9423L1.25439 18.9423L1.25439 16.7691Z"
                      fill="#02171D"
                    />
                    <path
                      d="M3.42627 7.82513L3.42627 18.9423L1.253 18.9423L1.253 7.82513L3.42627 7.82513Z"
                      fill="#02171D"
                    />
                    <path
                      d="M1.96397 2.89359L20.2278 2.89359L19.0158 5.06686H0.751953L1.96397 2.89359Z"
                      fill="#02171D"
                    />
                    <path
                      d="M7.31407 7.82513L19.6432 7.82513L18.4312 9.99841H6.10205L7.31407 7.82513Z"
                      fill="#02171D"
                    />
                    <rect
                      x="13.4575"
                      y="12.7569"
                      width="2.25686"
                      height="2.17327"
                      fill="#02171D"
                    />
                  </svg>
                  Leader Claims
                </a>
              </li>
            ) : null
            // <li>
            //   <a
            //     className={indexwait === 3 ? "active" : ""}
            //     onClick={() => {
            //       hitfunctionss(4);
            //       setSidebar(false);
            //     }}
            //   >
            //     <svg
            //       xmlns="http://www.w3.org/2000/svg"
            //       width="24"
            //       height="24"
            //       viewBox="0 0 24 24"
            //       fill="none"
            //     >
            //       <path
            //         d="M20.1611 11.2583H3.83883C3.01932 11.2583 2.35498 11.9226 2.35498 12.7421V20.1614C2.35498 20.9809 3.01932 21.6452 3.83883 21.6452H20.1611C20.9806 21.6452 21.645 20.9809 21.645 20.1614V12.7421C21.645 11.9226 20.9806 11.2583 20.1611 11.2583Z"
            //         stroke="white"
            //         stroke-width="1.5"
            //         stroke-linecap="round"
            //         stroke-linejoin="round"
            //       />
            //       <path
            //         d="M6.80664 4.58075V7.54844M12.0001 2.35498V7.54844M17.1936 4.58075V7.54844"
            //         stroke="white"
            //         stroke-width="1.5"
            //         stroke-linecap="round"
            //         stroke-linejoin="round"
            //       />
            //       <path
            //         d="M11.9999 18.6771C13.2292 18.6771 14.2257 17.6806 14.2257 16.4514C14.2257 15.2221 13.2292 14.2256 11.9999 14.2256C10.7707 14.2256 9.77417 15.2221 9.77417 16.4514C9.77417 17.6806 10.7707 18.6771 11.9999 18.6771Z"
            //         stroke="white"
            //         stroke-width="1.5"
            //         stroke-linecap="round"
            //         stroke-linejoin="round"
            //       />
            //     </svg>
            //     My Claims
            //   </a>
            // </li>
          }
          {user?.role === "admin" && (
            <li>
              <Accordion className="institute-accord">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <a
                      className={
                        indexwait === 5 || indexwait === 6 || indexwait === 7
                          ? "d-flex justify-content-between align-center active"
                          : "d-flex justify-content-between align-center"
                      }
                    >
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="21"
                          height="21"
                          viewBox="0 0 21 21"
                          fill="none"
                          style={{ marginRight: "20px" }}
                        >
                          <path
                            d="M6.75179 13.9736L10.2833 20.6796L7.79521 20.7166L4.26367 14.0106L6.75179 13.9736Z"
                            fill="#02171D"
                          />
                          <path
                            d="M14.0686 13.9736L10.537 20.6796L13.0252 20.7166L16.5567 14.0106L14.0686 13.9736Z"
                            fill="#02171D"
                          />
                          <path
                            d="M5.3304 10.4116L10.7468 0.887379L8.25866 0.85038L2.84229 10.3746L5.3304 10.4116Z"
                            fill="#02171D"
                          />
                          <path
                            d="M12.7061 0.850372L18.1225 10.3745L15.6343 10.4115L10.218 0.887371L12.7061 0.850372Z"
                            fill="#02171D"
                          />
                          <path
                            d="M5.00003 9.09985L11.9948 13.4774L10.4905 15.139L2.86376 10.3759L5.00003 9.09985Z"
                            fill="#02171D"
                          />
                          <path
                            d="M15.9603 9.09985L8.9656 13.4774L10.4905 15.139L18.0966 10.3759L15.9603 9.09985Z"
                            fill="#02171D"
                          />
                        </svg>
                        Institutions
                      </div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="7"
                        viewBox="0 0 13 7"
                        fill="none"
                        className="arrow"
                      >
                        <path
                          d="M0.601562 0.5L0.601563 3.0462L6.80156 6.5L6.80156 3.9538L0.601562 0.5Z"
                          fill="#02171D"
                        />
                        <path
                          d="M13 0.5L13 3.0462L6.8 6.5L6.8 3.9538L13 0.5Z"
                          fill="#02171D"
                        />
                      </svg>
                    </a>
                  </Accordion.Header>
                  <Accordion.Body>
                    <a
                      onClick={() => {
                        hitfunctionss(5);
                        setSidebar(false);
                      }}
                    >
                      Institutions Stats
                    </a>
                    <a
                      onClick={() => {
                        hitfunctionss(6);
                        setSidebar(false);
                      }}
                    >
                      All Institutions
                    </a>
                    <a
                      onClick={() => {
                        hitfunctionss(7);
                        setSidebar(false);
                      }}
                    >
                      All Transactions
                    </a>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </li>
          )}

          {/* {user?.role == "admin" ? (
              <li>
                <a
                  className={indexwait === 11 ? "active" : ""}
                  onClick={() => {
                    hitfunctionss(11);
                    setSidebar(false);
                  }}
                >
                  <img
                    src="\assets\sidebar\stats.svg"
                    alt="img"
                    className="img-fluid"
                  />
                  Stats
                </a>
              </li>
            ) : null} */}
          {user?.role == "admin" ? (
            <li>
              <a
                className={indexwait === 10 ? "active" : ""}
                onClick={() => {
                  hitfunctionss(10);
                  setSidebar(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                >
                  <path
                    d="M8.89648 8.75235L8.89648 19.1142L12.0001 17.3833L12.0001 7.17156L8.89648 8.75235Z"
                    fill="#02171D"
                  />
                  <path
                    d="M8.89648 3.46663L8.89648 7.10115L12.0001 5.37029L12.0001 1.88584L8.89648 3.46663Z"
                    fill="#02171D"
                  />
                </svg>
                Low Credit Errors
              </a>
            </li>
          ) : null}
          <p className="innerlistpara">Nodes & Miners</p>
          <li>
            <a
              className={indexwait === 24 ? "active" : ""}
              onClick={() => {
                hitfunctionss(24);
                setSidebar(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
              >
                <path
                  d="M3.46754 17.4928L1.37785 19.3011L1.37785 11.5772L3.46754 11.5772L3.46754 17.4928Z"
                  fill="#02171D"
                  stroke="#02171D"
                  stroke-width="0.0835874"
                />
                <path
                  d="M3.46754 7.29501L1.37785 9.10339L1.37785 1.37941L3.46754 1.37941L3.46754 7.29501Z"
                  fill="#02171D"
                  stroke="#02171D"
                  stroke-width="0.0835874"
                />
                <rect
                  x="2.38121"
                  y="11.5769"
                  width="7.18851"
                  height="2.08968"
                  fill="#02171D"
                  stroke="#02171D"
                  stroke-width="0.0835874"
                />
                <rect
                  x="2.38121"
                  y="1.37968"
                  width="7.18851"
                  height="2.08968"
                  fill="#02171D"
                  stroke="#02171D"
                  stroke-width="0.0835874"
                />
                <path
                  d="M13.7505 17.4928L11.6608 19.3011L11.6608 11.5772L13.7505 11.5772L13.7505 17.4928Z"
                  fill="#02171D"
                  stroke="#02171D"
                  stroke-width="0.0835874"
                />
                <path
                  d="M13.7505 7.29501L11.6608 9.10339L11.6608 1.37941L13.7505 1.37941L13.7505 7.29501Z"
                  fill="#02171D"
                  stroke="#02171D"
                  stroke-width="0.0835874"
                />
                <rect
                  x="12.6642"
                  y="11.5769"
                  width="7.18851"
                  height="2.08968"
                  fill="#02171D"
                  stroke="#02171D"
                  stroke-width="0.0835874"
                />
                <rect
                  x="17.6793"
                  y="17.261"
                  width="2.17327"
                  height="2.08968"
                  fill="#02171D"
                  stroke="#02171D"
                  stroke-width="0.0835874"
                />
                <rect
                  x="17.6793"
                  y="6.97978"
                  width="2.17327"
                  height="2.08968"
                  fill="#02171D"
                  stroke="#02171D"
                  stroke-width="0.0835874"
                />
                <rect
                  x="7.39818"
                  y="17.261"
                  width="2.17327"
                  height="2.08968"
                  fill="#02171D"
                  stroke="#02171D"
                  stroke-width="0.0835874"
                />
                <rect
                  x="7.39818"
                  y="6.97978"
                  width="2.17327"
                  height="2.08968"
                  fill="#02171D"
                  stroke="#02171D"
                  stroke-width="0.0835874"
                />
                <rect
                  x="12.6642"
                  y="1.37968"
                  width="7.18851"
                  height="2.08968"
                  fill="#02171D"
                  stroke="#02171D"
                  stroke-width="0.0835874"
                />
              </svg>
              Dashboard
            </a>
          </li>
          <li>
            <a
              className={indexwait === 25 ? "active" : ""}
              onClick={() => {
                hitfunctionss(25);
                setSidebar(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
              >
                <path
                  d="M8.27731 7.89946L9.39371 5.89855L15.7566 5.8896L14.6402 7.89052L8.27731 7.89946Z"
                  fill="#02171D"
                />
                <path
                  d="M16.8687 1.06111L20.1704 6.77983L17.6823 6.81682L14.3806 1.09811L16.8687 1.06111Z"
                  fill="#02171D"
                />
                <path
                  d="M16.8218 12.4998L20.1235 6.78112L17.6354 6.74412L14.3337 12.4628L16.8218 12.4998Z"
                  fill="#02171D"
                />
                <path
                  d="M11.8936 13.1007L10.7772 15.1016L4.41433 15.1105L5.53074 13.1096L11.8936 13.1007Z"
                  fill="#02171D"
                />
                <path
                  d="M7.47926 1.93826L6.36285 3.93917L0 3.94812L1.1164 1.9472L7.47926 1.93826Z"
                  fill="#02171D"
                />
                <path
                  d="M20.1843 17.0634L19.0679 19.0643L12.7051 19.0733L13.8215 17.0724L20.1843 17.0634Z"
                  fill="#02171D"
                />
                <path
                  d="M3.30219 19.939L0.000495173 14.2203L2.48861 14.1833L5.79031 19.902L3.30219 19.939Z"
                  fill="#02171D"
                />
                <path
                  d="M3.32319 8.50041L0.0214899 14.2191L2.50961 14.2561L5.81131 8.53741L3.32319 8.50041Z"
                  fill="#02171D"
                />
              </svg>
              All Transactions
            </a>
          </li>
          <li>
            <a
              className={indexwait === 27 ? "active" : ""}
              onClick={() => {
                hitfunctionss(27);
                setSidebar(false);
                setRoutes(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
              >
                <path
                  d="M15.2256 18.7794L15.2256 16.4011L9.46249 13.1749L9.46249 15.5533L15.2256 18.7794Z"
                  fill="#02171D"
                />
                <path
                  d="M0 18.7794L7.27727e-07 16.4011L5.7631 13.1749L5.7631 15.5533L0 18.7794Z"
                  fill="#02171D"
                />
                <path
                  d="M20.814 18.274L20.814 15.8956L15.4745 12.9241L15.4745 15.3025L20.814 18.274Z"
                  fill="#02171D"
                />
                <circle
                  cx="7.56421"
                  cy="7.11484"
                  r="3.13453"
                  stroke="#02171D"
                  stroke-width="1.88685"
                />
                <path
                  d="M13.833 10.2494C15.5642 10.2494 16.9675 8.84602 16.9675 7.11487C16.9675 5.38372 15.5642 3.98035 13.833 3.98035"
                  stroke="#02171D"
                  stroke-width="1.88685"
                />
              </svg>
              All Leaders
            </a>
          </li>
          {/* <li>
            <a
              className={indexwait === 26 ? "active" : ""}
              onClick={() => {
                hitfunctionss(26);
                setSidebar(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.55264 12.9922C6.78963 11.397 5.84295 7.86398 7.43818 5.10097C9.0334 2.33796 12.5664 1.39128 15.3294 2.98651C18.0925 4.58173 19.0391 8.11477 17.4439 10.8778C15.8487 13.6408 12.3156 14.5875 9.55264 12.9922ZM5.89567 4.2104C3.8086 7.82531 5.04716 12.4477 8.66207 14.5347C12.277 16.6218 16.8993 15.3833 18.9864 11.7683C21.0735 8.15344 19.8349 3.53107 16.22 1.444C12.6051 -0.643067 7.98274 0.595493 5.89567 4.2104Z"
                  fill="#021C24"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.77591 16.9307C2.31467 14.9006 1.96516 11.2596 3.99525 8.79839L2.62121 7.66505C-0.0348025 10.8851 0.422476 15.6487 3.64257 18.3047C6.86267 20.9607 11.6262 20.5034 14.2822 17.2833L12.9082 16.15C10.8781 18.6112 7.23715 18.9607 4.77591 16.9307Z"
                  fill="#021C24"
                />
                <rect
                  width="4.18191"
                  height="4.18191"
                  transform="matrix(0.707107 0.707107 0.707107 -0.707107 9.48096 8.2294)"
                  fill="#021C24"
                />
              </svg>
              Token Settings
            </a>
          </li> */}
          <p className="innerlistpara">Self Service Projects</p>
          <li>
            <a
              className={indexwait === 20 ? "active" : ""}
              onClick={() => {
                hitfunctionss(20);
                setSidebar(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
              >
                <path
                  d="M3.46754 17.4928L1.37785 19.3011L1.37785 11.5772L3.46754 11.5772L3.46754 17.4928Z"
                  fill="#02171D"
                  stroke="#02171D"
                  stroke-width="0.0835874"
                />
                <path
                  d="M3.46754 7.29501L1.37785 9.10339L1.37785 1.37941L3.46754 1.37941L3.46754 7.29501Z"
                  fill="#02171D"
                  stroke="#02171D"
                  stroke-width="0.0835874"
                />
                <rect
                  x="2.38121"
                  y="11.5769"
                  width="7.18851"
                  height="2.08968"
                  fill="#02171D"
                  stroke="#02171D"
                  stroke-width="0.0835874"
                />
                <rect
                  x="2.38121"
                  y="1.37968"
                  width="7.18851"
                  height="2.08968"
                  fill="#02171D"
                  stroke="#02171D"
                  stroke-width="0.0835874"
                />
                <path
                  d="M13.7505 17.4928L11.6608 19.3011L11.6608 11.5772L13.7505 11.5772L13.7505 17.4928Z"
                  fill="#02171D"
                  stroke="#02171D"
                  stroke-width="0.0835874"
                />
                <path
                  d="M13.7505 7.29501L11.6608 9.10339L11.6608 1.37941L13.7505 1.37941L13.7505 7.29501Z"
                  fill="#02171D"
                  stroke="#02171D"
                  stroke-width="0.0835874"
                />
                <rect
                  x="12.6642"
                  y="11.5769"
                  width="7.18851"
                  height="2.08968"
                  fill="#02171D"
                  stroke="#02171D"
                  stroke-width="0.0835874"
                />
                <rect
                  x="17.6793"
                  y="17.261"
                  width="2.17327"
                  height="2.08968"
                  fill="#02171D"
                  stroke="#02171D"
                  stroke-width="0.0835874"
                />
                <rect
                  x="17.6793"
                  y="6.97978"
                  width="2.17327"
                  height="2.08968"
                  fill="#02171D"
                  stroke="#02171D"
                  stroke-width="0.0835874"
                />
                <rect
                  x="7.39818"
                  y="17.261"
                  width="2.17327"
                  height="2.08968"
                  fill="#02171D"
                  stroke="#02171D"
                  stroke-width="0.0835874"
                />
                <rect
                  x="7.39818"
                  y="6.97978"
                  width="2.17327"
                  height="2.08968"
                  fill="#02171D"
                  stroke="#02171D"
                  stroke-width="0.0835874"
                />
                <rect
                  x="12.6642"
                  y="1.37968"
                  width="7.18851"
                  height="2.08968"
                  fill="#02171D"
                  stroke="#02171D"
                  stroke-width="0.0835874"
                />
              </svg>
              Dashboard
            </a>
          </li>
          <li>
            <a
              className={indexwait === 19 ? "active" : ""}
              onClick={() => {
                hitfunctionss(19);
                setSidebar(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
              >
                <path
                  d="M15.2256 18.7794L15.2256 16.4011L9.46249 13.175L9.46249 15.5533L15.2256 18.7794Z"
                  fill="#02171D"
                />
                <path
                  d="M0 18.7794L7.27727e-07 16.4011L5.7631 13.175L5.7631 15.5533L0 18.7794Z"
                  fill="#02171D"
                />
                <path
                  d="M20.814 18.274L20.814 15.8956L15.4745 12.9241L15.4745 15.3025L20.814 18.274Z"
                  fill="#02171D"
                />
                <circle
                  cx="7.56421"
                  cy="7.11484"
                  r="3.13453"
                  stroke="#02171D"
                  stroke-width="1.88685"
                />
                <path
                  d="M13.833 10.2494C15.5642 10.2494 16.9675 8.84602 16.9675 7.11487C16.9675 5.38372 15.5642 3.98035 13.833 3.98035"
                  stroke="#02171D"
                  stroke-width="1.88685"
                />
              </svg>
              All Referrers
            </a>
          </li>
          {/* <li>
            <a
              className={indexwait === 28 ? "active" : ""}
              onClick={() => {
                hitfunctionss(28);
                setSidebar(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="16"
                viewBox="0 0 21 16"
                fill="none"
              >
                <path
                  d="M4.36458 10.9319L9.95296 10.9319L8.89239 8.81074L3.18164 8.81074L4.36458 10.9319Z"
                  fill="#02171D"
                />
                <path
                  d="M7.50558 10.8911L10.1963 15.7464L11.5172 13.7768L8.89247 8.85151L7.50558 10.8911Z"
                  fill="#02171D"
                />
                <path
                  d="M4.44619 11.0135L1.84448 15.7465L0.523614 13.7769L2.20268 11.0135L4.44619 11.0135Z"
                  fill="#02171D"
                />
                <path
                  d="M13.0941 10.9319L18.6825 10.9319L17.6219 8.81074L11.9111 8.81074L13.0941 10.9319Z"
                  fill="#02171D"
                />
                <path
                  d="M16.2351 10.8911L18.9258 15.7464L20.2467 13.7768L17.622 8.85151L16.2351 10.8911Z"
                  fill="#02171D"
                />
                <path
                  d="M8.93343 2.12109L14.5218 2.12109L13.4612 -5.19977e-05L7.75049 -5.24521e-05L8.93343 2.12109Z"
                  fill="#02171D"
                />
                <path
                  d="M12.0744 2.08029L14.7652 6.93563L16.0861 4.96602L13.4613 0.0407237L12.0744 2.08029Z"
                  fill="#02171D"
                />
                <path
                  d="M9.01504 2.2027L6.41333 6.93567L5.09246 4.96606L6.77153 2.2027L9.01504 2.2027Z"
                  fill="#02171D"
                />
              </svg>
              All Projects
            </a>
          </li> */}
          <li>
            <a
              className={indexwait === 21 ? "active" : ""}
              onClick={() => {
                hitfunctionss(21);
                setSidebar(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
              >
                <path
                  d="M8.27731 7.89946L9.39371 5.89855L15.7566 5.8896L14.6402 7.89052L8.27731 7.89946Z"
                  fill="#02171D"
                />
                <path
                  d="M16.8687 1.06111L20.1704 6.77983L17.6823 6.81682L14.3806 1.09811L16.8687 1.06111Z"
                  fill="#02171D"
                />
                <path
                  d="M16.8218 12.4998L20.1235 6.78112L17.6354 6.74412L14.3337 12.4628L16.8218 12.4998Z"
                  fill="#02171D"
                />
                <path
                  d="M11.8936 13.1007L10.7772 15.1016L4.41433 15.1105L5.53074 13.1096L11.8936 13.1007Z"
                  fill="#02171D"
                />
                <path
                  d="M7.47926 1.93826L6.36285 3.93917L0 3.94812L1.1164 1.9472L7.47926 1.93826Z"
                  fill="#02171D"
                />
                <path
                  d="M20.1843 17.0634L19.0679 19.0643L12.7051 19.0733L13.8215 17.0724L20.1843 17.0634Z"
                  fill="#02171D"
                />
                <path
                  d="M3.30219 19.939L0.000495173 14.2203L2.48861 14.1833L5.79031 19.902L3.30219 19.939Z"
                  fill="#02171D"
                />
                <path
                  d="M3.32319 8.50041L0.0214899 14.2191L2.50961 14.2561L5.81131 8.53741L3.32319 8.50041Z"
                  fill="#02171D"
                />
              </svg>
              All Transactions
            </a>
          </li>
          {user?.role === "admin" && (
            <>
              <li>
                <a
                  className={indexwait === 22 ? "active" : ""}
                  onClick={() => {
                    hitfunctionss(22);
                    setSidebar(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                  >
                    <path
                      d="M12.7051 3.89658L8.27495 3.89658L8.27495 1.7233L12.7051 1.7233L12.7051 3.89658Z"
                      fill="#02171D"
                    />
                    <path
                      d="M12.7051 18.8587L8.27495 18.8587L8.27495 16.6854L12.7051 16.6854L12.7051 18.8587Z"
                      fill="#02171D"
                    />
                    <path
                      d="M16.8848 12.4225L16.8848 7.99242L19.058 7.99242L19.058 12.4225L16.8848 12.4225Z"
                      fill="#02171D"
                    />
                    <path
                      d="M1.92236 12.4225L1.92236 7.99242L4.09563 7.99242L4.09563 12.4225L1.92236 12.4225Z"
                      fill="#02171D"
                    />
                    <path
                      d="M16.5776 7.33575L13.4451 4.20318L14.9818 2.66644L18.1144 5.79902L16.5776 7.33575Z"
                      fill="#02171D"
                    />
                    <path
                      d="M5.99854 17.9156L2.86596 14.7831L4.4027 13.2463L7.53527 16.3789L5.99854 17.9156Z"
                      fill="#02171D"
                    />
                    <path
                      d="M13.5044 16.3198L16.637 13.1872L18.1737 14.724L15.0411 17.8565L13.5044 16.3198Z"
                      fill="#02171D"
                    />
                    <path
                      d="M2.9248 5.73987L6.05738 2.60729L7.59411 4.14403L4.46154 7.2766L2.9248 5.73987Z"
                      fill="#02171D"
                    />
                    <rect
                      x="8.27539"
                      y="7.9924"
                      width="4.43013"
                      height="4.43013"
                      fill="#02171D"
                    />
                  </svg>
                  Sale Rounds
                </a>
              </li>
            </>
          )}
          <p className="innerlistpara">Settings</p>
          {user?.role == "admin" ? (
            <li>
              <a
                className={indexwait === 8 ? "active" : ""}
                onClick={() => {
                  hitfunctionss(8);
                  setSidebar(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.55264 12.9922C6.78963 11.397 5.84295 7.86398 7.43818 5.10097C9.0334 2.33796 12.5664 1.39128 15.3294 2.98651C18.0925 4.58173 19.0391 8.11477 17.4439 10.8778C15.8487 13.6408 12.3156 14.5875 9.55264 12.9922ZM5.89567 4.2104C3.8086 7.82531 5.04716 12.4477 8.66207 14.5347C12.277 16.6218 16.8993 15.3833 18.9864 11.7683C21.0735 8.15344 19.8349 3.53107 16.22 1.444C12.6051 -0.643067 7.98274 0.595493 5.89567 4.2104Z"
                    fill="#021C24"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.77591 16.9307C2.31467 14.9006 1.96516 11.2596 3.99525 8.79839L2.62121 7.66505C-0.0348025 10.8851 0.422476 15.6487 3.64257 18.3047C6.86267 20.9607 11.6262 20.5034 14.2822 17.2833L12.9082 16.15C10.8781 18.6112 7.23715 18.9607 4.77591 16.9307Z"
                    fill="#021C24"
                  />
                  <rect
                    width="4.18191"
                    height="4.18191"
                    transform="matrix(0.707107 0.707107 0.707107 -0.707107 9.48096 8.2294)"
                    fill="#021C24"
                  />
                </svg>
                Token Settings
              </a>
            </li>
          ) : null}
          {/* <li>
            <a
              className={indexwait === 28 ? "active" : ""}
              onClick={() => {
                hitfunctionss(28);
                setSidebar(false);
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                <path d="M15.2256 18.7794L15.2256 16.4011L9.46249 13.1749L9.46249 15.5533L15.2256 18.7794Z" fill="#02171D" />
                <path d="M0 18.7794L7.27727e-07 16.4011L5.7631 13.1749L5.7631 15.5533L0 18.7794Z" fill="#02171D" />
                <path d="M20.8142 18.274L20.8142 15.8956L15.4747 12.9242L15.4747 15.3025L20.8142 18.274Z" fill="#02171D" />
                <circle cx="7.56446" cy="7.11481" r="3.13453" stroke="#02171D" stroke-width="1.88685" />
                <path d="M13.8333 10.2493C15.5644 10.2493 16.9678 8.84596 16.9678 7.11481C16.9678 5.38366 15.5644 3.98029 13.8333 3.98029" stroke="#02171D" stroke-width="1.88685" />
              </svg>
              User Management
            </a>
          </li>
          <li>
            <a
              className={indexwait === 29 ? "active" : ""}
              onClick={() => {
                hitfunctionss(29);
                setSidebar(false);
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                <path d="M8.27731 7.89934L9.39371 5.89842L15.7566 5.88948L14.6402 7.89039L8.27731 7.89934Z" fill="#02171D" />
                <path d="M16.8687 1.06099L20.1704 6.7797L17.6823 6.8167L14.3806 1.09799L16.8687 1.06099Z" fill="#02171D" />
                <path d="M16.8221 12.4998L20.1238 6.78109L17.6357 6.74409L14.334 12.4628L16.8221 12.4998Z" fill="#02171D" />
                <path d="M11.8936 13.1007L10.7772 15.1016L4.41433 15.1105L5.53074 13.1096L11.8936 13.1007Z" fill="#02171D" />
                <path d="M7.47926 1.93823L6.36285 3.93914L0 3.94808L1.1164 1.94717L7.47926 1.93823Z" fill="#02171D" />
                <path d="M20.1843 17.0634L19.0679 19.0643L12.7051 19.0732L13.8215 17.0723L20.1843 17.0634Z" fill="#02171D" />
                <path d="M3.30219 19.939L0.000495173 14.2203L2.48861 14.1833L5.79031 19.902L3.30219 19.939Z" fill="#02171D" />
                <path d="M3.32295 8.50032L0.0212457 14.219L2.50936 14.256L5.81106 8.53732L3.32295 8.50032Z" fill="#02171D" />
              </svg>
              Activity Log
            </a>
          </li> */}
          {/* <li>
              <a
                className={indexwait === 12 ? "active" : ""}
                onClick={() => {
                  hitfunctionss(12);
                  setSidebar(false);
                }}
              >
                <img
                  src="\assets\sidebar\inusrancedetail.svg"
                  alt="img"
                  className="img-fluid"
                />
                Insurance Details
              </a>
            </li> */}
          {/* <li>
              <a
                className={indexwait === 9 ? "active" : ""}
                onClick={() => {
                  hitfunctionss(9);
                  setSidebar(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M17.9998 7.91002C17.9698 7.91002 17.9498 7.91002 17.9198 7.91002H17.8698C15.9798 7.85002 14.5698 6.39001 14.5698 4.59001C14.5698 2.75001 16.0698 1.26001 17.8998 1.26001C19.7298 1.26001 21.2298 2.76001 21.2298 4.59001C21.2198 6.40001 19.8098 7.86001 18.0098 7.92001C18.0098 7.91001 18.0098 7.91002 17.9998 7.91002ZM17.8998 2.75002C16.8898 2.75002 16.0698 3.57002 16.0698 4.58002C16.0698 5.57002 16.8398 6.37002 17.8298 6.41002C17.8398 6.40002 17.9198 6.40002 18.0098 6.41002C18.9798 6.36002 19.7298 5.56002 19.7398 4.58002C19.7398 3.57002 18.9198 2.75002 17.8998 2.75002Z"
                    fill="white"
                  />
                  <path
                    d="M18.01 15.28C17.62 15.28 17.23 15.25 16.84 15.18C16.43 15.11 16.16 14.72 16.23 14.31C16.3 13.9 16.69 13.63 17.1 13.7C18.33 13.91 19.63 13.68 20.5 13.1C20.97 12.79 21.22 12.4 21.22 12.01C21.22 11.62 20.96 11.24 20.5 10.93C19.63 10.35 18.31 10.12 17.07 10.34C16.66 10.42 16.27 10.14 16.2 9.73002C16.13 9.32002 16.4 8.93003 16.81 8.86003C18.44 8.57003 20.13 8.88002 21.33 9.68002C22.21 10.27 22.72 11.11 22.72 12.01C22.72 12.9 22.22 13.75 21.33 14.35C20.42 14.95 19.24 15.28 18.01 15.28Z"
                    fill="white"
                  />
                  <path
                    d="M5.96998 7.91C5.95998 7.91 5.94998 7.91 5.94998 7.91C4.14998 7.85 2.73998 6.39 2.72998 4.59C2.72998 2.75 4.22998 1.25 6.05998 1.25C7.88998 1.25 9.38998 2.75 9.38998 4.58C9.38998 6.39 7.97998 7.85 6.17998 7.91L5.96998 7.16L6.03998 7.91C6.01998 7.91 5.98998 7.91 5.96998 7.91ZM6.06998 6.41C6.12998 6.41 6.17998 6.41 6.23998 6.42C7.12998 6.38 7.90998 5.58 7.90998 4.59C7.90998 3.58 7.08998 2.75999 6.07998 2.75999C5.06998 2.75999 4.24998 3.58 4.24998 4.59C4.24998 5.57 5.00998 6.36 5.97998 6.42C5.98998 6.41 6.02998 6.41 6.06998 6.41Z"
                    fill="white"
                  />
                  <path
                    d="M5.96 15.28C4.73 15.28 3.55 14.95 2.64 14.35C1.76 13.76 1.25 12.91 1.25 12.01C1.25 11.12 1.76 10.27 2.64 9.68002C3.84 8.88002 5.53 8.57003 7.16 8.86003C7.57 8.93003 7.84 9.32002 7.77 9.73002C7.7 10.14 7.31 10.42 6.9 10.34C5.66 10.12 4.35 10.35 3.47 10.93C3 11.24 2.75 11.62 2.75 12.01C2.75 12.4 3.01 12.79 3.47 13.1C4.34 13.68 5.64 13.91 6.87 13.7C7.28 13.63 7.67 13.91 7.74 14.31C7.81 14.72 7.54 15.11 7.13 15.18C6.74 15.25 6.35 15.28 5.96 15.28Z"
                    fill="white"
                  />
                  <path
                    d="M11.9998 15.38C11.9698 15.38 11.9498 15.38 11.9198 15.38H11.8698C9.97982 15.32 8.56982 13.86 8.56982 12.06C8.56982 10.22 10.0698 8.72998 11.8998 8.72998C13.7298 8.72998 15.2298 10.23 15.2298 12.06C15.2198 13.87 13.8098 15.33 12.0098 15.39C12.0098 15.38 12.0098 15.38 11.9998 15.38ZM11.8998 10.22C10.8898 10.22 10.0698 11.04 10.0698 12.05C10.0698 13.04 10.8398 13.84 11.8298 13.88C11.8398 13.87 11.9198 13.87 12.0098 13.88C12.9798 13.83 13.7298 13.03 13.7398 12.05C13.7398 11.05 12.9198 10.22 11.8998 10.22Z"
                    fill="white"
                  />
                  <path
                    d="M11.9998 22.76C10.7998 22.76 9.59978 22.45 8.66978 21.82C7.78978 21.23 7.27979 20.39 7.27979 19.49C7.27979 18.6 7.77978 17.74 8.66978 17.15C10.5398 15.91 13.4698 15.91 15.3298 17.15C16.2098 17.74 16.7198 18.58 16.7198 19.48C16.7198 20.37 16.2198 21.23 15.3298 21.82C14.3998 22.44 13.1998 22.76 11.9998 22.76ZM9.49979 18.41C9.02979 18.72 8.77979 19.11 8.77979 19.5C8.77979 19.89 9.03979 20.27 9.49979 20.58C10.8498 21.49 13.1398 21.49 14.4898 20.58C14.9598 20.27 15.2098 19.88 15.2098 19.49C15.2098 19.1 14.9498 18.72 14.4898 18.41C13.1498 17.5 10.8598 17.51 9.49979 18.41Z"
                    fill="white"
                  />
                </svg>
                Testnet Round
              </a>
            </li> */}
          <div className="btnsmains">
            <Navbar setSidebar={setSidebar} mobileNav={true} />
            <button onClick={() => Logout()} className="logout-btn d-none">
              Logout
            </button>
          </div>
        </ul>
      </Offcanvas>
      {/* )} */}
    </>
  );
}

export default Landing;
