import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import {
  getClaimsContract,
  getLEIAClaimsContract,
  getOlympusClaimsContract,
  getNFAClaimsContract,
  getAstroNovaClaimsContract,
  getAstroNovaClaimsV2Contract,
  getMinersClaimsContract,
  getRainClaimsContract,
  getRainClaimsV2Contract,
  getRainClaimsV3Contract,
} from "../../utils/contractHelpers";

import { useWeb3React } from "@web3-react/core";

const AllowClaimingHook = () => {
  const web3 = useWeb3();
  const { account } = useWeb3React();
  const tokenAddress = environment.Claim;
  const contract = getClaimsContract(tokenAddress, web3);

  const tokenAddressLEIA = environment.LEIAClaim;
  const contractLEIA = getLEIAClaimsContract(tokenAddressLEIA, web3);

  const tokenAddressOlympus = environment.OlympusClaim;
  const contractOlympus = getOlympusClaimsContract(tokenAddressOlympus, web3);

  const tokenAddressNFA = environment.NFAClaim;
  const contractNFA = getNFAClaimsContract(tokenAddressNFA, web3);

  const tokenAddressAstroNova = environment.AstroNovaClaim;
  const contractAstroNova = getAstroNovaClaimsContract(
    tokenAddressAstroNova,
    web3
  );

  const tokenAddressAstroNovaV2 = environment.AstroNovaClaimV2;
  const contractAstroNovaV2 = getAstroNovaClaimsV2Contract(
    tokenAddressAstroNovaV2,
    web3
  );

  const tokenAddressRain = environment.RainClaim;
  const contractRain = getRainClaimsContract(tokenAddressRain, web3);

  const tokenAddressRainV2 = environment.RainClaimV2;
  const contractRainV2 = getRainClaimsV2Contract(tokenAddressRainV2, web3);

  const tokenAddressRainV3 = environment.RainClaimV3;
  const contractRainV3 = getRainClaimsV3Contract(tokenAddressRainV3, web3);

  const tokenAddressMiners = environment.MinersClaim;
  const contractMiners = getMinersClaimsContract(tokenAddressMiners, web3);

  const project = localStorage.getItem("project");

  const allowClaimingHook = useCallback(
    async (bool, round, decesion) => {
      const roundNum = round?.round;
      console.log("round in hook: ", round, project, roundNum);

      try {
        if (project == "INCENTIV") {
          if (bool) {
            const details = await contract.methods.isEnabled(roundNum).call();
            return details;
          } else {
            const gas = await contract.methods
              .enableClaims(roundNum, decesion)
              .estimateGas({ from: account });
            const details = await contract.methods
              .enableClaims(roundNum, decesion)
              .send({
                from: account,
                gas,
              });
            return details;
          }
        } else if (project == "OLYMPUS") {
          if (bool) {
            const details = await contractOlympus.methods
              .isEnabled(roundNum)
              .call();
            return details;
          } else {
            const gas = await contractOlympus.methods
              .enableClaims(roundNum, decesion)
              .estimateGas({ from: account });
            const details = await contractOlympus.methods
              .enableClaims(roundNum, decesion)
              .send({
                from: account,
                gas,
              });
            return details;
          }
        } else if (project == "LEIA") {
          if (bool) {
            const details = await contractLEIA.methods
              .isEnabled(roundNum)
              .call();
            return details;
          } else {
            const gas = await contractLEIA.methods
              .enableClaims(roundNum, decesion)
              .estimateGas({ from: account });
            const details = await contractLEIA.methods
              .enableClaims(roundNum, decesion)
              .send({
                from: account,
                gas,
              });
            return details;
          }
        } else if (project == "NFA") {
          if (bool) {
            const details = await contractNFA.methods
              .isEnabled(roundNum)
              .call();
            return details;
          } else {
            const gas = await contractNFA.methods
              .enableClaims(roundNum, decesion)
              .estimateGas({ from: account });
            const details = await contractNFA.methods
              .enableClaims(roundNum, decesion)
              .send({
                from: account,
                gas,
              });
            return details;
          }
        } else if (project == "ASTRANOVA") {
          if (round?.version == 1) {
            if (bool) {
              const details = await contractAstroNova.methods
                .isEnabled(roundNum)
                .call();
              return details;
            } else {
              const gas = await contractAstroNova.methods
                .enableClaims(roundNum, decesion)
                .estimateGas({ from: account });
              const details = await contractAstroNova.methods
                .enableClaims(roundNum, decesion)
                .send({
                  from: account,
                  gas,
                });
              return details;
            }
          } else if (round?.version == 2) {
            if (bool) {
              const details = await contractAstroNovaV2.methods
                .isEnabled(roundNum)
                .call();
              return details;
            } else {
              const gas = await contractAstroNovaV2.methods
                .enableClaims(roundNum, decesion)
                .estimateGas({ from: account });
              const details = await contractAstroNovaV2.methods
                .enableClaims(roundNum, decesion)
                .send({
                  from: account,
                  gas,
                });
              return details;
            }
          }
        } else if (project == "RAIN") {
          if (round?.version == 1) {
            if (bool) {
              const details = await contractRain.methods
                .isEnabled(roundNum)
                .call();
              return details;
            } else {
              const gas = await contractRain.methods
                .enableClaims(roundNum, decesion)
                .estimateGas({ from: account });
              const details = await contractRain.methods
                .enableClaims(roundNum, decesion)
                .send({
                  from: account,
                  gas,
                });
              return details;
            }
          } else if (round?.version == 2) {
            console.log("in this version 2 if: ");

            if (bool) {
              const details = await contractRainV2.methods
                .isEnabled(roundNum)
                .call();
              return details;
            } else {
              // const gas = await contractRainV2.methods
              //   .enableClaims(roundNum, decesion)
              //   .estimateGas({ from: account });
              const details = await contractRainV2.methods
                .enableClaims(roundNum, decesion)
                .send({
                  from: account,
                  // gas,
                });
              return details;
            }
          } else if (round?.version == 3) {
            console.log("in this version 3 if: ");

            if (bool) {
              const details = await contractRainV3.methods
                .isEnabled(roundNum)
                .call();
              return details;
            } else {
              // const gas = await contractRainV2.methods
              //   .enableClaims(roundNum, decesion)
              //   .estimateGas({ from: account });
              const details = await contractRainV3.methods
                .enableClaims(roundNum, decesion)
                .send({
                  from: account,
                  // gas,
                });
              return details;
            }
          }
        }
        // else if (project == "NODEMINER") {
        //   if (bool) {
        //     const details = await contractMiners.methods
        //       .isEnabled(roundNum)
        //       .call();
        //     return details;
        //   } else {
        //     const gas = await contractMiners.methods
        //       .enableClaims(roundNum, decesion)
        //       .estimateGas({ from: account });
        //     const details = await contractMiners.methods
        //       .enableClaims(roundNum, decesion)
        //       .send({
        //         from: account,
        //         gas,
        //       });
        //     return details;
        //   }
        // }
      } catch (error) {
        throw error;
      }
    },
    [contract]
  );
  return { allowClaimingHook: allowClaimingHook };
};
export default AllowClaimingHook;
