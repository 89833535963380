import React, { useState, useEffect } from "react";
import "./allreferrers.scss";
import { Accordion, Dropdown, Offcanvas } from "react-bootstrap";
import DataTable from "./DataTable/DataTable";
import ViewDetail from "./ViewDetail";
import { Api_Url } from "../../../src/utils/ApiUrl";
import axios from "axios";
const Allreferrers = ({
  routes,
  setRoutes,
  user,
  setindexwait,
  GetMyData,
  tomiPrice,
  usdPrice,
  btcPrice,
  pepePrice,
  linkPrice,
  uniPrice,
  dopPrice,
  gemsPrice,
}) => {
  const [refs, setRefs] = useState([]);
  const [loader, setLoader] = useState(false);
  const [leadersLoader, setLeadersLoader] = useState(false);
  const [blockUnblock, setBlockUnblock] = useState(false);
  const [codeBlockFilter, setCodeBlockFilter] = useState(true);
  const [deletedFilter, setDeletedFilter] = useState(false);
  const [selectedLeaders, setSelectedLeaders] = useState([]);
  const [searchBy, setSearchBy] = useState("");
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  // const userObj = JSON.parse(localStorage.getItem("user"));
  const project = localStorage.getItem("project");

  const [show, setShow] = useState(false);
  const [agents, setAgents] = useState([]);
  const [name, setName] = useState("");
  const [role, setRole] = useState(null);
  const [addr, setAddr] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [offset, setOffset] = useState(1);
  const [count, setCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const [limit, setLimit] = useState(1);
  const [pages, setPages] = useState([]);
  const [page, setPage] = useState(0);
  const [search0, setSearch0] = useState("");
  const [userDel, setUserDel] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [leaderNames, setLeaderNames] = useState([]);
  const [leaderNames2, setLeaderNames2] = useState([]);
  const [leaderFilter, setLeaderFilter] = useState("");
  const [activeFilter, setActiveFilter] = useState();
  const [inActiveFilter, setInActiveFilter] = useState();
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [roleTypeFilter, setRoleTypeFilter] = useState("");
  const [premiumFilter, setPremiumFilter] = useState();

  const [searchQuery, setSearchQuery] = useState("");
  const [myTree, setMyTree] = useState(false);

  const [orderField, setOrderField] = useState("totalReferrals");
  const [orderDirection, setOrderDirection] = useState("-1");

  const handlePageChange = (e) => {
    const selectedPage = e.selected;

    setPage(selectedPage);
  };

  const [searchInputLeaders, setSearchInputLeaders] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [roundsData, setRoundsData] = useState([]);
  const [privateSaleFilter, setPrivateSaleFilter] = useState([]);
  const val = localStorage.getItem("accessToken");

  const handleCheckboxChangeRounds = (projectName, round = null) => {
    setPrivateSaleFilter((prevFilters) => {
      const existingProject = prevFilters.find(
        (item) => item.privateSale === projectName
      );

      if (existingProject) {
        // If the project exists in the filter
        if (round) {
          // If a specific round is being checked/unchecked
          const updatedRounds = existingProject.round.includes(round)
            ? existingProject.round.filter((r) => r !== round)
            : [...existingProject.round, round];

          // If no rounds are left after unchecking, remove the project entirely
          if (updatedRounds.length === 0) {
            return prevFilters.filter(
              (item) => item.privateSale !== projectName
            );
          }

          // Update the rounds for this project
          return prevFilters.map((item) =>
            item.privateSale === projectName
              ? { ...item, round: updatedRounds }
              : item
          );
        } else {
          // If no specific round is passed, remove the entire project from the filter
          return prevFilters.filter((item) => item.privateSale !== projectName);
        }
      } else {
        // If the project doesn't exist in the filter
        return [
          ...prevFilters,
          {
            privateSale: projectName,
            round: round ? [round] : [],
          },
        ];
      }
    });
  };

  useEffect(() => {
    getRoundsAndProjects();
  }, []);
  const getRoundsAndProjects = () => {
    var config = {
      method: "get",
      url: `${Api_Url}/project-presale/referral/rounds-filter`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    axios(config)
      .then(function (response) {
        setRoundsData(response?.data?.data?.roundDetails);
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          // localStorage.removeItem("accessToken");
          // history.push("/");
        }
      });
  };

  useEffect(() => {
    if (user) {
      GetAgents();
    }
  }, [
    privateSaleFilter?.length,
    selectedLeaders?.length,
    selectedRoles?.length,
    user,
    myTree,
    activeFilter,
    inActiveFilter,
    premiumFilter,
    leaderFilter,
    roleTypeFilter,
    searchQuery,
    orderField,
    orderDirection,
    codeBlockFilter,
    deletedFilter,
    page,
    startDate,
    endDate,
  ]);

  const GetAgents = () => {
    setRefs([]);
    var config = {
      method: "post",
      url: `${Api_Url}/referral/admin/referrals`,
      data: {
        limit: 10,
        offset: page + 1,
        ...(activeFilter !== null && { isActive: activeFilter }),

        ...(selectedLeaders?.length > 0 && {
          nameFilter: selectedLeaders?.map((leader) => leader?.name),
        }),
        ...(selectedRoles?.length > 0 && { roleFilter: selectedRoles }),
        ...(premiumFilter !== null && { isPremium: premiumFilter }),
        ...(searchQuery && {
          searchFilter: searchQuery,
        }),

        ...(privateSaleFilter?.length > 0 && {
          privateSaleFilter: privateSaleFilter,
        }),

        ...(startDate && {
          startDate: startDate,
        }),

        ...(endDate && {
          endDate: endDate,
        }),

        orderField: orderField,
        orderDirection: orderDirection,
      },
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    axios(config)
      .then(function (response) {
        const resData = response?.data?.data;
        setCount(resData.count);
        setAgents(resData?.allReferrals);
        let arr = Array.from(Array(parseInt(resData.pages)).keys());
        setPageCount(resData.pages);
        setPages(arr);

        setLeadersLoader(false);
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          // localStorage.removeItem("accessToken");
          // history.push("/");
        }
        setLeadersLoader(false);
      });
  };

  return (
    <>
      {routes ? (
        <>
          <ViewDetail
            user={user}
            userDel={userDel}
            setRoutes={setRoutes}
            setUserDel={setUserDel}
            setShow={setShow}
            setBlockUnblock={setBlockUnblock}
            blockUnblock={blockUnblock}
            tomiPrice={tomiPrice}
            usdPrice={usdPrice}
            btcPrice={btcPrice}
            pepePrice={pepePrice}
            linkPrice={linkPrice}
            uniPrice={uniPrice}
            dopPrice={dopPrice}
            gemsPrice={gemsPrice}
            privateSaleFilter={privateSaleFilter}
          />
        </>
      ) : (
        <section className="allreferrermain">
          <div className="custom-container">
            <div className="innerreferrerheading">
              <div>
                {/* <p className="selfhead">Premium Projects</p> */}
                <p className="selfhead">self serve Projects</p>
                <h6 className="refferhead">All Referrers</h6>
              </div>
              <div className="multi-items">
                <div className="rightside-content">
                  <div className="custom-option-field">
                    {/* <Dropdown className="positionset">
                      <Dropdown.Toggle id="dropdown-basic">
                        By Link
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13"
                          height="7"
                          viewBox="0 0 13 7"
                          fill="none"
                        >
                          <path
                            d="M0 0.5L2.22397e-07 3.0462L6.2 6.5L6.2 3.9538L0 0.5Z"
                            fill="#02171D"
                          />
                          <path
                            d="M12.3984 0.5L12.3984 3.0462L6.19844 6.5L6.19844 3.9538L12.3984 0.5Z"
                            fill="#02171D"
                          />
                        </svg>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <a className="dropdown-item drop">By Referral Link</a>
                        <a className="dropdown-item drop">By Hash</a>
                        <a className="dropdown-item drop">Wallet Address</a>
                      </Dropdown.Menu>
                    </Dropdown> */}
                    <input
                      type="search"
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                      }}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                      className="img-fluid search-icon"
                    >
                      <path
                        d="M10.0008 9.23257C9.37556 10.2088 8.2814 10.8558 7.03612 10.8558C5.09321 10.8558 3.51818 9.28077 3.51818 7.33787C3.51818 5.39497 5.09321 3.81994 7.03612 3.81994C8.97902 3.81994 10.5541 5.39497 10.5541 7.33787C10.5541 7.89647 10.4239 8.42465 10.1922 8.89374L12.5736 8.89374C12.7124 8.39889 12.7866 7.87705 12.7866 7.33787C12.7866 4.16198 10.212 1.5874 7.03612 1.5874C3.86022 1.5874 1.28564 4.16198 1.28564 7.33787C1.28564 10.5138 3.86022 13.0883 7.03612 13.0883C8.66134 13.0883 10.1291 12.4141 11.1749 11.3301L10.0008 9.23257Z"
                        fill="#0A2931"
                      />
                      <path
                        d="M14.9076 17.3347L15.636 14.6165L10.0373 9.16457L9.30901 11.8828L14.9076 17.3347Z"
                        fill="#0A2931"
                      />
                    </svg>
                  </div>

                  <button className="whitnewbtn" onClick={handleShow}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M7.29339 15.1C6.97339 15.1 6.66006 15.02 6.36672 14.86C5.78006 14.5334 5.42672 13.94 5.42672 13.2734V9.74002C5.42672 9.40669 5.20672 8.90669 5.00006 8.65336L2.50672 6.01336C2.08672 5.59336 1.76672 4.87336 1.76672 4.33336V2.80002C1.76672 1.73336 2.57339 0.900024 3.60006 0.900024H12.4001C13.4134 0.900024 14.2334 1.72002 14.2334 2.73336V4.20002C14.2334 4.90002 13.8134 5.69336 13.4201 6.08669L10.5334 8.64002C10.2534 8.87336 10.0334 9.38669 10.0334 9.80002V12.6667C10.0334 13.26 9.66006 13.9467 9.19339 14.2267L8.27339 14.82C7.97339 15.0067 7.63339 15.1 7.29339 15.1ZM3.60006 1.90002C3.13339 1.90002 2.76672 2.29336 2.76672 2.80002V4.33336C2.76672 4.58002 2.96672 5.06002 3.22006 5.31336L5.76006 7.98669C6.10006 8.40669 6.43339 9.10669 6.43339 9.73336V13.2667C6.43339 13.7 6.73339 13.9134 6.86006 13.98C7.14006 14.1334 7.48006 14.1334 7.74006 13.9734L8.66672 13.38C8.85339 13.2667 9.04006 12.9067 9.04006 12.6667V9.80002C9.04006 9.08669 9.38672 8.30002 9.88672 7.88002L12.7401 5.35336C12.9667 5.12669 13.2401 4.58669 13.2401 4.19336V2.73336C13.2401 2.27336 12.8667 1.90002 12.4067 1.90002H3.60006Z"
                        fill="black"
                      />
                      <path
                        d="M3.99995 7.16664C3.90661 7.16664 3.81995 7.13997 3.73328 7.09331C3.49995 6.94664 3.42661 6.63331 3.57328 6.39997L6.85995 1.13331C7.00661 0.899972 7.31328 0.826639 7.54661 0.973306C7.77995 1.11997 7.85328 1.42664 7.70661 1.65997L4.41995 6.92664C4.32661 7.07997 4.16661 7.16664 3.99995 7.16664Z"
                        fill="black"
                      />
                    </svg>
                    filter
                  </button>

                  <button className="whitnewbtn">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                    >
                      <path
                        d="M6 7.8335V11.8335L7.33333 10.5002"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6.00008 11.8333L4.66675 10.5"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M14.6666 7.16683V10.5002C14.6666 13.8335 13.3333 15.1668 9.99992 15.1668H5.99992C2.66659 15.1668 1.33325 13.8335 1.33325 10.5002V6.50016C1.33325 3.16683 2.66659 1.8335 5.99992 1.8335H9.33325"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M14.6666 7.16683H11.9999C9.99992 7.16683 9.33325 6.50016 9.33325 4.50016V1.8335L14.6666 7.16683Z"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Download CSV
                  </button>
                </div>
              </div>
            </div>
            <DataTable
              project={project}
              btcPrice={btcPrice}
              usdPrice={usdPrice}
              tomiPrice={tomiPrice}
              pepePrice={pepePrice}
              linkPrice={linkPrice}
              uniPrice={uniPrice}
              dopPrice={dopPrice}
              gemsPrice={gemsPrice}
              setBlockUnblock={setBlockUnblock}
              blockUnblock={blockUnblock}
              setUserDel={setUserDel}
              currentPage={currentPage}
              GetAllRounds={GetAgents}
              pages={pages}
              leadersLoader={leadersLoader}
              GetAgents={GetAgents}
              page={page}
              pageCount={pageCount}
              offset={offset}
              setOffset={setOffset}
              agents={agents}
              setAgents={setAgents}
              setShow={setShow}
              setRoutes={setRoutes}
              setOrderDirection={setOrderDirection}
              setOrderField={setOrderField}
              handlePageChange={handlePageChange}
              pagination={true}
              tok={val}
              searchBy={searchBy}
              startDate={startDate}
              endDate={endDate}
              privateSaleFilter={privateSaleFilter}
              selectedLeaders={selectedLeaders}
              selectedRoles={selectedRoles}
              leaderFilter={leaderFilter}
              activeFilter={activeFilter}
              inActiveFilter={inActiveFilter}
              premiumFilter={premiumFilter}
              searchQuery={searchQuery}
              orderDirection={orderDirection}
              orderField={orderField}
            />
          </div>
        </section>
      )}

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        className="leadersfilter"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filters</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
              Projects & Rounds
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="8"
                viewBox="0 0 14 8"
                fill="none"
              >
                <path
                  d="M12.9182 0.178772H6.68816H1.07816C0.118161 0.178772 -0.361838 1.33877 0.318162 2.01877L5.49816 7.19877C6.32816 8.02877 7.67816 8.02877 8.50816 7.19877L10.4782 5.22877L13.6882 2.01877C14.3582 1.33877 13.8782 0.178772 12.9182 0.178772Z"
                  fill="black"
                />
              </svg>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Accordion defaultActiveKey="0">
                {roundsData.map((project, projectIndex) => (
                  <Accordion.Item
                    key={projectIndex}
                    eventKey={projectIndex.toString()}
                  >
                    <Accordion.Header>
                      <div className="maininnerbtn">
                        <label className="cyberpunk-checkbox-label">
                          <input
                            type="checkbox"
                            className="cyberpunk-checkbox"
                            checked={privateSaleFilter.some(
                              (item) =>
                                item.privateSale === project.privateSale &&
                                item.round.length === project.totalRounds
                            )}
                            onChange={() => {
                              // Check/uncheck all rounds
                              const allRoundsSelected = privateSaleFilter.some(
                                (item) =>
                                  item.privateSale === project.privateSale &&
                                  item.round.length === project.totalRounds
                              );
                              if (allRoundsSelected) {
                                project.rounds.forEach((round) => {
                                  handleCheckboxChangeRounds(
                                    project.privateSale,
                                    round
                                  );
                                });
                              } else {
                                for (let i = 1; i <= project.totalRounds; i++) {
                                  handleCheckboxChangeRounds(
                                    project.privateSale,
                                    i
                                  );
                                }
                              }
                            }}
                          />
                        </label>
                        <div className="projectmain">
                          <div className="projectmainimg">
                            <img
                              // src={`/assets/${project.privateSale.toLowerCase()}.svg`}
                              src={project?.projectImg}
                              alt="projectinnerimg"
                              className="projectinnerimg"
                            />
                          </div>
                          <p className="projectpara">{project.privateSale}</p>
                        </div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      {Array.from(
                        { length: project.totalRounds },
                        (_, roundIndex) => (
                          <div key={roundIndex} className="inneracc">
                            <label className="cyberpunk-checkbox-label">
                              <input
                                type="checkbox"
                                className="cyberpunk-checkbox"
                                checked={privateSaleFilter.some(
                                  (item) =>
                                    item.privateSale === project.privateSale &&
                                    item.round.includes(roundIndex + 1)
                                )}
                                onChange={() =>
                                  handleCheckboxChangeRounds(
                                    project.privateSale,
                                    roundIndex + 1
                                  )
                                }
                              />
                            </label>
                            <p className="inneracccpara">
                              Round {roundIndex + 1}
                            </p>
                          </div>
                        )
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Dropdown.Menu>
          </Dropdown>
          <div className="spanmain">
            {privateSaleFilter.map((project) =>
              project.round.map((round, index) => {
                return (
                  <div
                    className="innerspan"
                    key={index}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleCheckboxChangeRounds(project.privateSale, round);
                    }}
                  >
                    {project.privateSale} Round {round}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M5.14282 12.8571L12.8571 5.14282"
                        stroke="white"
                        stroke-width="1.2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12.8571 12.8571L5.14282 5.14282"
                        stroke="white"
                        stroke-width="1.2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                );
              })
            )}
          </div>

          <div className="bottombtns">
            <button className="whitebtn">Clear all</button>
            <button
              className="greybtn"
              onClick={() => {
                handleClose();
              }}
            >
              Apply
            </button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Allreferrers;
