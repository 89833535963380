import React, { useEffect, useState } from "react";
import DataTable from "./DataTable/DataTable";
import { Dropdown, Modal } from "react-bootstrap";
import axios from "axios";
import { Api_Url } from "../../../utils/ApiUrl";
import Web3 from "web3";
import { toast } from "react-toastify";
import Loader from "../../../hooks/loader";
import useWeb3 from "../../../hooks/useWeb3";
import { getERC20Token } from "../../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
// import { getdoproundcreatecontract } from "../../../utils/contractHelpers";
import { getPreSaleContract } from "../../../utils/contractHelpers";
import {
    getinstitutionpresalecontract,
    getLEIAInstitutionPreSaleContract,
    getOlympusInstitutionPreSaleContract,
} from "../../../utils/contractHelpers";
import { getLEIAPreSaleContract } from "../../../utils/contractHelpers";
import {
    getOlympusPreSaleContract,
    getNFAPreSaleContract,
    getAstroNovaPreSaleContract,
} from "../../../utils/contractHelpers";

import Environment from "../../../utils/Environment";
import TimerNFT from "../../../hooks/dataFetchers/timerNft";

const ActivityLog = () => {
    const val = localStorage.getItem("accessToken");
    const { account } = useWeb3React();
    const { timerNFT } = TimerNFT();
    const web3 = useWeb3();
    const [project, setProject] = useState("ASTRANOVA");
    const [selectedItem, setSelectedItem] = useState({
        imgSrc: "/assets/astronova.svg",
        text: "ASTRANOVA",
    });

    const [loader, setLoader] = useState(false);

    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        setTokenDecimals("");
        setTokenName("");
        setTokenPriceFeed("");
        setNormalisationFactorNFT("");
        setNormalisationFactorToken("");
        setTokenSymbol("");
        setContractAddress("");
    };
    const handleShow = () => {
        setShow(true);
    };

    const [show1, setShow1] = useState(false);

    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const [tokenData, setTokenData] = useState([]);
    const [search, setSearch] = useState("");

    const [orderField, setOrderField] = useState("");
    const [orderDirection, setOrderDirection] = useState("");
    const [statusFilter, setStatusFilter] = useState("");

    const [contractAddress, setContractAddress] = useState([]);
    const [tokenName, setTokenName] = useState("");

    const [tokenSymbol, setTokenSymbol] = useState("");
    const [tokenDecimals, setTokenDecimals] = useState("");
    const [slippageTolerance, setSlippageTolerance] = useState("");
    const [normalisationFactorNFT, setNormalisationFactorNFT] = useState("");
    const [normalisationFactorToken, setNormalisationFactorToken] = useState("");
    const [tokenPriceFeed, setTokenPriceFeed] = useState("");
    const [tolerance, setTolerance] = useState("");

    const [errors, setErrors] = useState({});

    const handleItemClick = (imgSrc, text) => {
        setSelectedItem({ imgSrc, text });
        setProject(text);
    };

    const handleContractChange = async (e) => {
        try {
            let address = [e.target.value];
            setContractAddress(address);
            const contract = getERC20Token(e.target.value, web3);
            const name = await contract.methods.name().call();
            const symbol = await contract.methods.symbol().call();
            const decimals = await contract.methods.decimals().call();
            setTokenName(name);
            setTokenSymbol(symbol);
            setTokenDecimals(decimals);
        } catch (error) {
            throw error;
        }

        // await axios
        //   .get("https://api.coingecko.com/api/v3/coins/list")
        //   .then((response) => {
        //     // Data contains an array of tokens with their details
        //     console.log(response?.data);
        //     // Access each token's name, symbol, and icon using data[i].name, data[i].symbol, data[i].image
        //   })
        //   .catch((error) => console.error("Error fetching data:", error));
    };

    const connectwalletfirst = () => {
        toast.error("Please connect your wallet first ", {
            position: "top-right",
            autoClose: 3000,
        });
    };

    // useEffect(() => {
    //   tokenDataRegistry();
    // }, []);

    // const tokenDataRegistry = async () => {
    //   const tokdata = await timerNFT(
    //     "0x25762231808F040410586504fDF08Df259A2163c"
    //   );
    //   console.log("token data from reg: ", tokdata);
    // };
    useEffect(() => {
        getTokenData();
    }, [search, orderField, orderDirection, statusFilter, project]);

    const getTokenData = () => {
        var config = {
            method: "get",
            url: `${Api_Url}/tokens?limit=25&offset=1&privateSale=${project}`,

            headers: {
                Authorization: "Bearer " + val,
            },
        };
        let dynamicQuery = config.url;

        if (search) {
            dynamicQuery += `&search=${search}`;
        }
        if (orderField) {
            dynamicQuery += `&orderField=${orderField}&orderDirection=${orderDirection}`;
        }
        if (statusFilter == "active") {
            dynamicQuery += `&status=${true}`;
        } else if (statusFilter == "inactive") {
            dynamicQuery += `&status=${false}`;
        }
        config.url = dynamicQuery;
        axios(config)
            .then(function (res) {
                setTokenData(res?.data?.data?.tokens);

                setLoader(false);
            })
            .catch(function (error) {
                setLoader(false);
            });
    };

    const toggleStatus = async (id) => {
        var config = {
            method: "patch",
            url: `${Api_Url}/tokens/${id}/toggle-status`,

            headers: {
                Authorization: "Bearer " + val,
            },
        };

        await axios(config)
            .then(function (res) {
                getTokenData();
                setLoader(false);
            })
            .catch(function (error) {
                setLoader(false);
            });
    };

    const addToken = async () => {
        if (account) {
            if (ValidateForm()) {
                const data = {
                    ...(tokenName && { name: tokenName }),
                    address: contractAddress[0],
                    symbol: tokenSymbol,
                    decimal: tokenDecimals,
                    privateSale: project,
                };
                var config = {
                    method: "post",
                    url: `${Api_Url}/tokens`,
                    data: data,
                    headers: {
                        Authorization: "Bearer " + val,
                    },
                };
                setLoader(true);
                await axios(config)
                    .then((res) => {
                        const resData = res?.data?.data;
                        setPriceFeed();
                        getTokenData();
                    })
                    .catch((err) => {
                        console.log("err response: ", err?.response);
                        if (err?.response?.status == 409) {
                            setPriceFeed();
                            handleClose();
                            handleShow1();
                            // setLoader(false);
                            // toast.error(err?.response?.message);
                        } else {
                            setLoader(false);
                        }
                        toast.error(err?.response?.message);
                    });
            }
        } else {
            connectwalletfirst();
        }
    };

    const setPriceFeed = async () => {
        let contract, contractInstitution;
        if (project == "ASTRANOVA") {
            contract = getAstroNovaPreSaleContract(
                Environment.AstroNovaPreSale,
                web3
            );
            // contractInstitution = getNFAInstitutionPreSaleContract(
            //   Environment.NFAInstitutionPreSale,
            //   web3
            // );
        } else if (project == "NFA") {
            contract = getNFAPreSaleContract(Environment.NFAPreSale, web3);
            // contractInstitution = getNFAInstitutionPreSaleContract(
            //   Environment.NFAInstitutionPreSale,
            //   web3
            // );
        } else if (project == "LEIA") {
            contract = getLEIAPreSaleContract(Environment.LEIAPreSale, web3);
            contractInstitution = getLEIAInstitutionPreSaleContract(
                Environment.LEIAInstitutionPreSale,
                web3
            );
        } else if (project == "OLYMPUS") {
            contract = getOlympusPreSaleContract(Environment.OlympusPreSale, web3);
            contractInstitution = getOlympusInstitutionPreSaleContract(
                Environment.OlympusInstitutionPreSale,
                web3
            );
        } else if (project == "INCENTIV") {
            contract = getPreSaleContract(Environment.PreSale, web3);
            contractInstitution = getinstitutionpresalecontract(
                Environment.InstitutionPreSale,
                web3
            );
        }

        try {
            let data = [
                [
                    tokenPriceFeed,
                    parseInt(normalisationFactorToken),
                    parseInt(normalisationFactorNFT),
                    parseInt(tolerance),
                ],
            ];
            let dataInstitution = [
                [
                    tokenPriceFeed,
                    parseInt(normalisationFactorToken),
                    parseInt(tolerance),
                ],
            ];
            const createround1 = await contract.methods
                .setTokenPriceFeed(contractAddress, data)
                .send({ from: account })
                .on("transactionHash", (tx) => {
                    return tx.transactionHash;
                });

            if (createround1) {
                console.log(contractAddress, dataInstitution, "data institution");

                const createroundInstitution = await contractInstitution.methods
                    .setTokenPriceFeed(contractAddress, dataInstitution)
                    .send({ from: account })
                    .on("transactionHash", (tx) => {
                        return tx.transactionHash;
                    });
                if (createroundInstitution) {
                    handleClose();
                    handleShow1();
                    setLoader(false);
                }
            }
        } catch (e) {
            handleClose();
            handleShow1();
            setLoader(false);
            console.log(e);
        }
    };

    const ValidateForm = () => {
        // let input = userInput;
        let errors = {};
        let isValid = true;

        if (!contractAddress) {
            isValid = false;
            errors["address"] = "*Please enter a Contract Address";
        }

        if (!tokenSymbol) {
            isValid = false;
            errors["symbol"] = "*Please enter a Symbol";
        }

        if (!tokenDecimals) {
            isValid = false;
            errors["decimals"] = "*Please enter a Token Decimals";
        }

        // if (!slippageTolerance) {
        //   isValid = false;
        //   errors["tolerance"] = "*Please enter a Slippage Tolerance";
        // }

        if (
            (tokenSymbol == "eth" ||
                tokenSymbol == "ETH" ||
                contractAddress == "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE") &&
            !normalisationFactorToken
        ) {
            isValid = false;
            errors["NFToken"] = "*Please enter a Normalisation Factor for token";
        }
        if (
            (tokenSymbol == "eth" ||
                tokenSymbol == "ETH" ||
                contractAddress == "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE") &&
            !normalisationFactorNFT
        ) {
            isValid = false;
            errors["NFNFT"] = "*Please enter a Normalisation Factor for NFT";
        }

        if (
            (tokenSymbol == "eth" ||
                tokenSymbol == "ETH" ||
                contractAddress == "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE") &&
            !tolerance
        ) {
            isValid = false;
            errors["tolerance"] = "*Please enter a tolerance";
        }

        if (
            (tokenSymbol == "eth" ||
                tokenSymbol == "ETH" ||
                contractAddress == "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE") &&
            !tokenPriceFeed
        ) {
            isValid = false;
            errors["priceFeed"] = "*Please enter a Normalisation Factor for NFT";
        }

        setErrors(errors);
        return isValid;
    };

    return (
        <>
            {loader && <Loader />}
            <section className="salesround">
                <div className="custom-container">
                    <div className="main-heading">
                        <div>
                            <p className="selfhead">ADMIN Settings</p>
                            <h6>Activity Log</h6>
                        </div>
                    </div>
                    <DataTable
                        tokenData={tokenData}
                        toggleStatus={toggleStatus}
                        setOrderDirection={setOrderDirection}
                        setOrderField={setOrderField}
                    />
                </div>
            </section>

           
        </>
    );
};

export default ActivityLog;
