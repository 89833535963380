import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import {
  getPreSaleContract,
  getGEMSPreSaleContract,
  getLEIAPreSaleContract,
  getOlympusPreSaleContract,
  getNFAPreSaleContract,
  getAstroNovaPreSaleContract,
  getAstroNovaPreSaleV2Contract,
  getRainPreSaleContract,
  getRainPreSaleV2Contract,
  getRainPreSaleV3Contract,
} from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const Timer = () => {
  const [balance, setBalance] = useState(null);
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = Environment.PreSale;
  const incentivContractPresale = getPreSaleContract(tokenAddress, web3);

  const tokenAddressLEIA = Environment.LEIAPreSale;
  const LEIAContractPresale = getLEIAPreSaleContract(tokenAddressLEIA, web3);

  const tokenAddressGEMS = Environment.GemsPreSale;
  const GEMSContractPresale = getGEMSPreSaleContract(tokenAddressGEMS, web3);

  const tokenAddressOlympus = Environment.OlympusPreSale;
  const OlympusContractPresale = getOlympusPreSaleContract(
    tokenAddressOlympus,
    web3
  );

  const tokenAddressNFA = Environment.NFAPreSale;
  const NFAContractPresale = getNFAPreSaleContract(tokenAddressNFA, web3);

  const tokenAddressAstroNova = Environment.AstroNovaPreSale;
  const AstroNovaContractPresale = getAstroNovaPreSaleContract(
    tokenAddressAstroNova,
    web3
  );

  const tokenAddressAstroNovaV2 = Environment.AstroNovaPreSaleV2;
  const AstroNovaContractPresaleV2 = getAstroNovaPreSaleV2Contract(
    tokenAddressAstroNovaV2,
    web3
  );

  const tokenAddressRain = Environment.RainPreSale;
  const RainContractPresale = getRainPreSaleContract(tokenAddressRain, web3);

  const tokenAddressRainV2 = Environment.RainPreSaleV2;
  const RainContractPresaleV2 = getRainPreSaleV2Contract(
    tokenAddressRainV2,
    web3
  );

  const tokenAddressRainV3 = Environment.RainPreSaleV3;
  const RainContractPresaleV3 = getRainPreSaleV3Contract(
    tokenAddressRainV3,
    web3
  );
  const project = localStorage.getItem("project");

  const timer = useCallback(
    async (e, version) => {
      if (project == "INCENTIV") {
        try {
          const buy = await incentivContractPresale.methods.rounds(e).call();
          return buy;
        } catch (error) {
          throw error;
        }
      } else if (project == "OLYMPUS") {
        try {
          const buy = await OlympusContractPresale.methods.rounds(e).call();
          return buy;
        } catch (error) {
          throw error;
        }
      } else if (project == "LEIA") {
        try {
          const buy = await LEIAContractPresale.methods.rounds(e).call();
          return buy;
        } catch (error) {
          throw error;
        }
      } else if (project == "NFA") {
        try {
          const buy = await NFAContractPresale.methods.rounds(e).call();
          return buy;
        } catch (error) {
          throw error;
        }
      } else if (project == "ASTRANOVA") {
        if (version == 1) {
          try {
            const buy = await AstroNovaContractPresale.methods.rounds(e).call();
            return buy;
          } catch (error) {
            throw error;
          }
        } else if (version == 2) {
          try {
            const buy = await AstroNovaContractPresaleV2.methods
              .rounds(e)
              .call();
            return buy;
          } catch (error) {
            throw error;
          }
        }
      } else if (project == "RAIN") {
        if (version == 1) {
          try {
            const buy = await RainContractPresale.methods.rounds(e).call();
            return buy;
          } catch (error) {
            throw error;
          }
        } else if (version == 2) {
          try {
            const buy = await RainContractPresaleV2.methods.rounds(e).call();
            return buy;
          } catch (error) {
            throw error;
          }
        } else if (version == 3) {
          try {
            const buy = await RainContractPresaleV3.methods.rounds(e).call();
            return buy;
          } catch (error) {
            throw error;
          }
        }
      } else if (project == "GEMS") {
        try {
          const buy = await GEMSContractPresale.methods.rounds(e).call();
          return buy;
        } catch (error) {
          throw error;
        }
      }
    },
    [LEIAContractPresale, incentivContractPresale, account, web3]
  );
  return { timer: timer };
};
export default Timer;
