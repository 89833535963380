import React, { useState } from "react";
import "./datatable.scss";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { CopyToClipboard } from "react-copy-to-clipboard";

const DataTable = ({
  project,
  setRoutes,
  setShow,
  agents,
  leadersLoader,
  setAgents,
  pages,
  handlePageChange,
  page,
  pageCount,
  currentPage,
  GetAllRounds,
  setUserDel,
  sub,
  setOrderDirection,
  setOrderField,
  setBlockUnblock,
  blockUnblock,
  tomiPrice,
  usdPrice,
  btcPrice,
  pepePrice,
  linkPrice,
  uniPrice,
  dopPrice,
  gemsPrice,

  pagination,
  tok,

  searchBy,
  searchQuery,
  startDate,
  endDate,
  privateSaleFilter,
  selectedLeaders,
  selectedRoles,

  activeFilter,
  premiumFilter,
  orderDirection,
  orderField,
}) => {
  const [copy, setCopy] = useState(false);
  const [copy1, setCopy1] = useState(false);
  const [copy3, setCopy3] = useState(false);

  const textCopiedFun = (index) => {
    setCopy(index);

    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };

  const textCopiedFun1 = (index) => {
    setCopy1(index);

    setTimeout(() => {
      setCopy1(false);
    }, 1000);
  };

  const textCopiedFun3 = (index) => {
    setCopy3(index);

    setTimeout(() => {
      setCopy3(false);
    }, 1000);
  };
  return (
    <>
      <section className="datatable">
        <div className="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>Email</th>
                <th>Wallet Address</th>
                <th>Referral Link</th>
                <th>Total Referrals</th>

                <th>Purchases</th>
                <th>Earnings</th>
                <th>Total Sales</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {agents?.length > 0 ? (
                agents?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <p className="codepara">
                          <p>
                            {copy1 ? (
                              <span className="adadasdasdasdasdasdsad">
                                {item?.referral[0]?.email}
                                {/* <svg
                                  style={{ cursor: "pointer" }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  className={copy1 === index && "copy-button "}
                                >
                                  <path
                                    d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                                    stroke="#77868B"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                                    stroke="#77868B"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg> */}
                                {copy1 === index && (
                                  <p className="copy-text">Copied</p>
                                )}
                              </span>
                            ) : (
                              <>
                                {item?.referral[0]?.email && (
                                  <>
                                    <CopyToClipboard
                                      text={item?.referral[0]?.email}
                                      onCopy={(e) => textCopiedFun1(index)}
                                    >
                                      <span className="adadasdasdasdasdasdsad">
                                        {item?.referral[0]?.email}
                                        <svg
                                          style={{ cursor: "pointer" }}
                                          className={
                                            copy1 === index && "copy-button "
                                          }
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="15"
                                          height="16"
                                          viewBox="0 0 15 16"
                                          fill="none"
                                        >
                                          <path
                                            d="M9.41377 8.49972L9.44015 10.9496C9.46213 12.9911 8.65431 13.8165 6.61276 13.8385L4.1629 13.8649C2.12135 13.8869 1.29594 13.0791 1.27396 11.0375L1.24757 8.58766C1.22559 6.54611 2.03342 5.7207 4.07496 5.69871L6.52482 5.67233C8.56637 5.65035 9.39178 6.45817 9.41377 8.49972Z"
                                            stroke="#77868B"
                                            stroke-width="1.2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <path
                                            d="M12.8759 4.96213L12.9023 7.41198C12.9243 9.45353 12.1165 10.2789 10.0749 10.3009L9.43329 10.3078L9.41382 8.49961C9.39183 6.45806 8.56642 5.65024 6.52487 5.67222L4.71664 5.69169L4.70973 5.05006C4.68775 3.00851 5.49557 2.1831 7.53712 2.16112L9.98698 2.13474C12.0285 2.11275 12.8539 2.92058 12.8759 4.96213Z"
                                            stroke="#77868B"
                                            stroke-width="1.2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </svg>
                                      </span>
                                    </CopyToClipboard>
                                    {copy1 === index && (
                                      <p className="copy-text">Copied</p>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </p>
                        </p>
                      </td>
                      <td>
                        <p className="codepara">
                          <p>
                            <p>
                              {copy ? (
                                <span className="adadasdasdasdasdasdsad">
                                  {item?.referralWalletAddress?.slice(0, 11)}...
                                  {item?.referralWalletAddress?.slice(
                                    item?.referralWalletAddress?.length - 3,
                                    item?.referralWalletAddress?.length
                                  )}{" "}
                                  <svg
                                    style={{ cursor: "pointer" }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    className={copy === index && "copy-button "}
                                  >
                                    <path
                                      d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                                      stroke="#77868B"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                                      stroke="#77868B"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                  {copy === index && (
                                    <p className="copy-text">Copied</p>
                                  )}
                                </span>
                              ) : (
                                <>
                                  {item?.referralWalletAddress && (
                                    <>
                                      <CopyToClipboard
                                        text={item?.referralWalletAddress}
                                        onCopy={(e) => textCopiedFun(index)}
                                      >
                                        <span className="adadasdasdasdasdasdsad">
                                          {item?.referralWalletAddress?.slice(
                                            0,
                                            11
                                          )}
                                          ...
                                          {item?.referralWalletAddress?.slice(
                                            item?.referralWalletAddress
                                              ?.length - 3,
                                            item?.referralWalletAddress?.length
                                          )}{" "}
                                          <svg
                                            style={{ cursor: "pointer" }}
                                            className={
                                              copy === index && "copy-button "
                                            }
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="15"
                                            height="16"
                                            viewBox="0 0 15 16"
                                            fill="none"
                                          >
                                            <path
                                              d="M9.41377 8.49972L9.44015 10.9496C9.46213 12.9911 8.65431 13.8165 6.61276 13.8385L4.1629 13.8649C2.12135 13.8869 1.29594 13.0791 1.27396 11.0375L1.24757 8.58766C1.22559 6.54611 2.03342 5.7207 4.07496 5.69871L6.52482 5.67233C8.56637 5.65035 9.39178 6.45817 9.41377 8.49972Z"
                                              stroke="#77868B"
                                              stroke-width="1.2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                            <path
                                              d="M12.8759 4.96213L12.9023 7.41198C12.9243 9.45353 12.1165 10.2789 10.0749 10.3009L9.43329 10.3078L9.41382 8.49961C9.39183 6.45806 8.56642 5.65024 6.52487 5.67222L4.71664 5.69169L4.70973 5.05006C4.68775 3.00851 5.49557 2.1831 7.53712 2.16112L9.98698 2.13474C12.0285 2.11275 12.8539 2.92058 12.8759 4.96213Z"
                                              stroke="#77868B"
                                              stroke-width="1.2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        </span>
                                      </CopyToClipboard>
                                      {copy === index && (
                                        <p className="copy-text">Copied</p>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </p>
                          </p>
                        </p>
                      </td>
                      <td>
                        {item?.referralWalletAddress ===
                          "0x0000000000000000000000000000000000000000" ? (
                          ""
                        ) : (
                          <p className="codepara">
                            <CopyToClipboard
                              text={`gems.vip/${item?.referral[0]?.referralCode}`}
                              onCopy={(e) => textCopiedFun3(index)}
                            >
                              <p>
                                gems.vip/{item?.referral[0]?.referralCode}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className={copy3 === index && "copy-button "}
                                  width="15"
                                  height="16"
                                  viewBox="0 0 15 16"
                                  fill="none"
                                >
                                  <path
                                    d="M9.41377 8.49972L9.44015 10.9496C9.46213 12.9911 8.65431 13.8165 6.61276 13.8385L4.1629 13.8649C2.12135 13.8869 1.29594 13.0791 1.27396 11.0375L1.24757 8.58766C1.22559 6.54611 2.03342 5.7207 4.07496 5.69871L6.52482 5.67233C8.56637 5.65035 9.39178 6.45817 9.41377 8.49972Z"
                                    stroke="#77868B"
                                    stroke-width="1.2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M12.8759 4.96213L12.9023 7.41198C12.9243 9.45353 12.1165 10.2789 10.0749 10.3009L9.43329 10.3078L9.41382 8.49961C9.39183 6.45806 8.56642 5.65024 6.52487 5.67222L4.71664 5.69169L4.70973 5.05006C4.68775 3.00851 5.49557 2.1831 7.53712 2.16112L9.98698 2.13474C12.0285 2.11275 12.8539 2.92058 12.8759 4.96213Z"
                                    stroke="#77868B"
                                    stroke-width="1.2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </p>
                            </CopyToClipboard>
                          </p>
                        )}
                      </td>
                      <td>{item?.totalReferrals}</td>

                      <td>{item?.dopPurchased?.toFixed(4)}</td>
                      <td>{item?.referralComissionUsd?.toFixed(4)}</td>

                      <td>{item?.totalSaleConverted?.toFixed(4)}</td>
                      <td>
                        <div class="dropdown">
                          <button
                            class="dropdown-toggle"
                            type="button"
                            onClick={() => {
                              setRoutes(true);
                              setUserDel(item);
                            }}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                              <path d="M3.16683 8.66669L11.2802 8.66669L7.5535 12.3934L8.50016 13.3334L13.8335 8.00002L8.50016 2.66669L7.56016 3.60669L11.2802 7.33335L3.16683 7.33335L3.16683 8.66669Z" fill="#02171D" />
                            </svg>
                          </button>
                        
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : leadersLoader ? (
                <tr>
                  <td colSpan="12" className="text-center">
                    <div className="text-center">
                      <div class="d-flex align-items-center justify-content-center">
                        <div class="spinner-border text-info" role="status">
                          <span class="sr-only"></span>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : (
                <tr>
                  <td colSpan="10" className="text-center">
                    <div className="text-center">
                      <h6
                        className=""
                        style={{
                          color: "#000",
                          textAlign: "center",
                          marginTop: 60,
                        }}
                      >
                        No data found!
                      </h6>
                    </div>
                  </td>
                </tr>
              )}

              {/* <tr>
                <td>
                  <p className="codepara">
                    <p>
                      johndoe@gmail.com
                      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
                        <path d="M9.41377 8.49972L9.44015 10.9496C9.46213 12.9911 8.65431 13.8165 6.61276 13.8385L4.1629 13.8649C2.12135 13.8869 1.29594 13.0791 1.27396 11.0375L1.24757 8.58766C1.22559 6.54611 2.03342 5.7207 4.07496 5.69871L6.52482 5.67233C8.56637 5.65035 9.39178 6.45817 9.41377 8.49972Z" stroke="#77868B" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M12.8759 4.96213L12.9023 7.41198C12.9243 9.45353 12.1165 10.2789 10.0749 10.3009L9.43329 10.3078L9.41382 8.49961C9.39183 6.45806 8.56642 5.65024 6.52487 5.67222L4.71664 5.69169L4.70973 5.05006C4.68775 3.00851 5.49557 2.1831 7.53712 2.16112L9.98698 2.13474C12.0285 2.11275 12.8539 2.92058 12.8759 4.96213Z" stroke="#77868B" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </p>
                  </p>
                </td>
                <td>
                  <p className="codepara">
                    <p>
                      0x524...t56
                      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
                        <path d="M9.41377 8.49972L9.44015 10.9496C9.46213 12.9911 8.65431 13.8165 6.61276 13.8385L4.1629 13.8649C2.12135 13.8869 1.29594 13.0791 1.27396 11.0375L1.24757 8.58766C1.22559 6.54611 2.03342 5.7207 4.07496 5.69871L6.52482 5.67233C8.56637 5.65035 9.39178 6.45817 9.41377 8.49972Z" stroke="#77868B" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M12.8759 4.96213L12.9023 7.41198C12.9243 9.45353 12.1165 10.2789 10.0749 10.3009L9.43329 10.3078L9.41382 8.49961C9.39183 6.45806 8.56642 5.65024 6.52487 5.67222L4.71664 5.69169L4.70973 5.05006C4.68775 3.00851 5.49557 2.1831 7.53712 2.16112L9.98698 2.13474C12.0285 2.11275 12.8539 2.92058 12.8759 4.96213Z" stroke="#77868B" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </p>
                  </p>
                </td>
                <td>
                  <p className="codepara">
                    <p>
                      gems.vip/aas...
                      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
                        <path d="M9.41377 8.49972L9.44015 10.9496C9.46213 12.9911 8.65431 13.8165 6.61276 13.8385L4.1629 13.8649C2.12135 13.8869 1.29594 13.0791 1.27396 11.0375L1.24757 8.58766C1.22559 6.54611 2.03342 5.7207 4.07496 5.69871L6.52482 5.67233C8.56637 5.65035 9.39178 6.45817 9.41377 8.49972Z" stroke="#77868B" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M12.8759 4.96213L12.9023 7.41198C12.9243 9.45353 12.1165 10.2789 10.0749 10.3009L9.43329 10.3078L9.41382 8.49961C9.39183 6.45806 8.56642 5.65024 6.52487 5.67222L4.71664 5.69169L4.70973 5.05006C4.68775 3.00851 5.49557 2.1831 7.53712 2.16112L9.98698 2.13474C12.0285 2.11275 12.8539 2.92058 12.8759 4.96213Z" stroke="#77868B" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </p>
                  </p>
                </td>
                <td>4.5K</td>

                <td>1,500,000</td>
                <td>
                  1,500,000
                </td>

                <td>
                  $1,500,000
                </td>
                <td>
                  <div class="dropdown">
                    <button
                      class="dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src="\assets\more-icon.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          onClick={() => {
                            setRoutes(true);
                          }}
                          class="dropdown-item"
                          href="#"
                        >
                          <img
                            src="\assets\viewdetail.svg"
                            alt="img"
                            className="img-fluid"
                          />
                          View Details
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>
        <div className="footer-content mblviewdisplaynone mt-2">
          <div className="left-f">{/* <h6>SHOWING 1-10 OF 145</h6> */}</div>
          <div className="right-f">
            <nav aria-label="Page navigation example">
              <ul class="pagination">
                <nav aria-label="Page navigation example">
                  <ul class="text-center">
                    <ReactPaginate
                      previousLabel={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                        >
                          <path
                            d="M7.88904 1.93555L5.38763 1.93555L1.99459 7.99682L4.496 7.99682L7.88904 1.93555Z"
                            fill="#329879"
                          />
                          <path
                            d="M7.88904 14.0703L5.38763 14.0703L1.99459 8.00904L3.57108 6.96117L4.496 8.00904L7.88904 14.0703Z"
                            fill="#329879"
                          />
                          <path
                            d="M8.01655 6.85742H14.9616L13.6869 9.14313H6.74182L8.01655 6.85742Z"
                            fill="#329879"
                          />
                        </svg>
                      }
                      nextLabel={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                        >
                          <path
                            d="M9.11108 1.93555L11.6125 1.93555L15.0055 7.99682L12.5041 7.99682L9.11108 1.93555Z"
                            fill="#329879"
                          />
                          <path
                            d="M9.11108 14.0703L11.6125 14.0703L15.0055 8.00904L13.429 6.96117L12.5041 8.00904L9.11108 14.0703Z"
                            fill="#329879"
                          />
                          <path
                            d="M3.31318 6.85742H10.2582L8.98351 9.14313H2.03845L3.31318 6.85742Z"
                            fill="#329879"
                          />
                        </svg>
                      }
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      containerClassName="pagination"
                      activeClassName="active"
                    ></ReactPaginate>
                  </ul>
                </nav>
              </ul>
            </nav>
          </div>
        </div>
        <div className="mobiledesignss  d-none">
          <div className="new-accordionmobile dsuygfdsyufgsdyuf d-none">
            {agents?.length > 0 ? (
              agents?.map((item, index) => {
                return (
                  <>
                    <div className="upper-body">
                      <div className="inner-text">
                        <h6>Email</h6>
                        <p>
                          {copy1 ? (
                            <span className="adadasdasdasdasdasdsad">
                              {item?.referral[0]?.email}
                              {/* <svg
                                  style={{ cursor: "pointer" }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  className={copy1 === index && "copy-button "}
                                >
                                  <path
                                    d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                                    stroke="#77868B"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                                    stroke="#77868B"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg> */}
                              {copy1 === index && (
                                <p className="copy-text">Copied</p>
                              )}
                            </span>
                          ) : (
                            <>
                              {item?.referral[0]?.email && (
                                <>
                                  <CopyToClipboard
                                    text={item?.referral[0]?.email}
                                    onCopy={(e) => textCopiedFun1(index)}
                                  >
                                    <span className="adadasdasdasdasdasdsad">
                                      {item?.referral[0]?.email}
                                      <svg
                                        style={{ cursor: "pointer" }}
                                        className={
                                          copy1 === index && "copy-button "
                                        }
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15"
                                        height="16"
                                        viewBox="0 0 15 16"
                                        fill="none"
                                      >
                                        <path
                                          d="M9.41377 8.49972L9.44015 10.9496C9.46213 12.9911 8.65431 13.8165 6.61276 13.8385L4.1629 13.8649C2.12135 13.8869 1.29594 13.0791 1.27396 11.0375L1.24757 8.58766C1.22559 6.54611 2.03342 5.7207 4.07496 5.69871L6.52482 5.67233C8.56637 5.65035 9.39178 6.45817 9.41377 8.49972Z"
                                          stroke="#77868B"
                                          stroke-width="1.2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M12.8759 4.96213L12.9023 7.41198C12.9243 9.45353 12.1165 10.2789 10.0749 10.3009L9.43329 10.3078L9.41382 8.49961C9.39183 6.45806 8.56642 5.65024 6.52487 5.67222L4.71664 5.69169L4.70973 5.05006C4.68775 3.00851 5.49557 2.1831 7.53712 2.16112L9.98698 2.13474C12.0285 2.11275 12.8539 2.92058 12.8759 4.96213Z"
                                          stroke="#77868B"
                                          stroke-width="1.2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </span>
                                  </CopyToClipboard>
                                  {copy1 === index && (
                                    <p className="copy-text">Copied</p>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </p>
                      </div>
                      <div className="inner-text">
                        <h6>Wallet Address</h6>
                        <p>
                          {copy ? (
                            <span className="adadasdasdasdasdasdsad">
                              {item?.referralWalletAddress?.slice(0, 11)}...
                              {item?.referralWalletAddress?.slice(
                                item?.referralWalletAddress?.length - 3,
                                item?.referralWalletAddress?.length
                              )}{" "}
                              <svg
                                style={{ cursor: "pointer" }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                className={copy === index && "copy-button "}
                              >
                                <path
                                  d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                                  stroke="#77868B"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                                  stroke="#77868B"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              {copy === index && (
                                <p className="copy-text">Copied</p>
                              )}
                            </span>
                          ) : (
                            <>
                              {item?.referralWalletAddress && (
                                <>
                                  <CopyToClipboard
                                    text={item?.referralWalletAddress}
                                    onCopy={(e) => textCopiedFun(index)}
                                  >
                                    <span className="adadasdasdasdasdasdsad">
                                      {item?.referralWalletAddress?.slice(
                                        0,
                                        11
                                      )}
                                      ...
                                      {item?.referralWalletAddress?.slice(
                                        item?.referralWalletAddress?.length - 3,
                                        item?.referralWalletAddress?.length
                                      )}{" "}
                                      <svg
                                        style={{ cursor: "pointer" }}
                                        className={
                                          copy === index && "copy-button "
                                        }
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15"
                                        height="16"
                                        viewBox="0 0 15 16"
                                        fill="none"
                                      >
                                        <path
                                          d="M9.41377 8.49972L9.44015 10.9496C9.46213 12.9911 8.65431 13.8165 6.61276 13.8385L4.1629 13.8649C2.12135 13.8869 1.29594 13.0791 1.27396 11.0375L1.24757 8.58766C1.22559 6.54611 2.03342 5.7207 4.07496 5.69871L6.52482 5.67233C8.56637 5.65035 9.39178 6.45817 9.41377 8.49972Z"
                                          stroke="#77868B"
                                          stroke-width="1.2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M12.8759 4.96213L12.9023 7.41198C12.9243 9.45353 12.1165 10.2789 10.0749 10.3009L9.43329 10.3078L9.41382 8.49961C9.39183 6.45806 8.56642 5.65024 6.52487 5.67222L4.71664 5.69169L4.70973 5.05006C4.68775 3.00851 5.49557 2.1831 7.53712 2.16112L9.98698 2.13474C12.0285 2.11275 12.8539 2.92058 12.8759 4.96213Z"
                                          stroke="#77868B"
                                          stroke-width="1.2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </span>
                                  </CopyToClipboard>
                                  {copy === index && (
                                    <p className="copy-text">Copied</p>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </p>
                      </div>
                      <div className="inner-text">
                        <h6>Referral Link</h6>
                        <p>
                          {item?.referralWalletAddress ===
                            "0x0000000000000000000000000000000000000000" ? (
                            ""
                          ) : (
                            <p className="codepara">
                              <CopyToClipboard
                                text={`gems.vip/${item?.referral[0]?.referralCode}`}
                                onCopy={(e) => textCopiedFun3(index)}
                              >
                                <p>
                                  gems.vip/{item?.referral[0]?.referralCode}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className={
                                      copy3 === index && "copy-button "
                                    }
                                    width="15"
                                    height="16"
                                    viewBox="0 0 15 16"
                                    fill="none"
                                  >
                                    <path
                                      d="M9.41377 8.49972L9.44015 10.9496C9.46213 12.9911 8.65431 13.8165 6.61276 13.8385L4.1629 13.8649C2.12135 13.8869 1.29594 13.0791 1.27396 11.0375L1.24757 8.58766C1.22559 6.54611 2.03342 5.7207 4.07496 5.69871L6.52482 5.67233C8.56637 5.65035 9.39178 6.45817 9.41377 8.49972Z"
                                      stroke="#77868B"
                                      stroke-width="1.2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M12.8759 4.96213L12.9023 7.41198C12.9243 9.45353 12.1165 10.2789 10.0749 10.3009L9.43329 10.3078L9.41382 8.49961C9.39183 6.45806 8.56642 5.65024 6.52487 5.67222L4.71664 5.69169L4.70973 5.05006C4.68775 3.00851 5.49557 2.1831 7.53712 2.16112L9.98698 2.13474C12.0285 2.11275 12.8539 2.92058 12.8759 4.96213Z"
                                      stroke="#77868B"
                                      stroke-width="1.2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </p>
                              </CopyToClipboard>
                            </p>
                          )}
                        </p>
                      </div>

                      <div className="inner-text">
                        <h6>Total Referrals</h6>
                        <p>{item?.totalReferrals}</p>
                      </div>
                    </div>
                    <div className="bottom-body">
                      <Accordion>
                        <Accordion.Item>
                          <Accordion.Header>
                            More Info{" "}
                            <img
                              src="\assets\arrow-down.svg"
                              alt="img"
                              className="img-fluid"
                            />
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="inner-textt">
                              <p>Purchases </p>

                              <h6 className="textright">
                                {item?.dopPurchased?.toFixed(4)}
                              </h6>
                            </div>
                            <div className="inner-textt">
                              <p>Earnings</p>
                              <h6 className="textright">
                                ${item?.referralComissionUsd?.toFixed(4)}
                              </h6>
                            </div>
                            <div className="inner-textt">
                              <p>Total Sales</p>
                              <h6 className="textright">
                                ${item?.totalSaleConverted?.toFixed(4)}
                              </h6>
                            </div>
                            <div className="inner-textt">
                              <button
                                onClick={() => {
                                  setRoutes(true);
                                  setUserDel(item);
                                  setBlockUnblock(item?.isDeleted);
                                }}
                                className="view-btn"
                              >
                                View Details
                              </button>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </>
                );
              })
            ) : leadersLoader ? (
              <tr>
                <td colSpan="12" className="text-center">
                  <div className="text-center">
                    <div class="d-flex align-items-center justify-content-center">
                      <div class="spinner-border text-info" role="status">
                        <span class="sr-only"></span>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            ) : (
              <tr>
                <td colSpan="10" className="text-center">
                  <div className="text-center">
                    <h6
                      className=""
                      style={{
                        color: "#000",
                        textAlign: "center",
                        marginTop: 60,
                      }}
                    >
                      No data found!
                    </h6>
                  </div>
                </td>
              </tr>
            )}
          </div>
          <div className="footer-content mt-2">
            <div className="left-f">
              {/* <h6>SHOWING 1-10 OF 145</h6> */}
              {/* <h6>SHOWING 1-10 OF 145</h6> */}
            </div>
            <div className="right-f">
              <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <nav aria-label="Page navigation example">
                    <ul class="text-center">
                      <ReactPaginate
                        previousLabel={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <path
                              d="M7.38916 1.93555L4.88776 1.93555L1.49471 7.99682L3.99612 7.99682L7.38916 1.93555Z"
                              fill="#329879"
                            />
                            <path
                              d="M7.38916 14.0703L4.88776 14.0703L1.49471 8.00904L3.0712 6.96117L3.99612 8.00904L7.38916 14.0703Z"
                              fill="#329879"
                            />
                            <path
                              d="M7.51642 6.85742H14.4615L13.1868 9.14313H6.2417L7.51642 6.85742Z"
                              fill="#329879"
                            />
                          </svg>
                        }
                        nextLabel={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <path
                              d="M8.61084 1.93555L11.1122 1.93555L14.5053 7.99682L12.0039 7.99682L8.61084 1.93555Z"
                              fill="#329879"
                            />
                            <path
                              d="M8.61084 14.0703L11.1122 14.0703L14.5053 8.00904L12.9288 6.96117L12.0039 8.00904L8.61084 14.0703Z"
                              fill="#329879"
                            />
                            <path
                              d="M2.81306 6.85742H9.75811L8.48338 9.14313H1.53833L2.81306 6.85742Z"
                              fill="#329879"
                            />
                          </svg>
                        }
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        containerClassName="pagination"
                        activeClassName="active"
                      ></ReactPaginate>
                    </ul>
                  </nav>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DataTable;
