import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const Countrychart = ({ chartData, chartType, seeAll }) => {
  const [series, setSeries] = useState([{ data: [] }]);
  const [chartHeight, setChartHeight] = useState(450); // State for chart height

  const [options, setOptions] = useState({
    chart: {
      type: "bar",
      background: "#ECF5F9",
    },
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: true,
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "12px",
        colors: ["#FFF"],
      },
      formatter: function (val, { seriesIndex, dataPointIndex }) {
        const dataValue = parseFloat(val);
        if (!isNaN(dataValue)) {
          const formattedValue =
            chartType === "amount" ? `$${dataValue.toLocaleString()}` : val;
          return formattedValue;
        }
        return "";
      },
    },
    xaxis: {
      categories: [],
      labels: {
        style: {
          colors: ["#77868B"],
          fontSize: "14px",
        },
        rotateAlways: true,
        offsetY: 0,
        offsetX: 0,
        rotate: -35,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: ["#77868B"],
          fontSize: "14px",
        },
      },
    },
    grid: {
      show: false,
    },
    stroke: {
      show: false,
    },
    fill: {
      colors: ["#000"],
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const category = w.globals.labels[dataPointIndex];
        const capitalizedCategory =
          category.charAt(0).toUpperCase() + category.slice(1);
        const value = series[seriesIndex][dataPointIndex];
        const title = chartType === "amount" ? "Purchased Amount" : "Purchases";
        return `
          <div style="background-color: #ECF5F9; border: 1px solid #C1CBCE; padding: 10px; border-radius: 4px;">
            <div style="font-weight: bold;">${capitalizedCategory}</div>
            <div>${title}: ${
          chartType === "amount" ? `$${value.toLocaleString()}` : value
        }</div>
          </div>
        `;
      },
    },
  });

  useEffect(() => {
    const categories = chartData?.map((item) => item?._id);
    const data = chartData?.map((item) => {
      if (chartType === "purchases") {
        return item?.total;
      } else {
        return parseInt(item?.amountInUsd);
      }
    });

    const displayCount =
      seeAll && chartData?.length > 5 ? chartData?.length : seeAll ? 5 : 10; // Show all bars if seeAll is true, otherwise 10
    // const displayCount = 2;

    const updatedChartHeight = displayCount * 35; // Adjust chart height based on the number of bars

    // Only display a subset of the data based on the displayCount
    setSeries([{ data: data?.slice(0, displayCount) }]);
    setOptions((prevOptions) => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        categories: categories?.slice(0, displayCount),
      },
      chart: {
        ...prevOptions.chart,
        height: updatedChartHeight, // Update chart height
      },
    }));
    setChartHeight(updatedChartHeight);
  }, [chartData, chartType, seeAll]);

  return (
    <div id="chart" style={{ height: chartHeight }}>
      {" "}
      {/* Adjust outer box height */}
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={chartHeight}
      />
    </div>
  );
};

export default Countrychart;
