import React, { useState, useEffect } from "react";
import "./datatable.scss";
import { Accordion } from "react-bootstrap";
import moment from "moment";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { Api_Url } from "../../../../utils/ApiUrl";
import { CopyToClipboard } from "react-copy-to-clipboard";

const RowListing = ({ index, item, usdPrice, user, project }) => {
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [nr, setNr] = useState(0);
  const [earn, setEarn] = useState(0);
  const [earnT, setEarnT] = useState(0);
  const [copy, setCopy] = useState(false);
  const [copy1, setCopy1] = useState(false);

  const [copy2, setCopy2] = useState(false);
  const [copy3, setCopy3] = useState(false);

  const textCopiedFun = (index) => {
    setCopy(index);

    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };
  const textCopiedFun1 = (index) => {
    setCopy1(index);

    setTimeout(() => {
      setCopy1(false);
    }, 1000);
  };
  const textCopiedFun2 = (index) => {
    setCopy2(index);

    setTimeout(() => {
      setCopy2(false);
    }, 1000);
  };
  const textCopiedFun3 = (index) => {
    setCopy3(index);

    setTimeout(() => {
      setCopy3(false);
    }, 1000);
  };
  function formatTokenValue(tokenValue) {
    if (tokenValue >= 1000000) {
      return (tokenValue / 1000000).toFixed(2) + "M";
    } else if (tokenValue >= 1000) {
      return (tokenValue / 1000).toFixed(2) + "k";
    } else {
      return tokenValue?.toFixed(2);
    }
  }
  function formatPriceValue(tokenValue) {
    if (tokenValue >= 1000000) {
      return (tokenValue / 1000000).toFixed(3) + "M";
    } else if (tokenValue >= 1000) {
      return (tokenValue / 1000).toFixed(2) + "k";
    } else {
      return tokenValue.toFixed(2);
    }
  }
  useEffect(() => {
    if (user) {
      // calculate NR
      if (item?.tokenSelected === "ETH") {
        let vals =
          parseFloat(item?.AgentAmount) / 1000000000000000000 +
          parseFloat(item?.GodAgentAmount) / 1000000000000000000 +
          parseFloat(item?.MegaAgentAmount) / 1000000000000000000 +
          parseFloat(item?.SuperAgentAmount) / 1000000000000000000;
        let invest = parseFloat(item?.amountInvested) / 1000000000000000000;
        setEarnT(vals?.toFixed(4));
        setNr((invest - vals)?.toFixed(4));
        if (user?.role === "admin") {
          let vals =
            parseFloat(item?.AgentAmount) / 1000000000000000000 +
            parseFloat(item?.GodAgentAmount) / 1000000000000000000 +
            parseFloat(item?.MegaAgentAmount) / 1000000000000000000 +
            parseFloat(item?.SuperAgentAmount) / 1000000000000000000;
          // console.log("VVVVVVVV", vals);
          setEarn(vals?.toFixed(4));
        } else if (user?.role === "agent") {
          let vals = parseFloat(item?.AgentAmount) / 1000000000000000000;
          setEarn(vals?.toFixed(4));
        } else if (user?.role === "god_agent") {
          let vals = parseFloat(item?.GodAgentAmount) / 1000000000000000000;
          setEarn(vals?.toFixed(4));
        } else if (user?.role === "mega_agent") {
          let vals = parseFloat(item?.MegaAgentAmount) / 1000000000000000000;
          setEarn(vals?.toFixed(4));
        } else if (user?.role === "super_agent") {
          let vals = parseFloat(item?.SuperAgentAmount) / 1000000000000000000;
          setEarn(vals?.toFixed(4));
        }
      } else {
        let vals =
          parseFloat(item?.AgentAmount) / 1000000 +
          parseFloat(item?.GodAgentAmount) / 1000000 +
          parseFloat(item?.MegaAgentAmount) / 1000000 +
          parseFloat(item?.SuperAgentAmount) / 1000000;
        let invest = parseFloat(item?.amountInvested) / 1000000;
        setEarnT(vals?.toFixed(2));
        setNr((invest - vals)?.toFixed(2));
        if (user?.role === "admin") {
          let vals =
            parseFloat(item?.AgentAmount) / 1000000 +
            parseFloat(item?.GodAgentAmount) / 1000000 +
            parseFloat(item?.MegaAgentAmount) / 1000000 +
            parseFloat(item?.SuperAgentAmount) / 1000000;
          setEarn(vals?.toFixed(4));
        } else if (user?.role === "agent") {
          let vals = parseFloat(item?.AgentAmount) / 1000000;
          setEarn(vals?.toFixed(4));
        } else if (user?.role === "god_agent") {
          let vals = parseFloat(item?.GodAgentAmount) / 1000000;
          setEarn(vals?.toFixed(4));
        } else if (user?.role === "mega_agent") {
          let vals = parseFloat(item?.MegaAgentAmount) / 1000000;
          setEarn(vals?.toFixed(4));
        } else if (user?.role === "super_agent") {
          let vals = parseFloat(item?.SuperAgentAmount) / 1000000;
          setEarn(vals?.toFixed(4));
        }
      }

      let dumArr = [item?.code_code];
      // console.log("code transaction side", dumArr);
      const val = localStorage.getItem("accessToken");
      // const url = `${Api_Url}/users/names?accessCodes[]=${dumArr.join(
      //   "&accessCodes[]="
      // )}`;
      // var config = {
      //   method: "get",
      //   url: url,
      //   headers: {
      //     Authorization: "Bearer " + val,
      //   },
      // };

      // axios(config)
      //   .then(function (response) {
      //     if (response?.data?.data?.length > 0) {
      //       let obj = response?.data?.data[0];
      //       setName(obj?.name);
      //       setRole(obj?.role);
      //       // console.log("rrrrrr", obj)
      //     }
      //   })
      //   .catch(function (error) { });
    }
  }, [user, item]);

  function convertToTitleCase(input) {
    if (input) {
      let words = input
        .split("_")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );

      if (words[0] === "mini" || words[0] === "Mini") {
        return "Ambassador";
      }
      if (
        words[words.length - 1].toLowerCase() === "agent" &&
        (words[0] != "mini" || words[0] != "Mini")
      ) {
        words[words.length - 1] = "Leader";
      }
      if (words[0] === "super" || words[0] === "Super") {
        return "Super";
      } else if (words[0] !== "agent") {
        return words.join(" ");
      } else {
        return "Leader";
      }
    } else {
      return "";
    }
  }

  return (
    <>
      <tr className="destopviewss" key={index}>
        <td>
          <div className="twice-text">
            <h6>{moment(item?.transactionTime).format("DD/MM/YYYY")}</h6>
            <p>{moment(item?.transactionTime).format("hh:mm A")} </p>
          </div>
        </td>
        {project == "MINER" ? (
          <>
            <td>
              <div className="twice-text">
                <h6>{item?.leader?.name}</h6>
                <p>{convertToTitleCase(item?.leader?.role)}</p>
              </div>
            </td>
            <td>
              <p className="codepara">
                {item?.leader?.accessCode && (
                  <>
                    <CopyToClipboard
                      text={item?.leader?.accessCode}
                      onCopy={(e) => textCopiedFun(index)}
                    >
                      <p>
                        {/* {item?.code_code?.slice(0, 3)}...
                {item?.code_code?.slice(
                  item?.code_code?.length - 3,
                  item?.code_code?.length
                )} */}
                        {/* {item?.code_code} */}
                        {item?.leader?.accessCode}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="19"
                          height="19"
                          viewBox="0 0 19 19"
                          fill="none"
                          style={{ cursor: "pointer" }}
                          className={copy === index && "copy-button "}
                        >
                          <path
                            d="M6.95508 7.3623L6.95508 5.26245C6.95508 4.42237 6.95508 4.00202 7.11857 3.68115C7.26238 3.39891 7.49168 3.1696 7.77393 3.02579C8.09479 2.8623 8.51515 2.8623 9.35522 2.8623L13.5552 2.8623C14.3953 2.8623 14.8151 2.8623 15.136 3.02579C15.4182 3.16961 15.6479 3.39891 15.7917 3.68115C15.9552 4.00202 15.9552 4.42206 15.9552 5.26214L15.9552 9.46216C15.9552 10.3022 15.9552 10.7223 15.7917 11.0431C15.6479 11.3254 15.418 11.5552 15.1358 11.699C14.8152 11.8623 14.3958 11.8623 13.5574 11.8623L11.4551 11.8623M6.95508 7.3623L4.85522 7.3623C4.01515 7.3623 3.59479 7.3623 3.27393 7.52579C2.99168 7.66961 2.76238 7.89891 2.61857 8.18115C2.45508 8.50202 2.45508 8.92237 2.45508 9.76245L2.45508 13.9625C2.45508 14.8025 2.45508 15.2224 2.61857 15.5432C2.76238 15.8255 2.99168 16.0552 3.27393 16.199C3.59448 16.3623 4.01432 16.3623 4.85276 16.3623L9.05774 16.3623C9.89618 16.3623 10.3154 16.3623 10.636 16.199C10.9182 16.0552 11.1479 15.8252 11.2917 15.543C11.4551 15.2224 11.4551 14.8031 11.4551 13.9646L11.4551 11.8623M6.95508 7.3623L9.05523 7.3623C9.8953 7.3623 10.3151 7.3623 10.636 7.52579C10.9182 7.66961 11.1479 7.89891 11.2917 8.18115C11.4551 8.50171 11.4551 8.92156 11.4551 9.76001L11.4551 11.8623"
                            stroke="#77868B"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </p>
                    </CopyToClipboard>
                    {copy === index && <p className="copy-text">Copied</p>}
                  </>
                )}
              </p>
            </td>
          </>
        ) : null}
        <td>
          <p className="codepara">
            {/* <CopyToClipboard
              text={item?.code_code}
              onCopy={(e) => textCopiedFun(index)}
            > */}
            <p>
              {/* {item?.code_code?.slice(0, 3)}...
                {item?.code_code?.slice(
                  item?.code_code?.length - 3,
                  item?.code_code?.length
                )} */}
              {/* {item?.code_code} */}
              {item?.location[0]?.recipientEmail}
              {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  style={{ cursor: "pointer" }}
                  className={copy === index && "copy-button "}
                >
                  <path
                    d="M6.95508 7.3623L6.95508 5.26245C6.95508 4.42237 6.95508 4.00202 7.11857 3.68115C7.26238 3.39891 7.49168 3.1696 7.77393 3.02579C8.09479 2.8623 8.51515 2.8623 9.35522 2.8623L13.5552 2.8623C14.3953 2.8623 14.8151 2.8623 15.136 3.02579C15.4182 3.16961 15.6479 3.39891 15.7917 3.68115C15.9552 4.00202 15.9552 4.42206 15.9552 5.26214L15.9552 9.46216C15.9552 10.3022 15.9552 10.7223 15.7917 11.0431C15.6479 11.3254 15.418 11.5552 15.1358 11.699C14.8152 11.8623 14.3958 11.8623 13.5574 11.8623L11.4551 11.8623M6.95508 7.3623L4.85522 7.3623C4.01515 7.3623 3.59479 7.3623 3.27393 7.52579C2.99168 7.66961 2.76238 7.89891 2.61857 8.18115C2.45508 8.50202 2.45508 8.92237 2.45508 9.76245L2.45508 13.9625C2.45508 14.8025 2.45508 15.2224 2.61857 15.5432C2.76238 15.8255 2.99168 16.0552 3.27393 16.199C3.59448 16.3623 4.01432 16.3623 4.85276 16.3623L9.05774 16.3623C9.89618 16.3623 10.3154 16.3623 10.636 16.199C10.9182 16.0552 11.1479 15.8252 11.2917 15.543C11.4551 15.2224 11.4551 14.8031 11.4551 13.9646L11.4551 11.8623M6.95508 7.3623L9.05523 7.3623C9.8953 7.3623 10.3151 7.3623 10.636 7.52579C10.9182 7.66961 11.1479 7.89891 11.2917 8.18115C11.4551 8.50171 11.4551 8.92156 11.4551 9.76001L11.4551 11.8623"
                    stroke="#77868B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg> */}
            </p>
            {/* </CopyToClipboard>
            {copy === index && <p className="copy-text">Copied</p>} */}
          </p>
        </td>

        <td>{item?.location[0]?.ipAddress}</td>
        <td>
          <CopyToClipboard
            text={item?.by}
            onCopy={(e) => textCopiedFun3(index)}
          >
            <p className="adadasdasdasdasdasdsad">
              {item?.by?.slice(0, 5)}...
              {item?.by?.slice(item?.by?.length - 5, item?.by?.length)}
              <svg
                style={{ cursor: "pointer" }}
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                className={copy3 === index && "copy-button "}
              >
                <path
                  d="M6.95508 7.3623L6.95508 5.26245C6.95508 4.42237 6.95508 4.00202 7.11857 3.68115C7.26238 3.39891 7.49168 3.1696 7.77393 3.02579C8.09479 2.8623 8.51515 2.8623 9.35522 2.8623L13.5552 2.8623C14.3953 2.8623 14.8151 2.8623 15.136 3.02579C15.4182 3.16961 15.6479 3.39891 15.7917 3.68115C15.9552 4.00202 15.9552 4.42206 15.9552 5.26214L15.9552 9.46216C15.9552 10.3022 15.9552 10.7223 15.7917 11.0431C15.6479 11.3254 15.418 11.5552 15.1358 11.699C14.8152 11.8623 14.3958 11.8623 13.5574 11.8623L11.4551 11.8623M6.95508 7.3623L4.85522 7.3623C4.01515 7.3623 3.59479 7.3623 3.27393 7.52579C2.99168 7.66961 2.76238 7.89891 2.61857 8.18115C2.45508 8.50202 2.45508 8.92237 2.45508 9.76245L2.45508 13.9625C2.45508 14.8025 2.45508 15.2224 2.61857 15.5432C2.76238 15.8255 2.99168 16.0552 3.27393 16.199C3.59448 16.3623 4.01432 16.3623 4.85276 16.3623L9.05774 16.3623C9.89618 16.3623 10.3154 16.3623 10.636 16.199C10.9182 16.0552 11.1479 15.8252 11.2917 15.543C11.4551 15.2224 11.4551 14.8031 11.4551 13.9646L11.4551 11.8623M6.95508 7.3623L9.05523 7.3623C9.8953 7.3623 10.3151 7.3623 10.636 7.52579C10.9182 7.66961 11.1479 7.89891 11.2917 8.18115C11.4551 8.50171 11.4551 8.92156 11.4551 9.76001L11.4551 11.8623"
                  stroke="#77868B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              {copy3 === index && <p className="copy-text">Copied</p>}
            </p>
          </CopyToClipboard>
        </td>
        <td>
          <p className="adadasdasdasdasdasdsad">
            {item?.transactionHash?.slice(0, 5)}...
            {item?.transactionHash?.slice(
              item?.transactionHash?.length - 10,
              item?.transactionHash?.length
            )}
            <a
              href={"https://etherscan.io/tx/" + item?.transactionHash}
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
              >
                <path
                  d="M6.21677 3.66561H5.16677C4.51337 3.66561 4.18643 3.66561 3.93687 3.79277C3.71735 3.90462 3.539 4.08297 3.42715 4.30249C3.29999 4.55205 3.29999 4.87899 3.29999 5.53239V9.96572C3.29999 10.6191 3.29999 10.9456 3.42715 11.1952C3.539 11.4147 3.71735 11.5934 3.93687 11.7052C4.18619 11.8323 4.51273 11.8323 5.16485 11.8323H9.60179C10.2539 11.8323 10.58 11.8323 10.8293 11.7052C11.0488 11.5934 11.2278 11.4146 11.3396 11.195C11.4667 10.9457 11.4667 10.6195 11.4667 9.96741V8.91561M12.05 5.99894V3.08228M12.05 3.08228H9.13332M12.05 3.08228L7.96665 7.16561"
                  stroke="#77868B"
                  stroke-width="1.2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
          </p>
        </td>
        {/* {item?.tokenSelected === "ETH" ? (
          <td>
            {parseFloat(parseFloat(item?.amountInvested))
              ?.toFixed(5)
              .toLocaleString("en-US")}{" "}
            {item?.tokenSelected}
          </td>
        ) : item?.tokenSelected === "WBTC" ? (
          <td>
            {parseFloat(parseFloat(item?.amountInvested))
              ?.toFixed(6)
              .toLocaleString("en-US")}{" "}
            {item?.tokenSelected}
          </td>
        ) : (
          <td>
            {parseFloat(
              parseFloat(item?.amountInvested)?.toFixed(2)
            ).toLocaleString("en-US")}{" "}
            {item?.tokenSelected}
          </td>
        )} */}
        {/* <td>{item?.price ? item?.price : 0}</td> */}

        {project == "NODE" && (
          <>
            <td>$99.99</td>
            <td>{item?.nftAmounts[0]}</td>
          </>
        )}
        <td>
          <p>${parseFloat(item?.totalSaleConverted)?.toFixed(0)}</p>
        </td>
        {/* <td>{formatTokenValue(item?.dopPurchased) + ' ' + item?.privateSale} </td> */}

        <td>
          {" "}
          {parseFloat(
            parseFloat(item?.amountInvested)?.toFixed(2)
          )?.toLocaleString()}{" "}
          {item?.tokenSelected}
        </td>

        {project == "MINER" ? (
          <>
            <td>{item?.isInsured ? "Yes" : "No"}</td>
            <td>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                {(
                  parseInt(item?.nftAmounts[0]) +
                  parseInt(item?.nftAmounts[1]) +
                  parseInt(item?.nftAmounts[2])
                )?.toLocaleString()}
                <div class="dropdown dropdownnodesminers">
                  <button
                    class="dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                    >
                      <path
                        d="M9 17.249C13.1421 17.249 16.5 13.8912 16.5 9.74902C16.5 5.60689 13.1421 2.24902 9 2.24902C4.85786 2.24902 1.5 5.60689 1.5 9.74902C1.5 13.8912 4.85786 17.249 9 17.249Z"
                        stroke="#77868B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9 12.749V9.74902"
                        stroke="#77868B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9 6.74902H9.00833"
                        stroke="#77868B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <div className="twice">
                      <p>Mini</p>
                      <h6>{item?.nftAmounts[0]}</h6>
                    </div>
                    <div className="twice">
                      <p>Standard</p>
                      <h6>{item?.nftAmounts[1]}</h6>
                    </div>
                    <div className="twice">
                      <p>Mega</p>
                      <h6>{item?.nftAmounts[2]}</h6>
                    </div>
                  </ul>
                </div>
              </div>
            </td>
          </>
        ) : null}
      </tr>
      <div className="new-accordionmobile d-none">
        <div className="upper-body">
          <div className="inner-text">
            <h6>Date and time</h6>
            <p> {moment(item?.transactionTime).format("DD/MM/YYYY")}</p>

            <p> {moment(item?.transactionTime).format("hh:mm A")}</p>
          </div>
          <div className="inner-text">
            <h6>Name</h6>

            <p>{item?.leader?.name}</p>
            <p>{convertToTitleCase(item?.leader?.role)}</p>
          </div>
          <div className="inner-text">
            <h6>Code</h6>
            {copy ? (
              <h6 className="adadasdasdasdasdasdsad">
                {item?.leader?.accessCode}
                <svg
                  style={{ cursor: "pointer" }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  className=""
                >
                  <path
                    d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                    stroke="#77868B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                    stroke="#77868B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </h6>
            ) : (
              <>
                {item?.leader?.accessCode && (
                  <CopyToClipboard
                    text={item?.leader?.accessCode}
                    onCopy={(e) => textCopiedFun(index)}
                  >
                    <h6 className="adadasdasdasdasdasdsad">
                      {item?.leader?.accessCode}
                      <svg
                        style={{ cursor: "pointer" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        className={copy === index && "copy-button "}
                      >
                        <path
                          d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                          stroke="#77868B"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                          stroke="#77868B"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      {copy === index && <p className="copy-text">Copied</p>}
                    </h6>
                  </CopyToClipboard>
                )}
              </>
            )}
          </div>
        </div>
        <div className="bottom-body">
          <Accordion>
            <Accordion.Item eventKey={index}>
              <Accordion.Header>
                More Info{" "}
                <img
                  src="\assets\arrow-down.svg"
                  alt="img"
                  className="img-fluid"
                />
              </Accordion.Header>
              <Accordion.Body>
                <div className="inner-textt">
                  <p>Customer email</p>
                  <h6>
                    {item?.location[0]?.recipientEmail}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="15"
                      viewBox="0 0 16 15"
                      fill="none"
                    >
                      <path
                        d="M10.3389 8.24875L10.3653 10.6986C10.3872 12.7402 9.57942 13.5656 7.53787 13.5876L5.08801 13.6139C3.04646 13.6359 2.22105 12.8281 2.19907 10.7865L2.17268 8.33668C2.1507 6.29513 2.95853 5.46972 5.00007 5.44774L7.44993 5.42136C9.49148 5.39937 10.3169 6.2072 10.3389 8.24875Z"
                        stroke="#77868B"
                        stroke-width="1.2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M13.801 4.71139L13.8274 7.16125C13.8493 9.2028 13.0415 10.0282 11 10.0502L10.3583 10.0571L10.3389 8.24888C10.3169 6.20733 9.49147 5.3995 7.44992 5.42149L5.64169 5.44096L5.63478 4.79933C5.6128 2.75778 6.42062 1.93237 8.46217 1.91038L10.912 1.884C12.9536 1.86202 13.779 2.66985 13.801 4.71139Z"
                        stroke="#77868B"
                        stroke-width="1.2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </h6>
                </div>
                <div className="inner-textt">
                  <p>IP</p>
                  <h6>{item?.location[0]?.ipAddress}</h6>
                </div>
                <div className="inner-textt">
                  <p>Wallet Address</p>
                  {copy3 ? (
                    <h6 className="adadasdasdasdasdasdsad">
                      {item?.by?.slice(0, 6)}...
                      {item?.by?.slice(
                        item?.by?.length - 5,
                        item?.by?.length
                      )}{" "}
                      <svg
                        style={{ cursor: "pointer" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        className=""
                      >
                        <path
                          d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                          stroke="#77868B"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                          stroke="#77868B"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </h6>
                  ) : (
                    <>
                      {item?.by && (
                        <CopyToClipboard
                          text={item?.by}
                          onCopy={(e) => textCopiedFun3(index)}
                        >
                          <h6 className="adadasdasdasdasdasdsad">
                            {item?.by?.slice(0, 6)}...
                            {item?.by?.slice(
                              item?.by?.length - 5,
                              item?.by?.length
                            )}{" "}
                            <svg
                              style={{ cursor: "pointer" }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              className={copy3 === index && "copy-button "}
                            >
                              <path
                                d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                                stroke="#77868B"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                                stroke="#77868B"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            {copy3 === index && (
                              <p className="copy-text">Copied</p>
                            )}
                          </h6>
                        </CopyToClipboard>
                      )}
                    </>
                  )}
                </div>
                <div className="inner-textt">
                  <p>Hash</p>
                  <h6>
                    {" "}
                    <a
                      href={"https://etherscan.io/tx/" + item?.transactionHash}
                      target="_blank"
                    >
                      Etherscan{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                      >
                        <path
                          d="M6.21677 3.66561H5.16677C4.51337 3.66561 4.18643 3.66561 3.93687 3.79277C3.71735 3.90462 3.539 4.08297 3.42715 4.30249C3.29999 4.55205 3.29999 4.87899 3.29999 5.53239V9.96572C3.29999 10.6191 3.29999 10.9456 3.42715 11.1952C3.539 11.4147 3.71735 11.5934 3.93687 11.7052C4.18619 11.8323 4.51273 11.8323 5.16485 11.8323H9.60179C10.2539 11.8323 10.58 11.8323 10.8293 11.7052C11.0488 11.5934 11.2278 11.4146 11.3396 11.195C11.4667 10.9457 11.4667 10.6195 11.4667 9.96741V8.91561M12.05 5.99894V3.08228M12.05 3.08228H9.13332M12.05 3.08228L7.96665 7.16561"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </a>
                  </h6>
                </div>

                {project == "NODE" && (
                  <>
                    <div className="inner-textt">
                      <p>Price</p>
                      <h6>$99.99</h6>
                    </div>

                    <div className="inner-textt">
                      <p>Amount</p>
                      <h6>{item?.nftAmounts[0]}</h6>
                    </div>
                  </>
                )}
                <div className="inner-textt">
                  <p>Paid</p>

                  <h6>${parseFloat(item?.totalSaleConverted)?.toFixed(0)}</h6>
                </div>

                <div className="inner-textt">
                  <p>Payment</p>
                  <h6>
                    {parseFloat(
                      parseFloat(item?.amountInvested)?.toFixed(2)
                    )?.toLocaleString()}{" "}
                    {item?.tokenSelected}
                  </h6>
                </div>
                {project == "MINER" ? (
                  <>
                    <div className="inner-textt">
                      <p>Gems Protect</p>

                      <h6>{item?.isInsured ? "Yes" : "No"}</h6>
                    </div>
                    <div className="inner-textt">
                      <p>Type/Amount</p>
                      <div class="dropdown dropdownnodesminers">
                        <button
                          class="dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="19"
                            viewBox="0 0 18 19"
                            fill="none"
                          >
                            <path
                              d="M9 17.249C13.1421 17.249 16.5 13.8912 16.5 9.74902C16.5 5.60689 13.1421 2.24902 9 2.24902C4.85786 2.24902 1.5 5.60689 1.5 9.74902C1.5 13.8912 4.85786 17.249 9 17.249Z"
                              stroke="#77868B"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M9 12.749V9.74902"
                              stroke="#77868B"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M9 6.74902H9.00833"
                              stroke="#77868B"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </button>
                        <ul
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <div className="twice">
                            <p>Mini</p>
                            <h6>{item?.nftAmounts[0]}</h6>
                          </div>
                          <div className="twice">
                            <p>Standard</p>
                            <h6>{item?.nftAmounts[1]}</h6>
                          </div>
                          <div className="twice">
                            <p>Mega</p>
                            <h6>{item?.nftAmounts[2]}</h6>
                          </div>
                        </ul>
                      </div>
                    </div>
                  </>
                ) : null}

                {/* <div className="inner-textt">
                  <p>Leader Earnings</p>

                  <h6>
                    $
                    {item?.myEarningConverted !== undefined
                      ? formatTokenValue(item.myEarningConverted)
                      : 0}
                  </h6>

                  {user?.role == "admin" ? (
                    <div className="twice-text" style={{ textAlign: "right" }}>
                      <>
                        {item?.codeRole == "agent" ? (
                          <h6>
                            {parseFloat(item?.AgentAmount).toFixed(4)}{" "}
                            {item?.tokenSelected}
                          </h6>
                        ) : item?.codeRole == "god_agent" ? (
                          <h6>
                            {parseFloat(item?.GodAgentAmount).toFixed(4)}{" "}
                            {item?.tokenSelected}
                          </h6>
                        ) : item?.codeRole == "mega_agent" ? (
                          <h6>
                            {parseFloat(item?.MegaAgentAmount).toFixed(4)}{" "}
                            {item?.tokenSelected}
                          </h6>
                        ) : item?.codeRole == "super_agent" ? (
                          <h6>
                            {parseFloat(item?.SuperAgentAmount).toFixed(4)}{" "}
                            {item?.tokenSelected}
                          </h6>
                        ) : item?.codeRole == "mini_agent" ? (
                          <h6>
                            {parseFloat(item?.MiniAgentAmount).toFixed(4)}{" "}
                            {item?.tokenSelected}
                          </h6>
                        ) : (
                          ""
                        )}
                      </>

                      {item?.tokenSelected === "ETH" ? (
                        <p>
                          {item?.codeRole == "agent" ? (
                            <h6>
                              (
                              {(
                                parseFloat(item?.AgentAmount) * usdPrice
                              )?.toFixed(2)}{" "}
                              USD)
                            </h6>
                          ) : item?.codeRole == "god_agent" ? (
                            <h6>
                              (
                              {(
                                parseFloat(item?.GodAgentAmount) * usdPrice
                              )?.toFixed(2)}{" "}
                              USD)
                            </h6>
                          ) : item?.codeRole == "mega_agent" ? (
                            <h6>
                              (
                              {(
                                parseFloat(item?.MegaAgentAmount) * usdPrice
                              )?.toFixed(2)}{" "}
                              USD)
                            </h6>
                          ) : item?.codeRole == "super_agent" ? (
                            <h6>
                              (
                              {(
                                parseFloat(item?.SuperAgentAmount) * usdPrice
                              )?.toFixed(2)}{" "}
                              USD)
                            </h6>
                          ) : item?.codeRole == "mini_agent" ? (
                            <h6>
                              (
                              {(
                                parseFloat(item?.MiniAgentAmount) * usdPrice
                              )?.toFixed(2)}{" "}
                              USD)
                            </h6>
                          ) : (
                            ""
                          )}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <div className="twice-text" style={{ textAlign: "right" }}>
                      <>
                        {user?.role == "agent" ? (
                          <h6>
                            {parseFloat(item?.AgentAmount).toFixed(4)}{" "}
                            {item?.tokenSelected}
                          </h6>
                        ) : user?.role == "god_agent" ? (
                          <h6>
                            {parseFloat(item?.GodAgentAmount).toFixed(4)}{" "}
                            {item?.tokenSelected}
                          </h6>
                        ) : user?.role == "mega_agent" ? (
                          <h6>
                            {parseFloat(item?.MegaAgentAmount).toFixed(4)}{" "}
                            {item?.tokenSelected}
                          </h6>
                        ) : user?.role == "super_agent" ? (
                          <h6>
                            {parseFloat(item?.SuperAgentAmount).toFixed(4)}{" "}
                            {item?.tokenSelected}
                          </h6>
                        ) : user?.role == "mini_agent" ? (
                          <h6>
                            {parseFloat(item?.MiniAgentAmount).toFixed(4)}{" "}
                            {item?.tokenSelected}
                          </h6>
                        ) : (
                          ""
                        )}
                      </>

                      {item?.tokenSelected === "ETH" ? (
                        <p>
                          {user?.role == "agent" ? (
                            <h6>
                              (
                              {(
                                parseFloat(item?.AgentAmount) * usdPrice
                              )?.toFixed(2)}{" "}
                              USD)
                            </h6>
                          ) : user?.role == "god_agent" ? (
                            <h6>
                              (
                              {(
                                parseFloat(item?.GodAgentAmount) * usdPrice
                              )?.toFixed(2)}{" "}
                              USD)
                            </h6>
                          ) : user?.role == "mega_agent" ? (
                            <h6>
                              (
                              {(
                                parseFloat(item?.MegaAgentAmount) * usdPrice
                              )?.toFixed(2)}{" "}
                              USD)
                            </h6>
                          ) : user?.role == "super_agent" ? (
                            <h6>
                              (
                              {(
                                parseFloat(item?.SuperAgentAmount) * usdPrice
                              )?.toFixed(2)}{" "}
                              USD)
                            </h6>
                          ) : user?.role == "mini_agent" ? (
                            <h6>
                              (
                              {(
                                parseFloat(item?.MiniAgentAmount) * usdPrice
                              )?.toFixed(2)}{" "}
                              USD)
                            </h6>
                          ) : (
                            ""
                          )}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </div> */}
                {/* {user?.role == "admin" ? (
                  <div className="inner-textt">
                    <p>Net Revenue</p>
                    <div className="twice-text" style={{ textAlign: "right" }}>
                      {item?.tokenSelected === "ETH" ? (
                        <h6>
                          {(item?.amountInvested - item?.myEarningsEth).toFixed(
                            6
                          )}{" "}
                          {item?.tokenSelected}
                        </h6>
                      ) : //  item?.tokenSelected === "TOMI" ? (
                      //   <h6>
                      //     {(
                      //       item?.amountInvested - item?.myEarningsTomi
                      //     ).toFixed(4)}{" "}
                      //     {item?.tokenSelected}
                      //   </h6>
                      // ) :

                      item?.tokenSelected === "WBTC" ? (
                        <h6>
                          {(
                            item?.amountInvested - item?.myEarningsWbtc
                          ).toFixed(6)}{" "}
                          {item?.tokenSelected}
                        </h6>
                      ) : item?.tokenSelected === "USDC" ? (
                        <h6>
                          {(
                            item?.amountInvested - item?.myEarningsUsdc
                          ).toFixed(4)}{" "}
                          {item?.tokenSelected}
                        </h6>
                      ) : (
                        <h6>
                          {parseFloat(
                            item?.amountInvested - item?.myEarningsUsdt
                          )?.toLocaleString("en-US")}{" "}
                          {item?.tokenSelected}
                        </h6>
                      )}
                      {item?.tokenSelected === "ETH" ? (
                        <p>
                          (
                          {(
                            parseFloat(
                              item?.amountInvested - item?.myEarningsEth
                            ) * usdPrice
                          )?.toFixed(4)}{" "}
                          USD)
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )} */}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
      {/* <Accordion.Item className="mobileviewnew" eventKey={index}>
        <Accordion.Header>
          <div className="accheadermain">
            <p className="acctext">
              {moment(item?.blockTimestamp * 1000)
                .utc()
                .format("DD/MM/YYYY")}
            </p>
            <a
              href={"https://etherscan.io/tx/" + item?.transactionHash}
              target="_blank"
            >
              <p className="acctext">
                <img
                  src="\assets\link-icon.svg"
                  alt="img"
                  className="img-fluid"
                />{" "}
              </p>
            </a>
            {user?.role === role ? (
              <p className="acctext">Direct</p>
            ) : (
              <p className="acctext">{convertToTitleCase(role)}</p>
            )}
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <div className="acctexts">
            <h6 className="textleft">Leader</h6>
            <p className="textright">{name}</p>
          </div>
          <div className="acctexts">
            <h6 className="textleft">Wallet Address</h6>
            <p className="textright">
              {item?.by?.slice(0, 6)}...
              {item?.by?.slice(item?.by?.length - 3, item?.by?.length)}{" "}
              <img src="\assets\copy.svg" alt="img" className="img-fluid" />
            </p>
          </div>
          <div className="acctexts">
            <h6 className="textleft">Amount Spent</h6>
            {item?.tokenSelected === "ETH" ? (
              <p className="textright">
                {parseFloat(parseFloat(item?.amountInvested) / 1000000000000000000)
                  ?.toFixed(4)
                  .toLocaleString("en-US")}{" "}
                {item?.tokenSelected}
              </p>
            ) : (
              <p className="textright">
                {parseFloat(
                  (parseFloat(item?.amountInvested) / 1000000)?.toFixed(2)
                ).toLocaleString("en-US")}{" "}
                {item?.tokenSelected}
              </p>
            )}
          </div>
          <div className="acctexts">
            <h6 className="textleft">DOP Purchased</h6>
            {item?.tokenSelected === "ETH" ? (
              <p className="textright">
                {parseFloat(
                  (usdPrice *
                    (parseFloat(item?.amountInvested) / 1000000000000000000)) /
                  (parseFloat(item?.price) / 1000000000000000000)
                )?.toLocaleString("en-US")}{" "}
                DOP
              </p>
            ) : (
              <p className="textright">
                {parseFloat(
                  (
                    parseFloat(item?.amountInvested) /
                    1000000 /
                    (parseFloat(item?.price) / 1000000000000000000)
                  )?.toFixed(2)
                )?.toLocaleString("en-US")}{" "}
                DOP
              </p>
            )}
          </div>
          <div className="acctexts">
            <h6 className="textleft">My Earnings</h6>
            <div className="twice-text">
              <h6>${earn}</h6>
              {item?.tokenSelected === "ETH" ? (
                <p>({earn * usdPrice} USD)</p>
              ) : (
                ""
              )}
            </div>
          </div>
          {user?.role == "admin" ? (
            <div className="acctexts">
              <h6 className="textleft">Net Revenue</h6>
              <div className="twice-text">
                <h6>
                  {parseFloat(nr)?.toLocaleString("en-US")}{" "}
                  {item?.tokenSelected}
                </h6>
                {item?.tokenSelected === "ETH" ? (
                  <p>({(parseFloat(nr) * usdPrice)?.toFixed(4)} USD)</p>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </Accordion.Body>
      </Accordion.Item> */}
    </>
  );
};

export default RowListing;
