import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { getRainPreSaleV2Contract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const CuurentRoundRainV2 = () => {
  const [balance, setBalance] = useState(null);
  const { account } = useWeb3React();
  const web3 = useWeb3();

  const tokenAddressRainV2 = Environment.RainPreSaleV2;
  const RainContractPresaleV2 = getRainPreSaleV2Contract(
    tokenAddressRainV2,
    web3
  );

  // const project = localStorage.getItem("project");

  const cuurentRoundRainV2 = useCallback(
    async (proj) => {
      if (proj == "RAIN") {
        try {
          const buy = await RainContractPresaleV2.methods
            .getRoundCount()
            .call();

          return buy;
        } catch (error) {
          console.log("dlsflasdfjlasdf", error);
          throw error;
        }
      }
    },
    [, RainContractPresaleV2, account, web3]
  );
  return { cuurentRoundRainV2: cuurentRoundRainV2 };
};
export default CuurentRoundRainV2;
