import React, { useState, useEffect } from "react";
import "./allagents.scss";
import axios from "axios";
import DataTable from "./DataTable";
import DataTableLeaders from "./DataTableLeaders/DataTableLeaders";
import DataTableSpecialAccess from "./DataTableSpecialAccess/DataTableSpecialAccess";
import { useHistory } from "react-router-dom";

import ReactPaginate from "react-paginate";
import moment from "moment";
import { Api_Url } from "../../../utils/ApiUrl";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Modal, Dropdown } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import Specialaccess from "./Specialaccess";

const ViewDetail = ({
  setRoutes,
  user,
  userDel,
  setUserDel,
  setShow,
  setBlockUnblock,
  blockUnblock,
  tomiPrice,
  usdPrice,
  btcPrice,
  pepePrice,
  linkPrice,
  uniPrice,
  dopPrice,
  gemsPrice,
  privateSaleFilter,
}) => {
  const history = useHistory();

  console.log("user del data: ", userDel);

  const [loader, setLoader] = useState(false);

  const [transactions, setTransactions] = useState([]);
  const [specialAccessData, setSpecialAccessData] = useState([]);

  const [purchases, setPurchases] = useState(null);
  const [leaderscount, setleaderscount] = useState(null);
  const [minerDiscount, setMinerDiscount] = useState("");

  const [accessCodeToggle, setAccessCodeToggle] = useState(true);

  const [dops, setDops] = useState(null);
  const [search, setSearch] = useState("");
  let tok = localStorage.getItem("accessToken");
  let project = localStorage.getItem("project");

  // const [usdPrice, setUsdPrice] = useState(0);
  const [page, setPage] = useState(0);
  const [pageCountAgent, setPageCountAgent] = useState(0);

  const [earnings, setEarnings] = useState(null);

  const [agents, setAgents] = useState([]);

  const [offset, setOffset] = useState(1);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(1);
  const [pages, setPages] = useState([]);
  const [code, setCode] = useState([]);
  const [page1, setPage1] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [leaderNames, setLeaderNames] = useState([]);
  const [leaderFilter, setLeaderFilter] = useState("");
  const [roleTypeFilter, setRoleTypeFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryNames, setSearchQueryNames] = useState("");

  const [orderField, setOrderField] = useState("transactionTime");
  const [orderDirection, setOrderDirection] = useState("-1");

  const [orderFieldAgents, setOrderFieldAgents] = useState("createdAt");
  const [orderDirectionAgents, setOrderDirectionAgents] = useState("-1");
  const [tabKey, setTabKey] = useState("transactions");

  const [specialAccess, setSpecialAccess] = useState([]);
  const [addSpecialAccess, setAddSpecialAccess] = useState([]);

  const [removeSpecialAccess, setRemoveSpecialAccess] = useState([]);

  const [godLeadersList, setGodLeadersList] = useState([]);
  const [minimumBuy, setMinimumBuy] = useState("");

  const [newWalletAddress, setNewWalletAddress] = useState("");

  const [show1, setShow1] = useState(false);
  const [showMinerDiscount, setShowMinerDiscount] = useState(false);

  const handleClose1 = () => setShow1(false);

  const [show123, setShow123] = useState(false);
  const handleClose123 = () => setShow123(false);

  const [show11, setShow11] = useState(false);
  const [show12, setShow12] = useState(false);

  const handleShowMinerDiscount = () => setShowMinerDiscount(true);

  const handleCloseMinerDiscount = () => setShowMinerDiscount(false);

  const handleClose11 = () => {
    setShow11(false);
  };

  const handleClose12 = () => {
    setShow12(false);
  };

  useEffect(() => {
    // getLeaderNames();
  }, []);

  // useEffect(() => {
  //   if (userDel) {
  //     getDashboardData();
  //   }
  // }, [userDel]);

  const changeMinerDiscount = () => {
    const val = localStorage.getItem("accessToken");

    var config = {
      method: "patch",
      url: `${Api_Url}/users/${userDel?.userRef}/miner-discount`,

      headers: {
        Authorization: "Bearer " + val,
      },
      data: {
        allowedMinerDiscount: minerDiscount,
      },
    };
    axios(config)
      .then(function (response) {
        getData();
        handleCloseMinerDiscount();
      })
      .catch(function (error) {
        // toast.error(error?.response?.data?.message);
        if (error?.response?.status == 401) {
          // localStorage.removeItem("accessToken");
          // history.push("/");
        }
      });
  };

  const changeMinimumBuy = () => {
    const val = localStorage.getItem("accessToken");

    var config = {
      method: "patch",
      url: `${Api_Url}/users/${userDel?._id}/buying-limit`,

      headers: {
        Authorization: "Bearer " + val,
      },
      data: {
        leaderRole: userDel?.role,
        minimumBuy: minimumBuy,
      },
    };
    axios(config)
      .then(function (response) {
        getData();
        handleClose123();
      })
      .catch(function (error) {
        // toast.error(error?.response?.data?.message);
        if (error?.response?.status == 401) {
          // localStorage.removeItem("accessToken");
          // history.push("/");
        }
      });
  };

  const specialAccessList = () => {
    const val = localStorage.getItem("accessToken");

    var config = {
      method: "get",
      url: `${Api_Url}/users/god-leaders?limit=1000`,

      headers: {
        Authorization: "Bearer " + val,
      },
    };
    axios(config)
      .then(function (response) {
        setGodLeadersList(response?.data?.data);
      })
      .catch(function (error) {
        // toast.error(error?.response?.data?.message);
        if (error?.response?.status == 401) {
          // localStorage.removeItem("accessToken");
          // history.push("/");
        }
      });
  };

  const editSpecialAccessList = () => {
    setLoader(true);
    const val = localStorage.getItem("accessToken");

    var config = {
      method: "patch",
      url: `${Api_Url}/users/${userDel?._id}/god-leader`,
      data: {
        role: userDel?.role,
        addSpecialAccess: addSpecialAccess,
        removeSpecialAccess: removeSpecialAccess,
      },
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    axios(config)
      .then(function (response) {
        getData();
        handleClose1();
        setAddSpecialAccess([]);
        setRemoveSpecialAccess([]);
        setLoader(false);
      })
      .catch(function (error) {
        // toast.error(error?.response?.data?.message);
        setLoader(false);
        if (error?.response?.status == 401) {
          // localStorage.removeItem("accessToken");
          // history.push("/");
        }
      });
  };

  const getDashboardData = () => {
    var config = {
      method: "get",
      url: `${Api_Url}/users/${userDel?._id}/admin/leaders?limit=15&offset=1&&role=${userDel?.role}`,

      headers: {
        Authorization: "Bearer " + tok,
      },
    };

    let dynamicQuery = config.url;

    if (search) {
      dynamicQuery += `&by=${search}`;
    }

    config.url = dynamicQuery;
    axios(config)
      .then(function (res) {
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
      });
  };

  const toggleAccessCode = async () => {
    var config = {
      method: "patch",
      url: `${Api_Url}/users/${userDel?._id}/toggle-access-code`,

      headers: {
        Authorization: "Bearer " + tok,
      },
    };

    await axios(config)
      .then(function (res) {
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
      });
  };

  useEffect(() => {
    if (user && tabKey == "leaders") {
      // GetAgents();
    } else if (user && tabKey == "special") {
      getSpecialAccessData();
    }
  }, [
    user,
    searchQuery,
    leaderFilter,
    roleTypeFilter,
    orderDirectionAgents,
    orderFieldAgents,
    tabKey,
    page,
  ]);

  const handlePageChange = (e) => {
    const selectedPage = e.selected;

    setPage1(selectedPage);
  };

  const getTransactionsData = () => {
    var config = {
      method: "get",
      url: `${Api_Url}/nodeminer/${userDel?.userRef}/transactions?offset=${
        page1 + 1
      }&limit=10`,

      headers: {
        Authorization: "Bearer " + tok,
      },
    };
    let dynamicQuery = config.url;

    if (search) {
      dynamicQuery += `&search=${encodeURIComponent(search)}`;
    }
    config.url = dynamicQuery;

    axios(config)
      .then(function (res) {
        // console.log("trx data: ", res?.data?.data?.transactions);
        setTransactions(res?.data?.data?.transactions);
        setPageCount(res?.data?.data?.pages);
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
      });
  };

  const getLeaderNames = () => {
    const val = localStorage.getItem("accessToken");

    var config = {
      method: "get",
      url: `${Api_Url}/users/${userDel?._id}/leaders-names?limit=${500}&role=${
        userDel?.role
      }`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    let dynamicQuery = config.url;

    if (searchQueryNames) {
      dynamicQuery += `&name=${encodeURIComponent(searchQueryNames)}`;
    }
    axios(config)
      .then(function (response) {
        setLeaderNames(response?.data?.data);
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          // localStorage.removeItem("accessToken");
          // history.push("/");
        }
      });
  };

  const setSearchQuerying = (e) => {
    setSearchQuery(e);
    if (e === "") {
      GetAgents();
    }
  };

  const handleAgentsPageChange = (e) => {
    const selectedPage = e.selected;

    setPage(selectedPage);
  };

  const GetAgents = () => {
    // off?.preventDefault();
    const val = localStorage.getItem("accessToken");
    let valu = null;

    var config = {
      method: "post",
      url: `${Api_Url}/users/admin/single/leaders`,
      data: {
        limit: 10,
        offset: page + 1,
        ...(roleTypeFilter && { leaderType: roleTypeFilter }),
        id: userDel?._id,

        ...(privateSaleFilter?.length > 0 && {
          privateSaleFilter: privateSaleFilter,
        }),

        ...(searchQuery && { search: searchQuery }),

        orderField: orderFieldAgents,
        orderDirection: orderDirectionAgents,
      },
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    axios(config)
      .then(function (response) {
        const resData = response?.data?.data;
        setCount(response.data.data.count);
        setAgents(resData?.agents);
        let arr = Array.from(Array(parseInt(response.data.data.pages)).keys());
        setPageCountAgent(response?.data?.data?.pages);

        setPages(arr);
        // setSearch('')

        setLoader(false);
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          // localStorage.removeItem("accessToken");
          // history.push("/");
        }
        setLoader(false);
      });
  };

  const getSpecialAccessData = () => {
    var config = {
      method: "get",
      url: `${Api_Url}/users/${userDel?._id}/special-accesses?limit=10&offset=${
        page1 + 1
      }&role=${userDel?.role}`,

      headers: {
        Authorization: "Bearer " + tok,
      },
    };

    let dynamicQuery = config.url;

    // if (search) {
    //   dynamicQuery += `&by=${search}`;
    // }
    // if (orderField) {
    //   dynamicQuery += `&orderField=${orderField}&orderDirection=${orderDirection}`;
    // }

    // if (roleTypeFilter) {
    //   dynamicQuery += `&leaderType=${encodeURIComponent(roleTypeFilter)}`;
    // }
    config.url = dynamicQuery;
    axios(config)
      .then(function (res) {
        // console.log("trx data: ", res?.data?.data?.transactions);
        setSpecialAccessData(res?.data?.data?.specialAccesses);
        setPageCount(res?.data?.data?.pages);
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
      });
  };

  const getData = async () => {
    var config = {
      method: "get",
      url: `${Api_Url}/users/${userDel?.userRef}/miner-leader`,
      // data: {
      //   id: userDel?.userRef,
      //   ...(privateSaleFilter?.length > 0 && {
      //     privateSaleFilter: privateSaleFilter,
      //   }),
      // },
      headers: {
        authorization: `Bearer ` + tok,
      },
    };
    axios(config)
      .then(function (response) {
        // console.log("user coount eth", response);
        const resData = response?.data?.data[0];
        setleaderscount(resData);
        setMinimumBuy(resData?.minimumBuy);
        setAccessCodeToggle(resData?.accessCodeEnabled);
        setSpecialAccess(resData?.specialAccess);
      })
      .catch(function (error) {});
  };

  useEffect(() => {
    if (userDel) {
      getData();
    }
  }, [userDel]);

  function convertToTitleCase(input) {
    if (input) {
      let words = input
        .split("_")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );
      if (words[0] === "mini" || words[0] === "Mini") {
        return "Ambassador";
      }
      if (
        words[words.length - 1].toLowerCase() === "agent" &&
        (words[0] != "mini" || words[0] != "Mini")
      ) {
        words[words.length - 1] = "Leader";
      }
      if (words[0] === "super" || words[0] === "Super") {
        return "Super";
      } else if (words[0] !== "agent") {
        return words.join(" ");
      } else {
        return "Leader";
      }
    } else {
      return "";
    }
  }

  useEffect(() => {
    if (usdPrice && tabKey == "transactions") {
      getTransactionsData();
    }
  }, [
    usdPrice,
    userDel,
    search,
    orderDirection,
    orderField,
    tabKey,
    page1,
    roleTypeFilter,
  ]);

  // console.log("abbbbbbbbbbbbbbbbbbbbbbb", userDel?.role)
  const [showchange, setShowchange] = useState(false);

  const handleClosechange = () => setShowchange(false);
  const handleShowchange = () => setShowchange(true);

  const [showconfirmation, setShowconfirmation] = useState(false);

  const handleCloseconfirmation = () => setShowconfirmation(false);
  const handleShowconfirmation = () => setShowconfirmation(true);

  const [showchangesuccess, setShowchangesuccess] = useState(false);

  const handleClosechangesuccess = () => setShowchangesuccess(false);
  const handleShowchangesuccess = () => setShowchangesuccess(true);

  const tokenData = [
    { key: "totalEth", name: "ETH", img: "/assets/eth.svg", decimals: 5 },
    { key: "totalUsdt", name: "USDT", img: "/assets/usdt.svg", decimals: 2 },
    { key: "totalUsdc", name: "USDC", img: "/assets/usdc.svg", decimals: 2 },
    { key: "totalGems", name: "GEMS", img: "/assets/gems.svg", decimals: 1 },
    { key: "totalWbtc", name: "WBTC", img: "/assets/wbtc.svg", decimals: 6 },
    { key: "totalLink", name: "LINK", img: "/assets/link.svg", decimals: 2 },
    { key: "totalTomi", name: "TOMI", img: "/assets/tomi.svg", decimals: 2 },
    { key: "totalPepe", name: "PEPE", img: "/assets/pepe.svg", decimals: 2 },
    { key: "totalUni", name: "UNI", img: "/assets/uni.svg", decimals: 2 },
    { key: "totalDopToken", name: "DOP", img: "/assets/dop.svg", decimals: 2 },
  ];

  const tokenDataEarnings = [
    { key: "myEarningsEth", name: "ETH", img: "/assets/eth.svg", decimals: 5 },
    {
      key: "myEarningsUsdt",
      name: "USDT",
      img: "/assets/usdt.svg",
      decimals: 2,
    },
    {
      key: "myEarningsUsdc",
      name: "USDC",
      img: "/assets/usdc.svg",
      decimals: 2,
    },
    {
      key: "myEarningsGems",
      name: "GEMS",
      img: "/assets/gems.svg",
      decimals: 1,
    },
    {
      key: "myEarningsWbtc",
      name: "WBTC",
      img: "/assets/wbtc.svg",
      decimals: 6,
    },
    {
      key: "myEarningsLink",
      name: "LINK",
      img: "/assets/link.svg",
      decimals: 2,
    },
    {
      key: "myEarningsTomi",
      name: "TOMI",
      img: "/assets/tomi.svg",
      decimals: 2,
    },
    {
      key: "myEarningsPepe",
      name: "PEPE",
      img: "/assets/pepe.svg",
      decimals: 2,
    },
    { key: "myEarningsUni", name: "UNI", img: "/assets/uni.svg", decimals: 2 },
    {
      key: "myEarningsDopToken",
      name: "DOP",
      img: "/assets/dop.svg",
      decimals: 2,
    },
  ];

  function formatTokenValue(tokenValue, decimals) {
    const num = parseFloat(tokenValue);
    if (isNaN(num) || num === 0) return null; // Hide zero values

    if (num >= 1000000) {
      return (num / 1000000)?.toFixed(2) + "M";
    } else if (num >= 1000) {
      return (num / 1000)?.toFixed(2) + "K";
    } else {
      return num?.toFixed(decimals); // Apply specific decimal formatting
    }
  }
  return (
    <>
      <section className="viewdetail viewdetailnodesminers">
        <div className="custom-container veiwdetaileinnerpage">
          <div className="main-heading">
            <button
              onClick={() => {
                setRoutes(false);
                setUserDel(null);
              }}
              className="btn-back"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
              >
                <path
                  d="M7.88916 1.93555L5.38776 1.93555L1.99471 7.99682L4.49612 7.99682L7.88916 1.93555Z"
                  fill="#FFF"
                />
                <path
                  d="M7.88916 14.0703L5.38776 14.0703L1.99471 8.00904L3.5712 6.96117L4.49612 8.00904L7.88916 14.0703Z"
                  fill="#FFF"
                />
                <path
                  d="M8.01642 6.85742H14.9615L13.6868 9.14313H6.7417L8.01642 6.85742Z"
                  fill="#FFF"
                />
              </svg>
              Back
            </button>
            <div className="twice-btn">
              {user?.role == "admin" ? (
                <>
                  <div className="set-custom-claim-text">
                    <div className="parent-toggle-switch-custom">
                      <div class="custom-toggle">
                        <input
                          type="checkbox"
                          class="checkbox"
                          id="checkbox"
                          checked={!accessCodeToggle}
                          onChange={(e) => {
                            toggleAccessCode();
                            setAccessCodeToggle(!accessCodeToggle);
                          }}
                        />
                        <label class="switch" for="checkbox">
                          <span class="slider"></span>
                        </label>
                      </div>
                    </div>
                    <h6>Disable this Code</h6>
                  </div>
                  {leaderscount?.role == "god_agent" ? (
                    <button
                      onClick={() => {
                        specialAccessList();
                        setShow1(true);
                      }}
                      className="btn-edit"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                      >
                        <path
                          d="M17.5 18.8333H2.5C2.15833 18.8333 1.875 18.55 1.875 18.2083C1.875 17.8666 2.15833 17.5833 2.5 17.5833H17.5C17.8417 17.5833 18.125 17.8666 18.125 18.2083C18.125 18.55 17.8417 18.8333 17.5 18.8333Z"
                          fill="black"
                        />
                        <path
                          d="M15.85 3.39999C14.2333 1.78332 12.65 1.74166 10.9917 3.39999L9.98333 4.40832C9.9 4.49166 9.86666 4.62499 9.9 4.74166C10.5333 6.94999 12.3 8.71666 14.5083 9.34999C14.5417 9.35832 14.575 9.36666 14.6083 9.36666C14.7 9.36666 14.7833 9.33332 14.85 9.26666L15.85 8.25832C16.675 7.44166 17.075 6.64999 17.075 5.84999C17.0833 5.02499 16.6833 4.22499 15.85 3.39999Z"
                          fill="black"
                        />
                        <path
                          d="M13.0083 10.1084C12.7667 9.99169 12.5333 9.87502 12.3083 9.74169C12.125 9.63335 11.95 9.51669 11.775 9.39169C11.6333 9.30002 11.4667 9.16669 11.3083 9.03335C11.2917 9.02502 11.2333 8.97502 11.1667 8.90835C10.8917 8.67502 10.5833 8.37502 10.3083 8.04169C10.2833 8.02502 10.2417 7.96669 10.1833 7.89169C10.1 7.79169 9.95833 7.62502 9.83333 7.43335C9.73333 7.30835 9.61666 7.12502 9.50833 6.94169C9.375 6.71669 9.25833 6.49169 9.14166 6.25835C8.9887 5.93057 8.55849 5.8332 8.30271 6.08897L3.61666 10.775C3.50833 10.8834 3.40833 11.0917 3.38333 11.2334L2.93333 14.425C2.85 14.9917 3.00833 15.525 3.35833 15.8834C3.65833 16.175 4.075 16.3334 4.525 16.3334C4.625 16.3334 4.725 16.325 4.825 16.3084L8.025 15.8584C8.175 15.8334 8.38333 15.7334 8.48333 15.625L13.1771 10.9312C13.4278 10.6806 13.3336 10.2493 13.0083 10.1084Z"
                          fill="black"
                        />
                      </svg>
                      Edit Special Access
                    </button>
                  ) : null}
                  <button
                    onClick={() => setShow(true)}
                    className={blockUnblock ? "btn-del btnunblock" : "btn-del"}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                    >
                      <path
                        d="M9.99999 10.9167C12.5313 10.9167 14.5833 8.86464 14.5833 6.33333C14.5833 3.80203 12.5313 1.75 9.99999 1.75C7.46868 1.75 5.41666 3.80203 5.41666 6.33333C5.41666 8.86464 7.46868 10.9167 9.99999 10.9167Z"
                        fill="black"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M11.7108 18.8434C11.5441 18.5197 11.4575 18.1608 11.4583 17.7967V14.88C11.4583 14.1684 11.7825 13.5325 12.2917 13.1125V12.7967C12.2917 12.4625 12.345 12.1334 12.4475 11.8217C11.6377 11.6965 10.8194 11.6341 10 11.635C7.23168 11.635 4.78084 12.3275 3.25918 13.365C2.09834 14.1567 1.45834 15.1625 1.45834 16.2184V17.4267C1.45878 17.8023 1.60818 18.1624 1.87376 18.4279C2.13934 18.6935 2.49942 18.8429 2.87501 18.8434H11.7108Z"
                        fill="black"
                      />
                      <path
                        d="M15.8333 11.755H15.4167C15.1407 11.7559 14.8762 11.8659 14.6811 12.0611C14.4859 12.2562 14.3759 12.5207 14.375 12.7967V14.0467C14.375 14.2124 14.3092 14.3714 14.1919 14.4886C14.0747 14.6058 13.9158 14.6717 13.75 14.6717C13.5842 14.6717 13.4253 14.6058 13.3081 14.4886C13.1908 14.3714 13.125 14.2124 13.125 14.0467V12.7967C13.1254 12.189 13.367 11.6064 13.7967 11.1767C14.2264 10.747 14.809 10.5054 15.4167 10.505H15.7433C16.0518 10.4929 16.3595 10.5432 16.648 10.6529C16.9365 10.7626 17.1999 10.9295 17.4223 11.1435C17.6447 11.3576 17.8216 11.6143 17.9424 11.8984C18.0631 12.1825 18.1252 12.488 18.125 12.7967V14.0467C18.125 14.2124 18.0592 14.3714 17.9419 14.4886C17.8247 14.6058 17.6658 14.6717 17.5 14.6717C17.3342 14.6717 17.1753 14.6058 17.0581 14.4886C16.9408 14.3714 16.875 14.2124 16.875 14.0467V12.7967C16.8746 12.5205 16.7647 12.2558 16.5694 12.0606C16.3742 11.8653 16.1095 11.7554 15.8333 11.755Z"
                        fill="black"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M18.9583 17.7967V14.88C18.9583 14.4933 18.8047 14.1223 18.5312 13.8488C18.2577 13.5753 17.8868 13.4217 17.5 13.4217H13.75C13.3632 13.4217 12.9923 13.5753 12.7188 13.8488C12.4453 14.1223 12.2917 14.4933 12.2917 14.88V17.7967C12.2917 18.6025 12.945 19.255 13.75 19.255H17.5C17.8868 19.255 18.2577 19.1014 18.5312 18.8279C18.8047 18.5544 18.9583 18.1835 18.9583 17.7967ZM15 15.9217V16.755C15 16.9208 15.0658 17.0798 15.183 17.197C15.3003 17.3142 15.4592 17.38 15.625 17.38C15.7908 17.38 15.9497 17.3142 16.0669 17.197C16.1841 17.0798 16.25 16.9208 16.25 16.755V15.9217C16.25 15.7559 16.1841 15.597 16.0669 15.4798C15.9497 15.3625 15.7908 15.2967 15.625 15.2967C15.4592 15.2967 15.3003 15.3625 15.183 15.4798C15.0658 15.597 15 15.7559 15 15.9217Z"
                        fill="black"
                      />
                    </svg>
                    {blockUnblock ? "Unblock" : "Block"}
                  </button>
                </>
              ) : null}
            </div>
            {/* <div class="dropdown d-none">
                            <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src="\assets\more-icon.svg" alt="img" className="img-fluid" />
                            </button>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="#"><img src="\assets\edit.svg" alt="img" className="img-fluid" />Edit</a></li>
                                <li><a class="dropdown-item" href="#"><img src="\assets\delete.svg" alt="img" className="img-fluid" />Delete</a></li>
                            </ul>
                        </div> */}
          </div>
          <div
            className="inner-heading newheadingss"
            style={{ flexDirection: "column", alignItems: "flex-start" }}
          >
            <p>Nodes & Miners</p>
            <h4>
              {leaderscount?.name} - {convertToTitleCase(leaderscount?.role)} -{" "}
              <span>{leaderscount?.accessCode}</span>
            </h4>
          </div>
          <div
            className={
              leaderscount?.role == "mini_agent"
                ? "bottom-cards  newclassagent"
                : "bottom-cards "
            }
          >
            <div className="single-card">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M14 11H10C9.46957 11 8.96086 10.7893 8.58579 10.4142C8.21071 10.0391 8 9.53043 8 9C8 8.46957 8.21071 7.96086 8.58579 7.58579C8.96086 7.21071 9.46957 7 10 7H15C15.2652 7 15.5196 7.10536 15.7071 7.29289C15.8946 7.48043 16 7.73478 16 8C16 8.26522 16.1054 8.51957 16.2929 8.70711C16.4804 8.89464 16.7348 9 17 9C17.2652 9 17.5196 8.89464 17.7071 8.70711C17.8946 8.51957 18 8.26522 18 8C18 7.20435 17.6839 6.44129 17.1213 5.87868C16.5587 5.31607 15.7956 5 15 5H13V3C13 2.73478 12.8946 2.48043 12.7071 2.29289C12.5196 2.10536 12.2652 2 12 2C11.7348 2 11.4804 2.10536 11.2929 2.29289C11.1054 2.48043 11 2.73478 11 3V5H10C8.93913 5 7.92172 5.42143 7.17157 6.17157C6.42143 6.92172 6 7.93913 6 9C6 10.0609 6.42143 11.0783 7.17157 11.8284C7.92172 12.5786 8.93913 13 10 13H14C14.5304 13 15.0391 13.2107 15.4142 13.5858C15.7893 13.9609 16 14.4696 16 15C16 15.5304 15.7893 16.0391 15.4142 16.4142C15.0391 16.7893 14.5304 17 14 17H9C8.73478 17 8.48043 16.8946 8.29289 16.7071C8.10536 16.5196 8 16.2652 8 16C8 15.7348 7.89464 15.4804 7.70711 15.2929C7.51957 15.1054 7.26522 15 7 15C6.73478 15 6.48043 15.1054 6.29289 15.2929C6.10536 15.4804 6 15.7348 6 16C6 16.7956 6.31607 17.5587 6.87868 18.1213C7.44129 18.6839 8.20435 19 9 19H11V21C11 21.2652 11.1054 21.5196 11.2929 21.7071C11.4804 21.8946 11.7348 22 12 22C12.2652 22 12.5196 21.8946 12.7071 21.7071C12.8946 21.5196 13 21.2652 13 21V19H14C15.0609 19 16.0783 18.5786 16.8284 17.8284C17.5786 17.0783 18 16.0609 18 15C18 13.9391 17.5786 12.9217 16.8284 12.1716C16.0783 11.4214 15.0609 11 14 11Z"
                  fill="#021C24"
                />
              </svg>
              <p>Sold Amount</p>
              <h6>
                ~$
                {leaderscount?.totalAmountInUsd > 0
                  ? formatTokenValue(leaderscount?.totalAmountInUsd, 2)
                  : 0}
              </h6>
              <div className="bottom-text">
                <p className="uni-text">
                  <span></span>$
                  {leaderscount?.totalAmountInUsd > 0
                    ? formatTokenValue(leaderscount?.directAmountInUsd, 2)
                    : 0}{" "}
                  Direct
                </p>
                <p className="uni-text">
                  <span></span>$
                  {leaderscount?.indirectAmountInUsd > 0
                    ? formatTokenValue(leaderscount?.indirectAmountInUsd, 2)
                    : 0}{" "}
                  Indirect
                </p>
              </div>
              <div className="valuemaindiv">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    <img
                      src="\assets\Caret_Down_MD.svg"
                      alt="arrowimg"
                      className="arrowimg"
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <div className="teamsdrops">
                      {leaderscount && leaderscount?.totalEth > 0 ? (
                        <div className="innerteamdrop">
                          <p className="teamdroppara">ETH</p>
                          <h6 className="teamdrophead">
                            {leaderscount
                              ? leaderscount?.totalEth?.toFixed(4)
                              : 0}{" "}
                          </h6>
                        </div>
                      ) : null}
                      {leaderscount && leaderscount?.totalUsdt > 0 ? (
                        <div className="innerteamdrop">
                          <p className="teamdroppara">USDT</p>
                          <h6 className="teamdrophead">
                            {leaderscount
                              ? parseFloat(
                                  leaderscount?.totalUsdt?.toFixed(2)
                                )?.toLocaleString("en-US")
                              : 0}{" "}
                          </h6>
                        </div>
                      ) : null}
                      {leaderscount && leaderscount?.totalUsdc > 0 ? (
                        <div className="innerteamdrop">
                          <p className="teamdroppara">USDC</p>
                          <h6 className="teamdrophead">
                            {leaderscount
                              ? parseFloat(
                                  leaderscount?.totalUsdc?.toFixed(2)
                                )?.toLocaleString("en-US")
                              : 0}{" "}
                          </h6>
                        </div>
                      ) : null}

                      {leaderscount && leaderscount?.totalGems > 0 ? (
                        <div className="innerteamdrop">
                          <p className="teamdroppara">GEMS</p>
                          <h6 className="teamdrophead">
                            {leaderscount
                              ? parseFloat(
                                  leaderscount?.totalGems?.toFixed(2)
                                )?.toLocaleString("en-US")
                              : 0}{" "}
                          </h6>
                        </div>
                      ) : null}

                      <a
                        style={{
                          color: "#329879",
                          justifyContent: "center",
                          padding: "10px",
                          display: "flex",
                        }}
                        onClick={() => setShow11(true)}
                      >
                        View All
                      </a>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="single-card">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M14 11H10C9.46957 11 8.96086 10.7893 8.58579 10.4142C8.21071 10.0391 8 9.53043 8 9C8 8.46957 8.21071 7.96086 8.58579 7.58579C8.96086 7.21071 9.46957 7 10 7H15C15.2652 7 15.5196 7.10536 15.7071 7.29289C15.8946 7.48043 16 7.73478 16 8C16 8.26522 16.1054 8.51957 16.2929 8.70711C16.4804 8.89464 16.7348 9 17 9C17.2652 9 17.5196 8.89464 17.7071 8.70711C17.8946 8.51957 18 8.26522 18 8C18 7.20435 17.6839 6.44129 17.1213 5.87868C16.5587 5.31607 15.7956 5 15 5H13V3C13 2.73478 12.8946 2.48043 12.7071 2.29289C12.5196 2.10536 12.2652 2 12 2C11.7348 2 11.4804 2.10536 11.2929 2.29289C11.1054 2.48043 11 2.73478 11 3V5H10C8.93913 5 7.92172 5.42143 7.17157 6.17157C6.42143 6.92172 6 7.93913 6 9C6 10.0609 6.42143 11.0783 7.17157 11.8284C7.92172 12.5786 8.93913 13 10 13H14C14.5304 13 15.0391 13.2107 15.4142 13.5858C15.7893 13.9609 16 14.4696 16 15C16 15.5304 15.7893 16.0391 15.4142 16.4142C15.0391 16.7893 14.5304 17 14 17H9C8.73478 17 8.48043 16.8946 8.29289 16.7071C8.10536 16.5196 8 16.2652 8 16C8 15.7348 7.89464 15.4804 7.70711 15.2929C7.51957 15.1054 7.26522 15 7 15C6.73478 15 6.48043 15.1054 6.29289 15.2929C6.10536 15.4804 6 15.7348 6 16C6 16.7956 6.31607 17.5587 6.87868 18.1213C7.44129 18.6839 8.20435 19 9 19H11V21C11 21.2652 11.1054 21.5196 11.2929 21.7071C11.4804 21.8946 11.7348 22 12 22C12.2652 22 12.5196 21.8946 12.7071 21.7071C12.8946 21.5196 13 21.2652 13 21V19H14C15.0609 19 16.0783 18.5786 16.8284 17.8284C17.5786 17.0783 18 16.0609 18 15C18 13.9391 17.5786 12.9217 16.8284 12.1716C16.0783 11.4214 15.0609 11 14 11Z"
                  fill="#021C24"
                />
              </svg>
              <p>Leader’s Earnings</p>$
              {leaderscount?.totalAmountEarned > 0
                ? formatTokenValue(leaderscount?.totalAmountEarned, 2)
                : 0}
              <div className="bottom-text">
                {leaderscount?.myEarningsEth != 0 ? (
                  <p className="uni-text">
                    <span></span>
                    {leaderscount?.myEarningsEth
                      ? parseFloat(leaderscount.myEarningsEth).toFixed(5)
                      : "0"}{" "}
                    ETH
                  </p>
                ) : null}
                {leaderscount?.myEarningsUsdt != 0 ? (
                  <p className="uni-text">
                    <span></span>
                    {leaderscount?.myEarningsUsdt
                      ? parseFloat(leaderscount.myEarningsUsdt).toFixed(2)
                      : "0"}{" "}
                    USDT
                  </p>
                ) : null}
                {leaderscount?.myEarningsGems != 0 ? (
                  <p className="uni-text">
                    <span></span>
                    {leaderscount?.myEarningsGems
                      ? parseFloat(leaderscount.myEarningsGems)?.toFixed(0)
                      : "0"}{" "}
                    GEMS
                  </p>
                ) : null}
                {leaderscount?.myEarningsUsdc != 0 ? (
                  <p className="uni-text">
                    <span></span>
                    {leaderscount?.myEarningsUsdc
                      ? parseFloat(leaderscount.myEarningsUsdc).toFixed(2)
                      : "0"}{" "}
                    USDC
                  </p>
                ) : null}
              </div>
              <div className="valuemaindiv">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    <img
                      src="\assets\Caret_Down_MD.svg"
                      alt="arrowimg"
                      className="arrowimg"
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <div className="teamsdrops">
                      {leaderscount?.myEarningsEth > 0 ? (
                        <div className="innerteamdrop">
                          <p className="teamdroppara">ETH</p>
                          <h6 className="teamdrophead">
                            {leaderscount
                              ? leaderscount?.myEarningsEth?.toFixed(4)
                              : 0}{" "}
                          </h6>
                        </div>
                      ) : null}
                      {leaderscount?.myEarningsUsdt > 0 ? (
                        <div className="innerteamdrop">
                          <p className="teamdroppara">USDT</p>
                          <h6 className="teamdrophead">
                            {leaderscount
                              ? parseFloat(
                                  leaderscount?.myEarningsUsdt?.toFixed(2)
                                )?.toLocaleString("en-US")
                              : 0}{" "}
                          </h6>
                        </div>
                      ) : null}
                      {leaderscount?.myEarningsUsdc > 0 ? (
                        <div className="innerteamdrop">
                          <p className="teamdroppara">USDC</p>
                          <h6 className="teamdrophead">
                            {leaderscount
                              ? parseFloat(
                                  leaderscount?.myEarningsUsdc?.toFixed(2)
                                )?.toLocaleString("en-US")
                              : 0}{" "}
                          </h6>
                        </div>
                      ) : null}
                      {leaderscount?.myEarningsGems > 0 ? (
                        <div className="innerteamdrop">
                          <p className="teamdroppara">GEMS</p>
                          <h6 className="teamdrophead">
                            {leaderscount
                              ? parseFloat(
                                  leaderscount?.myEarningsGems?.toFixed(2)
                                )?.toLocaleString("en-US")
                              : 0}{" "}
                          </h6>
                        </div>
                      ) : null}
                    </div>
                    <a
                      style={{
                        color: "#329879",
                        justifyContent: "center",
                        padding: "10px",
                        display: "flex",
                      }}
                      onClick={() => setShow12(true)}
                    >
                      View All
                    </a>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className="bottom-cards bottom-cardswiththree">
            <div className="single-card">
              <img
                src="\assets\dashboard\purchasedamount.svg"
                alt="img"
                className="img-fluid"
              />
              <p>No. of Purchases</p>
              <h6>
                {leaderscount?.totalSales > 0
                  ? formatTokenValue(leaderscount?.totalSales, 3)
                  : 0}
              </h6>

              <div className="bottom-text">
                <p className="uni-text">
                  <span></span>
                  {leaderscount?.totalDirectSales > 0
                    ? formatTokenValue(leaderscount?.totalDirectSales, 3)
                    : 0}{" "}
                  Direct
                </p>
                <p className="uni-text">
                  <span></span>~{" "}
                  {leaderscount?.totalInDirectSales > 0
                    ? formatTokenValue(leaderscount?.totalInDirectSales, 3)
                    : 0}{" "}
                  indirect
                </p>
              </div>
            </div>
            <div className="single-card">
              <img
                src="\assets\incentive-purchased.svg"
                alt="img"
                className="img-fluid"
              />
              <p>Total Miners Sold</p>
              <h6 className="datahead">
                {" "}
                {(leaderscount?.totalMiniMiners || 0) +
                  (leaderscount?.totalStandardMiners || 0) +
                  (leaderscount?.totalMegaMiners || 0)}
              </h6>

              <div className="bottom-text">
                <p className="uni-text">
                  <span></span>
                  {/* ? (user?.totalEth + user?.totalEthIndirect)?.toFixed(4) */}
                  {leaderscount?.totalMiniMiners || 0} Mini
                </p>{" "}
                <p className="uni-text">
                  <span></span>
                  {leaderscount?.totalStandardMiners || 0} Miner
                </p>
                <p className="uni-text">
                  <span></span>
                  {leaderscount?.totalMegaMiners || 0} Mega
                </p>
              </div>
            </div>
            <div className="single-card">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M17.6279 20.9358L17.6279 18.1822L10.9556 14.4471L10.9556 17.2007L17.6279 20.9358Z"
                  fill="#021C24"
                />
                <path
                  d="M0 20.9358L8.42534e-07 18.1822L6.67229 14.4471L6.67229 17.2007L0 20.9358Z"
                  fill="#021C24"
                />
                <path
                  d="M24.0967 20.3504L24.0967 17.5968L17.9148 14.1566L17.9148 16.9101L24.0967 20.3504Z"
                  fill="#021C24"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.7584 4.89424C7.35738 4.89424 6.22163 6.02999 6.22163 7.43101C6.22163 8.83203 7.35738 9.96778 8.7584 9.96778C10.1594 9.96778 11.2952 8.83203 11.2952 7.43101C11.2952 6.02999 10.1594 4.89424 8.7584 4.89424ZM4.03711 7.43101C4.03711 4.82351 6.15091 2.70972 8.7584 2.70972C11.3659 2.70972 13.4797 4.82351 13.4797 7.43101C13.4797 10.0385 11.3659 12.1523 8.7584 12.1523C6.15091 12.1523 4.03711 10.0385 4.03711 7.43101Z"
                  fill="#021C24"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M18.5534 7.43101C18.5534 6.02999 17.4176 4.89424 16.0166 4.89424V2.70972C18.6241 2.70972 20.7379 4.82351 20.7379 7.43101C20.7379 10.0385 18.6241 12.1523 16.0166 12.1523V9.96778C17.4176 9.96778 18.5534 8.83203 18.5534 7.43101Z"
                  fill="#021C24"
                />
              </svg>
              <p>No. of Leaders</p>
              <h6 className="datahead">
                {(leaderscount?.gods || 0) +
                  (leaderscount?.megas || 0) +
                  (leaderscount?.leaders || 0) +
                  (leaderscount?.supers || 0) +
                  (leaderscount?.miniLeaders || 0)}
              </h6>
              <div className="bottom-text">
                <p className="uni-text">
                  <span></span>
                  {leaderscount?.gods || 0} God
                </p>
                <p className="uni-text">
                  <span></span>
                  {leaderscount?.megas || 0} Mega
                </p>
                <p className="uni-text">
                  <span></span>
                  {leaderscount?.supers || 0} Super
                </p>
              </div>
              <div className="valuemaindiv">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    <img
                      src="\assets\Caret_Down_MD.svg"
                      alt="arrowimg"
                      className="arrowimg"
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <div className="teamsdrops">
                      <div className="innerteamdrop">
                        <p className="teamdroppara">God</p>
                        <h6 className="teamdrophead">
                          {" "}
                          {leaderscount?.gods || 0}
                        </h6>
                      </div>
                      <div className="innerteamdrop">
                        <p className="teamdroppara">Mega</p>
                        <h6 className="teamdrophead">
                          {" "}
                          {leaderscount?.megas || 0}
                        </h6>
                      </div>

                      <div className="innerteamdrop">
                        <p className="teamdroppara">Super</p>
                        <h6 className="teamdrophead">
                          {leaderscount?.supers || 0}
                        </h6>
                      </div>

                      <div className="innerteamdrop">
                        <p className="teamdroppara">Leader</p>
                        <h6 className="teamdrophead">
                          {leaderscount?.leaders || 0}
                        </h6>
                      </div>
                      <div className="innerteamdrop">
                        <p className="teamdroppara">Ambassador</p>
                        <h6 className="teamdrophead">
                          {leaderscount?.miniLeaders || 0}
                        </h6>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className="parent-new-card">
            <div className="main-headingg">
              <h6>General Information</h6>
            </div>
            <div className="bottom-cards1">
              <div className="inner-text">
                <p>Access Code</p>
                <h6>{leaderscount?.accessCode}</h6>
              </div>
              <div className="inner-text">
                <p>Name</p>
                <h6>{leaderscount?.name}</h6>
              </div>
              <div className="inner-text">
                <p>Direct Sale</p>
                {leaderscount?.totalDirectSales != 0 ||
                leaderscount?.totalInDirectSales != 0 ? (
                  <h6>
                    {parseInt(
                      (leaderscount?.totalDirectSales /
                        (leaderscount?.totalDirectSales +
                          leaderscount?.totalInDirectSales)) *
                        100
                    ).toFixed(2)}
                    %
                  </h6>
                ) : (
                  <h6>0%</h6>
                )}
              </div>
              <div className="inner-text">
                <p>Indirect Sale</p>

                {leaderscount?.totalDirectSales != 0 ||
                leaderscount?.totalInDirectSales != 0 ? (
                  <h6>
                    {parseInt(
                      (leaderscount?.totalInDirectSales /
                        (leaderscount?.totalDirectSales +
                          leaderscount?.totalInDirectSales)) *
                        100
                    ).toFixed(2)}
                    %
                  </h6>
                ) : (
                  <h6>0%</h6>
                )}
              </div>
              <div className="inner-text">
                <p>Created On</p>
                <h6>
                  {moment(leaderscount?.user[0]?.createdAt).format(
                    "DD/MM/YYYY hh:mm"
                  )}{" "}
                  {/* UTC */}
                </h6>
              </div>
              <div className="inner-text">
                <p>Email address</p>
                <h6>{leaderscount?.email}</h6>
              </div>
              <div className="inner-text setcustomwidthwalletaddress">
                <p>Owner’s Wallet Address</p>
                <h6 className="forflex">
                  {leaderscount?.walletAddress}
                  {/* <img
                    src="\assets\editi.svg"
                    alt="img"
                    style={{ cursor: "pointer" }}
                    onClick={handleShowchange}
                  /> */}
                  {/* // ?.slice(0, 11)}...
                  // {userDel?.walletAddress?.slice( */}
                  {/* //   userDel?.walletAddress?.length - 3,
                  //   userDel?.walletAddress?.length
                  // )} */}
                </h6>
              </div>

              <div className="inner-text">
                <p>Discount %</p>
                <h6 className="forflex">
                  {leaderscount?.user[0]?.allowedMinerDiscount}%
                  <svg
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ cursor: "pointer" }}
                    onClick={handleShowMinerDiscount}
                  >
                    <path
                      d="M5.75 12.7598L9.05975 12.7486L16.2838 5.59355C16.5673 5.31005 16.7233 4.93355 16.7233 4.53305C16.7233 4.13255 16.5673 3.75605 16.2838 3.47255L15.0943 2.28305C14.5273 1.71605 13.538 1.71905 12.9755 2.2808L5.75 9.4373V12.7598ZM14.0338 3.34355L15.2255 4.5308L14.0278 5.7173L12.8383 4.52855L14.0338 3.34355ZM7.25 10.0628L11.7725 5.58305L12.962 6.77255L8.44025 11.2508L7.25 11.2545V10.0628Z"
                      fill="#329879"
                    />
                    <path
                      d="M4.25 15.75H14.75C15.5773 15.75 16.25 15.0773 16.25 14.25V7.749L14.75 9.249V14.25H6.6185C6.599 14.25 6.57875 14.2575 6.55925 14.2575C6.5345 14.2575 6.50975 14.2507 6.48425 14.25H4.25V3.75H9.38525L10.8853 2.25H4.25C3.42275 2.25 2.75 2.92275 2.75 3.75V14.25C2.75 15.0773 3.42275 15.75 4.25 15.75Z"
                      fill="#329879"
                    />
                  </svg>
                  {/* <img
                    src="\assets\editi.svg"
                    alt="img"
                    style={{ cursor: "pointer" }}
                    onClick={handleShowMinerDiscount}
                  /> */}
                </h6>
              </div>
            </div>
            {user?.role == "admin" ? (
              <div className="bottom-cards2">
                <h4>Hierarchy</h4>
                <div className="parent-card">
                  <div className="inner-text">
                    <p>God Leader</p>
                    {leaderscount?.godAgent?.length > 0 ? (
                      <>
                        {user?.role == "admin" ? (
                          <>
                            <a
                              onClick={() => {
                                setUserDel(leaderscount?.godAgent[0]);
                              }}
                              href="#"
                            >
                              <h6 className="style-text">
                                {leaderscount?.godAgent[0]?.name}
                              </h6>
                            </a>
                          </>
                        ) : (
                          <>
                            <h6>{leaderscount?.godAgent[0]?.name}</h6>
                          </>
                        )}
                      </>
                    ) : (
                      <h6>None</h6>
                    )}
                  </div>
                  <div className="inner-text">
                    <p>Mega Leader</p>
                    {leaderscount?.megaAgent?.length > 0 ? (
                      <>
                        {user?.role == "admin" ? (
                          <>
                            <a
                              onClick={() => {
                                setUserDel(leaderscount?.megaAgent[0]);
                              }}
                              href="#"
                            >
                              <h6 className="style-text">
                                {leaderscount?.megaAgent[0]?.name}
                              </h6>
                            </a>
                          </>
                        ) : (
                          <>
                            <h6>{leaderscount?.megaAgent[0]?.name}</h6>
                          </>
                        )}
                      </>
                    ) : (
                      <h6>None</h6>
                    )}
                  </div>
                  <div className="inner-text">
                    <p>Super Leader</p>
                    {leaderscount?.superAgent?.length > 0 ? (
                      <>
                        {user?.role == "admin" ? (
                          <>
                            <a
                              onClick={() => {
                                setUserDel(leaderscount?.superAgent[0]);
                              }}
                              href="#"
                            >
                              <h6 className="style-text">
                                {leaderscount?.superAgent[0]?.name}
                              </h6>
                            </a>
                          </>
                        ) : (
                          <>
                            <h6>{leaderscount?.superAgent[0]?.name}</h6>
                          </>
                        )}
                      </>
                    ) : (
                      <h6>None</h6>
                    )}
                  </div>
                  <div className="inner-text">
                    <p>Leader</p>
                    {leaderscount?.agent?.length > 0 ? (
                      <>
                        {user?.role == "admin" ? (
                          <>
                            <a
                              onClick={() => {
                                setUserDel(leaderscount?.agent[0]);
                              }}
                              href="#"
                            >
                              <h6 className="style-text">
                                {leaderscount?.agent[0]?.name}
                              </h6>
                            </a>
                          </>
                        ) : (
                          <>
                            <h6>{leaderscount?.agent[0]?.name}</h6>
                          </>
                        )}
                      </>
                    ) : (
                      <h6>None</h6>
                    )}
                  </div>
                </div>
              </div>
            ) : null}
            {/* {user?.role == "admin" ? (
              <div className="bottom-cards33">
                <h6>Code Buying Limit</h6>
                <p onClick={() => setShow123(true)}>
                  $ {leaderscount?.minimumBuy ? leaderscount?.minimumBuy : 0}{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M17.5 18.3334H2.5C2.15833 18.3334 1.875 18.05 1.875 17.7084C1.875 17.3667 2.15833 17.0834 2.5 17.0834H17.5C17.8417 17.0834 18.125 17.3667 18.125 17.7084C18.125 18.05 17.8417 18.3334 17.5 18.3334Z"
                      fill="white"
                    />
                    <path
                      d="M15.85 2.90005C14.2333 1.28338 12.65 1.24172 10.9916 2.90005L9.98331 3.90838C9.89998 3.99172 9.86665 4.12505 9.89998 4.24172C10.5333 6.45005 12.3 8.21672 14.5083 8.85005C14.5416 8.85838 14.575 8.86672 14.6083 8.86672C14.7 8.86672 14.7833 8.83338 14.85 8.76672L15.85 7.75838C16.675 6.94172 17.075 6.15005 17.075 5.35005C17.0833 4.52505 16.6833 3.72505 15.85 2.90005Z"
                      fill="white"
                    />
                    <path
                      d="M13.0083 9.60829C12.7666 9.49163 12.5333 9.37496 12.3083 9.24163C12.125 9.13329 11.95 9.01663 11.775 8.89163C11.6333 8.79996 11.4666 8.66663 11.3083 8.53329C11.2916 8.52496 11.2333 8.47496 11.1666 8.40829C10.8916 8.17496 10.5833 7.87496 10.3083 7.54163C10.2833 7.52496 10.2416 7.46663 10.1833 7.39163C10.1 7.29163 9.95831 7.12496 9.83331 6.93329C9.73331 6.80829 9.61665 6.62496 9.50831 6.44163C9.37498 6.21663 9.25831 5.99163 9.14165 5.75829C8.98868 5.43051 8.55847 5.33314 8.3027 5.58891L3.61665 10.275C3.50831 10.3833 3.40831 10.5916 3.38331 10.7333L2.93331 13.925C2.84998 14.4916 3.00831 15.025 3.35831 15.3833C3.65831 15.675 4.07498 15.8333 4.52498 15.8333C4.62498 15.8333 4.72498 15.825 4.82498 15.8083L8.02498 15.3583C8.17498 15.3333 8.38331 15.2333 8.48331 15.125L13.1771 10.4312C13.4278 10.1805 13.3336 9.74924 13.0083 9.60829Z"
                      fill="white"
                    />
                  </svg>
                </p>
              </div>
            ) : null} */}

            {/* {user?.role == "admin" ? (
              <div className="bottom-cards2 bottom-cards3">
                <h4>Special Access</h4>
                <div className="parent-card">
                  {specialAccess?.length > 0
                    ? specialAccess?.map((item) => {
                        return (
                          <>
                            <div className="inner-text">
                              <p>God Leader</p>
                              {leaderscount?.role == "god_agent" ? (
                                <>
                                  <h6 className="style-text">{item?.name}</h6>
                                </>
                              ) : (
                                <h6>None</h6>
                              )}
                            </div>
                          </>
                        );
                      })
                    : null}
                </div>
              </div>
            ) : null} */}
          </div>
          {/* {user?.role == "admin" ? (
            <Specialaccess
              specialAccess={specialAccess}
              setUserDel={setUserDel}
              setShow1={setShow1}
            />
          ) : null} */}

          {/* <Tabs
            defaultActiveKey="transactions"
            id="uncontrolled-tab-example"
            className="viewdetail-tabs"
            onSelect={(e) => {
              setTabKey(e);
            }}
          >
            <Tab eventKey="transactions" title="All Transactions"> */}

          <div className="main-heading111">
            <h5 className="heading-text" style={{ color: "#02171D" }}>
              Transactions
            </h5>
            <div
              className={
                userDel?.role !== "agent"
                  ? "rightside-content fxrxxrxxrexxr "
                  : "rightside-content "
              }
            >
              <div
                className=" custom-option-field "
                style={{ maxWidth: "50vw" }}
              >
                {/* <form onSubmit={(e) => GetSearch(e)}> */}
                <input
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  type="search"
                  placeholder="Search..."
                />
                {/* </form> */}
                <img
                  style={{ cursor: "pointer" }}
                  src="\assets\search-icon.svg"
                  alt="img"
                  className="img-fluid search-icon"
                  // onClick={() => GetSearch()}
                />
              </div>
              {/* {userDel?.role !== "mini_agent" && (
                <div class="dropdown">
                  <button
                    class="dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Type{" "}
                    <img
                      src="\assets\arrow-down.svg"
                      alt="img"
                      className="img-fluid"
                    />
                  </button>
                  {userDel?.role === "god_agent" && (
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          onClick={() => setRoleTypeFilter("")}
                          class="dropdown-item"
                          href="#"
                        >
                          All Leaders
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => setRoleTypeFilter("god_agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          God Leader
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => setRoleTypeFilter("mega_agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Mega Leader
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => setRoleTypeFilter("super_agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Super Leader
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => setRoleTypeFilter("agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Leader
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => setRoleTypeFilter("mini_agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Ambassador
                        </a>
                      </li>
                    </ul>
                  )}
                  {userDel?.role === "mega_agent" && (
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          onClick={() => setRoleTypeFilter("")}
                          class="dropdown-item"
                          href="#"
                        >
                          All Leaders
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => setRoleTypeFilter("mega_agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Mega Leader
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => setRoleTypeFilter("super_agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Super Leader
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => setRoleTypeFilter("agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Leader
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => setRoleTypeFilter("mini_agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Ambassador
                        </a>
                      </li>
                     
                    </ul>
                  )}
                  {userDel?.role === "super_agent" && (
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          onClick={() => setRoleTypeFilter("")}
                          class="dropdown-item"
                          href="#"
                        >
                          All Leaders
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => setRoleTypeFilter("agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Leader
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => setRoleTypeFilter("mini_agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Ambassador
                        </a>
                      </li>
                    </ul>
                  )}
                  {userDel?.role === "agent" && (
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          onClick={() => setRoleTypeFilter("")}
                          class="dropdown-item"
                          href="#"
                        >
                          All Leaders
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => setRoleTypeFilter("mini_agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Ambassador
                        </a>
                      </li>
                    </ul>
                  )}
                </div>
              )} */}
              {/* <button className="reset-btn-global">Reset</button> */}
            </div>
          </div>
          {transactions?.length > 0 ? (
            <DataTable
              tomiPrice={tomiPrice}
              transactions={transactions}
              setTransactions={setTransactions}
              user={user}
              usdPrice={usdPrice}
              page={page1}
              pageCount={pageCount}
              setOrderDirection={setOrderDirection}
              setOrderField={setOrderField}
            />
          ) : (
            <p
              className=""
              style={{ color: "#000", textAlign: "center", marginTop: 100 }}
            >
              No data found!
            </p>
          )}
          <div className="footer-content mt-2">
            <div className="left-f">
              {/* <h6>SHOWING 1-10 OF 145</h6> */}
              {/* <h6>SHOWING 1-10 OF 145</h6> */}
            </div>
            <div className="right-f">
              <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <nav aria-label="Page navigation example">
                    <ul class="text-center">
                      <ReactPaginate
                        previousLabel={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="17"
                            viewBox="0 0 17 17"
                            fill="none"
                          >
                            <path
                              d="M7.88916 2.68457L5.38776 2.68457L1.99471 8.74584L4.49612 8.74584L7.88916 2.68457Z"
                              fill="#329879"
                            />
                            <path
                              d="M7.88916 14.8193L5.38776 14.8193L1.99471 8.75807L3.5712 7.7102L4.49612 8.75807L7.88916 14.8193Z"
                              fill="#329879"
                            />
                            <path
                              d="M8.01642 7.60645H14.9615L13.6868 9.89216H6.7417L8.01642 7.60645Z"
                              fill="#329879"
                            />
                          </svg>
                        }
                        nextLabel={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="17"
                            viewBox="0 0 17 17"
                            fill="none"
                          >
                            <path
                              d="M9.11084 2.68457L11.6122 2.68457L15.0053 8.74584L12.5039 8.74584L9.11084 2.68457Z"
                              fill="#329879"
                            />
                            <path
                              d="M9.11084 14.8193L11.6122 14.8193L15.0053 8.75807L13.4288 7.7102L12.5039 8.75807L9.11084 14.8193Z"
                              fill="#329879"
                            />
                            <path
                              d="M3.31281 7.60645H10.2579L8.98314 9.89216H2.03809L3.31281 7.60645Z"
                              fill="#329879"
                            />
                          </svg>
                        }
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                        containerClassName="pagination"
                        activeClassName="active"
                        forcePage={page1}
                      ></ReactPaginate>
                    </ul>
                  </nav>
                </ul>
              </nav>
            </div>
          </div>
          {/* </Tab> */}
          {/* {userDel?.role != "mini_agent" ? (
              <Tab eventKey="leaders" title="All Leaders">
                <div className="main-heading2 ">
                  <div
                    className={
                      userDel?.role == "mini_agent"
                        ? "rightside-content gvcvtdvdvtdcvdtc"
                        : "rightside-content"
                    }
                  >
                    <div className="custom-option-field">
                      <input
                        type="search"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={(e) => {
                          setSearchQuery(e.target.value);
                        }}
                      />
                      <img
                        style={{ cursor: "pointer" }}
                        src="\assets\search-icon.svg"
                        alt="img"
                        className="img-fluid search-icon"
                      />
                    </div>
                    {userDel?.role == "mini_agent" ? (
                      ""
                    ) : (
                      <div class="dropdown">
                        <button
                          class="dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <div
                           
                            className="se-custom-flex-gap"
                          >
                            {roleTypeFilter == "god_agent"
                              ? "God"
                              : roleTypeFilter == "mega_agent"
                              ? "Mega"
                              : roleTypeFilter == "super_agent"
                              ? "Super"
                              : roleTypeFilter == "agent"
                              ? "Leader "
                              : roleTypeFilter == "mini_agent"
                              ? "Ambassador "
                              : "By Role"}
                            <img
                              src="\assets\arrow-down.svg"
                              alt="img"
                              className="img-fluid"
                            />
                          </div>
                        </button>
                        {userDel?.role == "admin" ? (
                          <ul class="dropdown-menu">
                            <li>
                              <a
                                onClick={() => setRoleTypeFilter("")}
                                class="dropdown-item"
                                href="#"
                              >
                                All Leaders
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("god_agent");
                                }}
                              >
                                God
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("mega_agent");
                                }}
                              >
                                Mega
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("super_agent");
                                }}
                              >
                                Super
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("agent");
                                }}
                              >
                                Leader
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("mini_agent");
                                }}
                              >
                                Ambassador
                              </a>
                            </li>
                          </ul>
                        ) : userDel?.role == "god_agent" ? (
                          <ul class="dropdown-menu">
                            <li>
                              <a
                                onClick={() => setRoleTypeFilter("")}
                                class="dropdown-item"
                                href="#"
                              >
                                All Leaders
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("mega_agent");
                                }}
                              >
                                Mega
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("super_agent");
                                }}
                              >
                                Super
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("agent");
                                }}
                              >
                                Leader
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("mini_agent");
                                }}
                              >
                                Ambassador
                              </a>
                            </li>
                          </ul>
                        ) : userDel?.role == "mega_agent" ? (
                          <ul class="dropdown-menu">
                            <li>
                              <a
                                onClick={() => setRoleTypeFilter("")}
                                class="dropdown-item"
                                href="#"
                              >
                                All Leaders
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("super_agent");
                                }}
                              >
                                Super
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("agent");
                                }}
                              >
                                Leader
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("mini_agent");
                                }}
                              >
                                Ambassador
                              </a>
                            </li>
                          </ul>
                        ) : userDel?.role == "super_agent" ? (
                          <ul class="dropdown-menu">
                            <li>
                              <a
                                onClick={() => setRoleTypeFilter("")}
                                class="dropdown-item"
                                href="#"
                              >
                                All Leaders
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("agent");
                                }}
                              >
                                Leader
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("mini_agent");
                                }}
                              >
                                Ambassador
                              </a>
                            </li>
                          </ul>
                        ) : userDel?.role == "agent" ? (
                          <ul class="dropdown-menu">
                            <li>
                              <a
                                onClick={() => setRoleTypeFilter("")}
                                class="dropdown-item"
                                href="#"
                              >
                                All Leaders
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("mini_agent");
                                }}
                              >
                                Ambassador
                              </a>
                            </li>
                          </ul>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                    <div class="dropdown">
                      <button
                        class="dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <div
                       
                          className="se-custom-flex-gap"
                        >
                          {leaderFilter ? leaderFilter + " " : "By Name "}

                          <img
                            src="\assets\arrow-down.svg"
                            alt="img"
                            className="img-fluid"
                          />
                        </div>
                      </button>
                      <ul class="dropdown-menu set-custom-scrollbar">
                        <div className="custom-option-field drop-in-search-sticky">
                          <input type="search" placeholder="Search" />
                          <img
                            src="\assets\search-icon.svg"
                            alt="img"
                            className="img-fluid search-icon"
                          />
                        </div>
                        {leaderNames?.length > 0
                          ? leaderNames?.map((item) => {
                              return (
                                <>
                                  <li>
                                    <a
                                      class="dropdown-item"
                                      href="#"
                                      onClick={() => {
                                        setLeaderFilter(item?.name);
                                      }}
                                    >
                                      {item?.name}
                                    </a>
                                  </li>
                                </>
                              );
                            })
                          : null}
                      </ul>
                    </div>
                  
                  </div>
                </div>
                {agents?.length > 0 ? (
                  <DataTableLeaders
                    btcPrice={btcPrice}
                    usdPrice={usdPrice}
                    tomiPrice={tomiPrice}
                    pepePrice={pepePrice}
                    linkPrice={linkPrice}
                    uniPrice={uniPrice}
                    dopPrice={dopPrice}
                    gemsPrice={gemsPrice}
                    currentPage={currentPage}
                    GetAllRounds={GetAgents}
                    pages={pages}
                    GetAgents={GetAgents}
                    page={page}
                    pageCount={pageCountAgent}
                    handlePageChange={handleAgentsPageChange}
                    offset={offset}
                    setOffset={setOffset}
                    agents={agents}
                    setAgents={setAgents}
                    setRoutes={setRoutes}
                    sub={true}
                    pagination={true}
                  />
                ) : (
                  <p
                    className=""
                    style={{
                      color: "#000",
                      textAlign: "center",
                      marginTop: 100,
                    }}
                  >
                    No data found!
                  </p>
                )}
              </Tab>
            ) : null} */}

          {/* {leaderscount?.role == "god_agent" &&
            leaderscount?.specialAccess?.length > 0 ? (
              <Tab eventKey="special" title="Special Access">
                <DataTableSpecialAccess
                 
                  btcPrice={btcPrice}
                  tomiPrice={tomiPrice}
                  pepePrice={pepePrice}
                  linkPrice={linkPrice}
                  uniPrice={uniPrice}
                  dopPrice={dopPrice}
                  gemsPrice={gemsPrice}
                  currentPage={currentPage}
                  GetAllRounds={getSpecialAccessData}
                  pages={pages}
                  GetAgents={getSpecialAccessData}
                  page={page}
                  pageCount={pageCountAgent}
                  handlePageChange={handleAgentsPageChange}
                  offset={offset}
                  setOffset={setOffset}
                  agents={specialAccessData}
                  setAgents={setAgents}
                  setRoutes={setRoutes}
                  sub={true}
                />

              
              </Tab>
            ) : null} */}
          {/* </Tabs> */}
        </div>
      </section>

      <Modal
        className="add-mega-modal global-modal-scss"
        show={show1}
        onHide={handleClose1}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Special Access</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="multiselect-custom mt-5">
            <label className="custom-label">Special Access to</label>
            <Multiselect
              placeholder=""
              selectedValues={specialAccess?.map((item) => item?.name)}
              isObject={false}
              onKeyPressFn={function noRefCheck() {}}
              onRemove={function noRefCheck(selectedList, removedItem) {
                const selectedLeader = specialAccess.find(
                  (leader) => leader.name == removedItem
                );
                if (selectedLeader) {
                  const selectedId = selectedLeader._id;
                  const specialAccessExist = removeSpecialAccess?.find(
                    (leader) => leader == selectedId
                  );
                  if (!specialAccessExist) {
                    removeSpecialAccess.push(selectedId);
                  }
                  // Do whatever you need to with the selected id
                }
              }}
              onSearch={function noRefCheck() {}}
              onSelect={function noRefCheck(selectedList, selectedName) {
                const selectedLeader = godLeadersList.find(
                  (leader) => leader.name == selectedName
                );
                if (selectedLeader) {
                  const selectedId = selectedLeader._id;
                  const specialAccessExist = addSpecialAccess?.find(
                    (leader) => leader == selectedId
                  );
                  if (!specialAccessExist) {
                    addSpecialAccess.push(selectedId);
                  }
                  // Do whatever you need to with the selected id
                }
              }}
              customCloseIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M5.14307 12.8571L12.8574 5.14285"
                    stroke="black"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.8574 12.8571L5.14307 5.14285"
                    stroke="black"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              }
              options={godLeadersList?.map((item) => item?.name)}
            />
          </div>
          <div className="twice-btn">
            <button
              onClick={() => {
                handleClose1();
              }}
              className="btn-cancel"
            >
              Back
            </button>
            <button
              disabled={loader}
              onClick={editSpecialAccessList}
              className="btn-add"
            >
              {loader ? "Edit..." : "Edit"}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="add-mega-modal global-modal-scss"
        show={showMinerDiscount}
        onHide={handleCloseMinerDiscount}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Discount %</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="add-mega-div">
            <div className="option-field">
              <div className="material-textfield">
                <label>Discount % (0 - 50)</label>

                <input
                  type="number"
                  placeholder="Miner Discount (0 - 50 %)"
                  min="0"
                  max="50"
                  value={minerDiscount}
                  onChange={(e) => {
                    let value = Number(e.target.value);

                    if (value < 0) value = 0;
                    if (value > 50) value = 50;

                    setMinerDiscount(value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="twice-btn">
            <button
              className="btn-cancel"
              onClick={() => {
                setMinerDiscount("");
                handleCloseMinerDiscount();
              }}
            >
              Back
            </button>
            <button
              className="btn-add"
              onClick={() => {
                changeMinerDiscount();
              }}
            >
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="add-mega-modal global-modal-scss"
        show={show123}
        onHide={handleClose123}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Code Buying Limit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="add-mega-div">
            <div className="option-field">
              <div className="material-textfield">
                <input
                  type="number"
                  placeholder="Buying Limit (USD)"
                  value={minimumBuy}
                  onChange={(e) => setMinimumBuy(e.target.value)}
                />
                <label>Buying Limit (USD)</label>
              </div>
            </div>
          </div>
          <div className="twice-btn">
            <button className="btn-cancel" onClick={() => setMinimumBuy("")}>
              Back
            </button>
            <button
              className="btn-add"
              onClick={() => {
                changeMinimumBuy();
              }}
            >
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="add-mega-modal global-modal-scss walletaddrssmodal"
        show={showchange}
        onHide={handleClosechange}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Wallet Address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="walletaddrssmain">
            <p className="walletaddrsspara">Current Wallet Address</p>
            <h6 className="walletaddrsshead">{userDel?.walletAddress}</h6>
          </div>
          <div className="material-textfield">
            <input
              type="text"
              placeholder="Enter new wallet address"
              onChange={(e) => setNewWalletAddress(e.target.value)}
              value={newWalletAddress}
            />
            <label>New Wallet Address </label>
          </div>
          <div className="twice-btn">
            <button onClick={handleClosechange} className="btn-cancel">
              Cancel
            </button>
            <button
              className="btn-add"
              onClick={() => {
                handleClosechange();
                handleShowconfirmation();
              }}
            >
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="add-mega-modal global-modal-scss walletaddrssmodal"
        show={showconfirmation}
        onHide={handleCloseconfirmation}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="paraconfirm">
            Are you sure you want to change wallet address
          </p>
          <div className="twice-btn">
            <button onClick={handleCloseconfirmation} className="btn-cancel">
              Cancel
            </button>
            <button
              className="btn-add"
              onClick={() => {
                handleCloseconfirmation();
                handleShowchangesuccess();
              }}
            >
              Confirm
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="add-mega-modal global-modal-scss walletaddrssmodal"
        show={showchangesuccess}
        onHide={handleClosechangesuccess}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="ssuuccessmain">
            <img
              src="\assets\happy.svg"
              alt="successimg"
              className="successimg"
            />
            <p className="paraconfirm">Wallet address changed successfully</p>
            <button
              className="btn-okay w-100"
              onClick={handleClosechangesuccess}
            >
              Okay
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="global-modal-scss tokenslist-modal"
        show={show11}
        onHide={handleClose11}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Total Sold</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-tokendata">
            <div className="upper-twice-card">
              <div className="single-card">
                <p>Leader Name</p>
                <h6>{leaderscount?.name}</h6>
              </div>
              <div className="single-card">
                <p>Total Sold</p>
                <h6>~${formatTokenValue(leaderscount?.totalAmountInUsd)} </h6>
              </div>
            </div>
            <div className="bottom-twice">
              <div className="upper-head">
                <p>Token</p>
                <p>Amount</p>
              </div>
              {tokenData
                .map(({ key, name, img, decimals }) => ({
                  value: formatTokenValue(leaderscount?.[key], decimals),
                  name,
                  img,
                }))
                .filter(({ value }) => value !== null) // Remove zero values
                .map(({ name, value, img }) => (
                  <div className="bottom-tokens" key={name}>
                    <div className="left-token">
                      <img src={img} alt={name} className="img-fluid" />
                      <h6>{name}</h6>
                    </div>
                    <div className="right-token">
                      <p>
                        {value} {name}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className="twice-btn">
            <button
              className="btn-add w-100"
              style={{ flex: "0 0 100%" }}
              onClick={() => {
                handleClose11();
              }}
            >
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        className="global-modal-scss tokenslist-modal"
        show={show12}
        onHide={handleClose12}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Leader Earnings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-tokendata">
            <div className="upper-twice-card">
              <div className="single-card">
                <p>Leader Name</p>
                <h6>{leaderscount?.name}</h6>
              </div>
              <div className="single-card">
                <p>Total Earnings</p>
                <h6>~${formatTokenValue(leaderscount?.totalAmountEarned)} </h6>
              </div>
            </div>
            <div className="bottom-twice">
              <div className="upper-head">
                <p>Token</p>
                <p>Amount</p>
              </div>
              {tokenDataEarnings
                .map(({ key, name, img, decimals }) => ({
                  value: formatTokenValue(leaderscount?.[key], decimals),
                  name,
                  img,
                }))
                .filter(({ value }) => value !== null) // Remove zero values
                .map(({ name, value, img }) => (
                  <div className="bottom-tokens" key={name}>
                    <div className="left-token">
                      <img src={img} alt={name} className="img-fluid" />
                      <h6>{name}</h6>
                    </div>
                    <div className="right-token">
                      <p>
                        {value} {name}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className="twice-btn">
            <button
              className="btn-add w-100"
              style={{ flex: "0 0 100%" }}
              onClick={() => {
                handleClose12();
              }}
            >
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewDetail;
