import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { getRainPreSaleV3Contract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const CuurentRoundRainV3 = () => {
  const [balance, setBalance] = useState(null);
  const { account } = useWeb3React();
  const web3 = useWeb3();

  const tokenAddressRainV3 = Environment.RainPreSaleV3;
  const RainContractPresaleV3 = getRainPreSaleV3Contract(
    tokenAddressRainV3,
    web3
  );

  // const project = localStorage.getItem("project");

  const cuurentRoundRainV3 = useCallback(
    async (proj) => {
      if (proj == "RAIN") {
        try {
          const buy = await RainContractPresaleV3.methods
            .getRoundCount()
            .call();

          return buy;
        } catch (error) {
          console.log("dlsflasdfjlasdf", error);
          throw error;
        }
      }
    },
    [, RainContractPresaleV3, account, web3]
  );
  return { cuurentRoundRainV3: cuurentRoundRainV3 };
};
export default CuurentRoundRainV3;
