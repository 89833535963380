// export const Api_Url =
//   "http://ec2-18-236-118-29.us-west-2.compute.amazonaws.com:8000";
// export const Api_Url = "http://192.168.20.68:8001";
// export const Api_Url = "http://192.168.10.189:8000";
// export const Api_Url = "http://192.168.30.135:4000";

// export const Api_Url = "http://54.82.115.121:3000";

export const Api_Url = "https://prodapi.gems.vip";

// export const Api_Url =
//   "http://ec2-18-236-118-29.us-west-2.compute.amazonaws.com:8007";

// export const Api_Url = "https://prod-api.gems.vip";
export const Api_Url_Referral = "https://prodapi.gems.vip";
