import React, { useState, useEffect } from "react";
import "./datatable.scss";
import { Link } from "react-router-dom";
import tableup from "../../../../assets/tableup.svg";
import tabledown from "../../../../assets/tabledown.svg";
import { Accordion } from "react-bootstrap";
import axios from "axios";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactPaginate from "react-paginate";

const DataTable = ({
  setRoutes,
  setShow,
  agents,
  setAgents,
  pages,
  handlePageChange,
  page,
  pageCount,
  currentPage,
  GetAllRounds,
  getNextData,
  getPrevData,
  setUserDel,
  sub,
  setOrderDirection,
  setOrderField,
  setBlockUnblock,
  blockUnblock,
  tomiPrice,
  usdPrice,
  btcPrice,
  pepePrice,
  linkPrice,
  uniPrice,
  dopPrice,
  gemsPrice,
}) => {
  // const [usdPrice, setUsdPrice] = useState(0);
  const [rend, setRend] = useState(false);

  const [copy, setCopy] = useState(false);
  const [copy1, setCopy1] = useState(false);

  const [copy2, setCopy2] = useState(false);
  const [copy3, setCopy3] = useState(false);

  const textCopiedFun = (index) => {
    setCopy(index);
    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };

  const textCopiedFun1 = (index) => {
    setCopy1(index);
    setTimeout(() => {
      setCopy1(false);
    }, 1000);
  };

  const textCopiedFun2 = (index) => {
    setCopy2(index);
    setTimeout(() => {
      setCopy2(false);
    }, 1000);
  };

  const textCopiedFun3 = (index) => {
    setCopy3(index);
    setTimeout(() => {
      setCopy3(false);
    }, 1000);
  };

  function convertToTitleCase(input) {
    if (input) {
      let words = input
        .split("_")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );

      if (words[0] === "mini" || words[0] === "Mini") {
        return "Ambassador";
      }
      if (
        words[words.length - 1].toLowerCase() === "agent" &&
        (words[0] != "mini" || words[0] != "Mini")
      ) {
        words[words.length - 1] = "Leader";
      }
      if (words[0] === "super" || words[0] === "Super") {
        return "Super";
      } else if (words[0] !== "agent") {
        return words.join(" ");
      } else {
        return "Leader";
      }
    } else {
      return "";
    }
  }

  function convertDateFormat(input) {
    const date = new Date(input);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    return `${day}/${month}/${year}\n${hours}:${minutes} UTC`;
  }

  return (
    <>
      <section className="datatable">
        <div className="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>Email</th>
                <th>Wallet Address</th>
                <th>Referral Link</th>
                <th>Total Referrals</th>

                <th>Purchases</th>
                <th>Earnings</th>
                <th>Total Sales</th>
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody>
              {agents?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <p className="codepara">
                        {item?.referral[0]?.email && (
                          <>
                            <CopyToClipboard
                              text={item?.referral[0]?.email}
                              onCopy={(e) => textCopiedFun1(index)}
                            >
                              <span className="adadasdasdasdasdasdsad">
                                {item?.referral[0]?.email}
                                <svg
                                  style={{ cursor: "pointer" }}
                                  className={copy1 === index && "copy-button "}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15"
                                  height="16"
                                  viewBox="0 0 15 16"
                                  fill="none"
                                >
                                  <path
                                    d="M9.41377 8.49972L9.44015 10.9496C9.46213 12.9911 8.65431 13.8165 6.61276 13.8385L4.1629 13.8649C2.12135 13.8869 1.29594 13.0791 1.27396 11.0375L1.24757 8.58766C1.22559 6.54611 2.03342 5.7207 4.07496 5.69871L6.52482 5.67233C8.56637 5.65035 9.39178 6.45817 9.41377 8.49972Z"
                                    stroke="#77868B"
                                    stroke-width="1.2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M12.8759 4.96213L12.9023 7.41198C12.9243 9.45353 12.1165 10.2789 10.0749 10.3009L9.43329 10.3078L9.41382 8.49961C9.39183 6.45806 8.56642 5.65024 6.52487 5.67222L4.71664 5.69169L4.70973 5.05006C4.68775 3.00851 5.49557 2.1831 7.53712 2.16112L9.98698 2.13474C12.0285 2.11275 12.8539 2.92058 12.8759 4.96213Z"
                                    stroke="#77868B"
                                    stroke-width="1.2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </span>
                            </CopyToClipboard>
                            {copy1 === index && (
                              <p className="copy-text">Copied</p>
                            )}
                          </>
                        )}
                      </p>
                    </td>
                    <td>
                      <p className="codepara">
                        {item?.referralWalletAddress && (
                          <>
                            <CopyToClipboard
                              text={item?.referralWalletAddress}
                              onCopy={(e) => textCopiedFun(index)}
                            >
                              <span className="adadasdasdasdasdasdsad">
                                {item?.referralWalletAddress?.slice(0, 11)}
                                ...
                                {item?.referralWalletAddress?.slice(
                                  item?.referralWalletAddress?.length - 5,
                                  item?.referralWalletAddress?.length
                                )}{" "}
                                <svg
                                  style={{ cursor: "pointer" }}
                                  className={copy === index && "copy-button "}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15"
                                  height="16"
                                  viewBox="0 0 15 16"
                                  fill="none"
                                >
                                  <path
                                    d="M9.41377 8.49972L9.44015 10.9496C9.46213 12.9911 8.65431 13.8165 6.61276 13.8385L4.1629 13.8649C2.12135 13.8869 1.29594 13.0791 1.27396 11.0375L1.24757 8.58766C1.22559 6.54611 2.03342 5.7207 4.07496 5.69871L6.52482 5.67233C8.56637 5.65035 9.39178 6.45817 9.41377 8.49972Z"
                                    stroke="#77868B"
                                    stroke-width="1.2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M12.8759 4.96213L12.9023 7.41198C12.9243 9.45353 12.1165 10.2789 10.0749 10.3009L9.43329 10.3078L9.41382 8.49961C9.39183 6.45806 8.56642 5.65024 6.52487 5.67222L4.71664 5.69169L4.70973 5.05006C4.68775 3.00851 5.49557 2.1831 7.53712 2.16112L9.98698 2.13474C12.0285 2.11275 12.8539 2.92058 12.8759 4.96213Z"
                                    stroke="#77868B"
                                    stroke-width="1.2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </span>
                            </CopyToClipboard>
                            {copy === index && (
                              <p className="copy-text">Copied</p>
                            )}
                          </>
                        )}
                      </p>
                    </td>
                    <td>
                      {item?.referralWalletAddress ===
                        "0x0000000000000000000000000000000000000000" ||
                      item?.referral?.length == 0 ? (
                        ""
                      ) : (
                        <p className="codepara">
                          <CopyToClipboard
                            text={`gems.vip/${item?.referral[0]?.referralCode}`}
                            onCopy={(e) => textCopiedFun3(index)}
                          >
                            <p>
                              gems.vip/{item?.referral[0]?.referralCode}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className={copy3 === index && "copy-button "}
                                width="15"
                                height="16"
                                viewBox="0 0 15 16"
                                fill="none"
                              >
                                <path
                                  d="M9.41377 8.49972L9.44015 10.9496C9.46213 12.9911 8.65431 13.8165 6.61276 13.8385L4.1629 13.8649C2.12135 13.8869 1.29594 13.0791 1.27396 11.0375L1.24757 8.58766C1.22559 6.54611 2.03342 5.7207 4.07496 5.69871L6.52482 5.67233C8.56637 5.65035 9.39178 6.45817 9.41377 8.49972Z"
                                  stroke="#77868B"
                                  stroke-width="1.2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M12.8759 4.96213L12.9023 7.41198C12.9243 9.45353 12.1165 10.2789 10.0749 10.3009L9.43329 10.3078L9.41382 8.49961C9.39183 6.45806 8.56642 5.65024 6.52487 5.67222L4.71664 5.69169L4.70973 5.05006C4.68775 3.00851 5.49557 2.1831 7.53712 2.16112L9.98698 2.13474C12.0285 2.11275 12.8539 2.92058 12.8759 4.96213Z"
                                  stroke="#77868B"
                                  stroke-width="1.2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              {copy3 === index && (
                                <p className="copy-text">Copied</p>
                              )}
                            </p>
                          </CopyToClipboard>
                        </p>
                      )}
                    </td>
                    <td>{item?.totalReferrals}</td>

                    <td>{item?.dopPurchased?.toFixed(4)}</td>
                    <td>{item?.referralComissionUsd?.toFixed(4)}</td>

                    <td>{item?.totalSaleConverted?.toFixed(4)}</td>
                    {/* <td>
                      <div class="dropdown">
                        <button
                          class="dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img
                            src="\assets\more-icon.svg"
                            alt="img"
                            className="img-fluid"
                          />
                        </button>
                        <ul class="dropdown-menu">
                          <li>
                            <a
                              onClick={() => {
                                setRoutes(true);
                                setUserDel(item);
                                setBlockUnblock(item?.isDeleted);
                              }}
                              class="dropdown-item"
                              href="#"
                            >
                              <img
                                src="\assets\viewdetail.svg"
                                alt="img"
                                className="img-fluid"
                              />
                              View Details
                            </a>
                          </li>
                        </ul>
                      </div>
                    </td> */}
                  </tr>
                );
              })}
            </tbody>
          </table>

          {sub != true ? (
            <div className="footer-content mt-2">
              <div className="left-f">
                {/* <h6>SHOWING 1-10 OF 145</h6> */}
                {/* <h6>SHOWING 1-10 OF 145</h6> */}
              </div>
              <div className="right-f">
                <nav aria-label="Page navigation example">
                  <ul class="pagination">
                    <nav aria-label="Page navigation example">
                      <ul class="text-center">
                        <ReactPaginate
                          previousLabel={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                            >
                              <path
                                d="M10 13L5 8L10 3"
                                stroke="#8C8C8C"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          }
                          nextLabel={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                            >
                              <path
                                d="M5 13L10 8L5 3"
                                stroke="#8C8C8C"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          }
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={handlePageChange}
                          containerClassName="pagination"
                          activeClassName="active"
                          forcePage={page}
                        ></ReactPaginate>
                      </ul>
                    </nav>
                  </ul>
                </nav>
              </div>
            </div>
          ) : null}
        </div>
        <div className="mobiledesignss  d-none">
          {agents?.map((item, index) => {
            return (
              <div className="new-accordionmobile dsuygfdsyufgsdyuf d-none">
                <div className="upper-body">
                  <div className="inner-text">
                    <h6>Date and time</h6>
                    <p> {convertDateFormat(item?.createdAt)?.slice(0, 10)}</p>
                  </div>
                  <div className="inner-text">
                    <h6>Leader</h6>
                    <p>{item?.name}</p>
                  </div>
                  <div className="inner-text">
                    <h6>Role</h6>
                    <p>{convertToTitleCase(item?.role)}</p>
                  </div>
                  <div className="inner-text">
                    <h6>Wallet Address</h6>
                    {copy ? (
                      <p className="adadasdasdasdasdasdsad">
                        {item?.walletAddress?.slice(0, 11)}...
                        {item?.walletAddress?.slice(
                          item?.walletAddress?.length - 3,
                          item?.walletAddress?.length
                        )}
                        <svg
                          style={{ cursor: "pointer" }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          className=""
                        >
                          <path
                            d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </p>
                    ) : (
                      <>
                        {item?.walletAddress && (
                          <CopyToClipboard
                            text={item?.walletAddress}
                            onCopy={textCopiedFun2}
                          >
                            <p className="adadasdasdasdasdasdsad">
                              {item?.walletAddress?.slice(0, 11)}...
                              {item?.walletAddress?.slice(
                                item?.walletAddress?.length - 3,
                                item?.walletAddress?.length
                              )}
                              <svg
                                style={{ cursor: "pointer" }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                className=""
                              >
                                <path
                                  d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                                  stroke="white"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                                  stroke="white"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </p>
                          </CopyToClipboard>
                        )}
                      </>
                    )}
                  </div>
                  {/* <div className="inner-text">
                    <h6>Wallet Address</h6>
                    <p className="textright">
                      {item?.walletAddress?.slice(0, 11)}...
                      {item?.walletAddress?.slice(
                        item?.walletAddress?.length - 3,
                        item?.walletAddress?.length
                      )}
                      <img
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigator.clipboard.writeText(item?.walletAddress);
                        }}
                        src="\assets\copy.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </p>
                  </div> */}
                </div>
                <div className="bottom-body">
                  <Accordion>
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>
                        More Info{" "}
                        <img
                          src="\assets\arrow-down.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="inner-textt">
                          <p>Tokens Purchased</p>
                          {item?.dopPurchased ? (
                            <h6 className="textright">
                              {item?.dopPurchased?.toFixed(4)} Tokens
                            </h6>
                          ) : (
                            <h6 className="textright">{0} Tokens</h6>
                          )}
                        </div>
                        <div className="inner-textt">
                          <p>Leader Earnings</p>

                          <div className="twice-text">
                            <h6>
                              $
                              {(
                                item?.leaderEarningInUsdt +
                                usdPrice * item?.leaderEarningInEth +
                                dopPrice * item?.leaderEarningInDopToken +
                                btcPrice * item?.leaderEarningInWbtc +
                                item?.leaderEarningInUsdc +
                                pepePrice * item?.leaderEarningInPepe +
                                linkPrice * item?.leaderEarningInLink +
                                uniPrice * item?.leaderEarningInUni +
                                tomiPrice * item?.leaderEarningInTomi +
                                gemsPrice * item?.leaderEarningInGems
                              )?.toFixed(4)}
                            </h6>
                            <p>
                              (
                              {item?.leaderEarningInEth
                                ? item?.leaderEarningInEth?.toFixed(5)
                                : 0}{" "}
                              ETH +{" "}
                              {item?.leaderEarningInUsdt
                                ? item?.leaderEarningInUsdt?.toFixed(2)
                                : 0}{" "}
                              USDT +{" "}
                              {item?.leaderEarningInDopToken
                                ? item?.leaderEarningInDopToken?.toFixed(4)
                                : 0}{" "}
                              DOP +{" "}
                              {item?.leaderEarningInWbtc
                                ? item?.leaderEarningInWbtc?.toFixed(4)
                                : 0}{" "}
                              WBTC +{" "}
                              {item?.leaderEarningInUsdc
                                ? item?.leaderEarningInUsdc?.toFixed(2)
                                : 0}{" "}
                              USDC +{" "}
                              {item?.leaderEarningInPepe
                                ? item?.leaderEarningInPepe?.toFixed(2)
                                : 0}{" "}
                              PEPE +{" "}
                              {item?.leaderEarningInLink
                                ? item?.leaderEarningInLink?.toFixed(2)
                                : 0}{" "}
                              LINK +{" "}
                              {item?.leaderEarningInUni
                                ? item?.leaderEarningInUni?.toFixed(2)
                                : 0}{" "}
                              UNI +{" "}
                              {item?.leaderEarningInTomi
                                ? item?.leaderEarningInTomi?.toFixed(2)
                                : 0}{" "}
                              TOMI +{" "}
                              {item?.leaderEarningInGems
                                ? item?.leaderEarningInGems?.toFixed(2)
                                : 0}{" "}
                              GEMS)
                            </p>
                          </div>
                        </div>
                        {/* totalPurchaseUsdt */}
                        <div className="inner-textt">
                          <p>Total Purchases</p>
                          {usdPrice &&
                          (item?.purchaseInEth ||
                            item?.purchaseInTomi ||
                            item?.purchaseInUsdt ||
                            item?.purchaseInWbtc ||
                            item?.purchaseInUsdc ||
                            item?.purchaseInPepe ||
                            item?.purchaseInLink ||
                            item?.purchaseInUni ||
                            item?.purchaseInGems) ? (
                            <div className="twice-text">
                              <h6>
                                $
                                {(
                                  item?.purchaseInEth * usdPrice +
                                  item?.purchaseInUsdt +
                                  item?.purchaseInDopToken * dopPrice +
                                  item?.purchaseInWbtc * btcPrice +
                                  item?.purchaseInUsdc +
                                  item?.purchaseInPepe * pepePrice +
                                  item?.purchaseInLink * linkPrice +
                                  item?.purchaseInUni * uniPrice +
                                  item?.purchaseInTomi * tomiPrice +
                                  item?.purchaseInGems * gemsPrice
                                )?.toFixed(4)}
                              </h6>
                              <p>
                                ({item?.purchaseInEth?.toFixed(5)} ETH +{" "}
                                {item?.purchaseInUsdt?.toFixed(2)} USDT +{" "}
                                {item?.purchaseInDopToken?.toFixed(4)} DOP +{" "}
                                {item?.purchaseInWbtc?.toFixed(4)} WBTC +{" "}
                                {item?.purchaseInUsdc?.toFixed(2)} USDC +{" "}
                                {item?.purchaseInPepe?.toFixed(2)} PEPE +{" "}
                                {item?.purchaseInLink?.toFixed(2)} LINK +{" "}
                                {item?.purchaseInUni?.toFixed(2)} UNI +{" "}
                                {item?.purchaseInTomi?.toFixed(2)} TOMI +{" "}
                                {item?.purchaseInGems?.toFixed(2)} GEMS)
                              </p>
                            </div>
                          ) : (
                            <div className="twice-text">
                              <>
                                <h6>$0</h6>
                                <p>
                                  (0 ETH + 0 USDT + 0 WBTC + 0 USDC + 0 PEPE + 0
                                  LINK + 0 UNI + 0 TOMI + 0 GEMS)
                                </p>
                              </>
                            </div>
                          )}
                        </div>
                        {sub != true ? (
                          <div className="inner-textt">
                            <button
                              onClick={() => {
                                setRoutes(true);
                                setUserDel(item);
                                setBlockUnblock(item?.isDeleted);
                              }}
                              className="view-btn"
                            >
                              View Details
                            </button>
                          </div>
                        ) : null}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
              // <Accordion.Item key={index} eventKey={index}>
              //   <Accordion.Header>
              //     <div className="accheadermain">
              //       <p className="acctext">
              //         {convertDateFormat(item?.createdAt)?.slice(0, 10)}
              //       </p>
              //       <p className="acctext">{item?.name}</p>
              //       <p className="acctext">
              //         {convertToTitleCase(item?.role)}
              //       </p>
              //     </div>
              //   </Accordion.Header>
              //   <Accordion.Body>
              //     <div className="acctexts">
              //       <h6 className="textleft">Wallet Address</h6>
              //       <p className="textright">
              //         {item?.walletAddress?.slice(0, 11)}...
              //         {item?.walletAddress?.slice(
              //           item?.walletAddress?.length - 3,
              //           item?.walletAddress?.length
              //         )}
              //         <img
              //           style={{ cursor: "pointer" }}
              //           onClick={() => {
              //             navigator.clipboard.writeText(item?.walletAddress);
              //           }}
              //           src="\assets\copy.svg"
              //           alt="img"
              //           className="img-fluid"
              //         />
              //       </p>
              //     </div>
              //     <div className="acctexts">
              //       <h6 className="textleft">DOP Purchased</h6>
              //       <p className="textright">{item?.totalDop} DOP</p>
              //     </div>
              //     <div className="acctexts">
              //       <h6 className="textleft">Leader Earning</h6>
              //       <div className="twice-text">
              //         <h6>
              //           $
              //           {(
              //             item?.myEarningsEth * usdPrice +
              //             item?.myEarningsUsdt
              //           )?.toFixed(4)}
              //         </h6>
              //         <p>
              //           ({item?.myEarningsEth} ETH + {item?.myEarningsUsdt}{" "}
              //           USDT)
              //         </p>
              //       </div>
              //     </div>
              //     <div className="acctexts">
              //       <h6 className="textleft">Total Purchases</h6>
              //       <div className="twice-text">
              //         <h6>
              //           {(
              //             item?.totalEth * usdPrice +
              //             item?.totalUsdt
              //           )?.toFixed(4)}{" "}
              //         </h6>
              //         <p>
              //           ({item?.totalEth} ETH + {item?.totalUsdt} USDT){" "}
              //         </p>
              //       </div>
              //     </div>
              //     <div className="acctexts">
              //       <button
              //          onClick={() => {
              //           setRoutes(true);
              //           setUserDel(item);
              //         }}
              //         className="view-btn"
              //       >
              //         View Details
              //       </button>
              //     </div>
              //   </Accordion.Body>
              // </Accordion.Item>
            );
          })}
          {sub != true ? (
            <div className="footer-content mt-2">
              <div className="left-f">
                {/* <h6>SHOWING 1-10 OF 145</h6> */}
                {/* <h6>SHOWING 1-10 OF 145</h6> */}
              </div>
              <div className="right-f">
                <nav aria-label="Page navigation example">
                  <ul class="pagination">
                    <nav aria-label="Page navigation example">
                      <ul class="text-center">
                        <ReactPaginate
                          previousLabel={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                            >
                              <path
                                d="M10 13L5 8L10 3"
                                stroke="#8C8C8C"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          }
                          nextLabel={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                            >
                              <path
                                d="M5 13L10 8L5 3"
                                stroke="#8C8C8C"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          }
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={handlePageChange}
                          containerClassName="pagination"
                          activeClassName="active"
                          forcePage={page}
                        ></ReactPaginate>
                      </ul>
                    </nav>
                  </ul>
                </nav>
              </div>
            </div>
          ) : null}
        </div>
      </section>
    </>
  );
};

export default DataTable;
