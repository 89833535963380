import React, { useState } from "react";
import tableup from "../../../../assets/tableup.svg";
import tabledown from "../../../../assets/tabledown.svg";
import { Accordion, Modal } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";

const DataTable = ({
  tokenData,
  toggleStatus,
  setOrderDirection,
  setOrderField,
}) => {
  const [copy, setCopy] = useState(false);
  const textCopiedFun2 = () => {
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };

  return (
    <>
      <section className="datatable">
        <div className="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>
                  <div
                    className="parent-tag"
                    style={{ cursor: "pointer" }}

                  >
                    Date
                    <div className="filter-btn">
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#77868B"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#77868B"
                        />
                      </svg>
                    </div>
                  </div>
                </th>
                <th>
                  <div
                    className="parent-tag"
                    style={{ cursor: "pointer" }}

                  >
                    User

                  </div>
                </th>
                <th>
                  <div
                    className="parent-tag"
                    style={{ cursor: "pointer" }}

                  >
                    Activity

                  </div>
                </th>
              
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="twice-text">
                    <h6>03/09/2024</h6>
                    <p>5:14</p>
                  </div>
                </td>
                <td>John Doe</td>
                <td>Changed commission rate for this (User)</td>
              </tr>
            </tbody>
          </table>
          {/* <div className="footer-content">
            <div className="left-f">
            </div>
            <nav className="right-f">
              <ul className="pagination">
                <li className="page-item">
                  <button
                    onClick={() => getPrevData(currentPage)}
                    className="page-link arrowssss scsdsdfefssdvsdvsd"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none"><path d="M10 13L5 8L10 3" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                  </button>
                </li>
                {pages?.map((item, index) => {
                  return (
                    <li key={index} className="page-item cursor-pointer">
                      <p
                        className={
                          "page-link " +
                          (index + 1 === parseInt(currentPage)
                            ? "active-pag"
                            : "")
                        }
                        onClick={() => GetAllRounds(index + 1)}
                        style={{ fontSize: "13px !important" }}
                      >
                        {index + 1}
                      </p>
                    </li>
                  );
                })}
                <li className="page-item">
                  <button
                    onClick={() => getNextData(currentPage)}
                    className="page-link arrowssss"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none"><path d="M5 13L10 8L5 3" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                  </button>
                </li>
              </ul>
            </nav>
          </div> */}
        </div>
        <div className="accmblviewfdsasdf d-none">
          <div className="main-twice-text">
            <h6 className="accmblviewhead">Date</h6>
          </div>
          <Accordion>
            <Accordion.Item>
              <Accordion.Header>
                <div className="accheadermain">
                  <div className="twice-text">
                    <h6 style={{color: "#000"}}>03/09/2024</h6>
                    <p style={{color: "#000"}}>5:14</p>
                  </div>
               
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="acctexts">
                  <h6 className="textleft">User</h6>
                  <p className="textright">John Doe</p>
                </div>
                <div className="acctexts">
                  <h6 className="textleft">Activity</h6>
                  <p className="textright" style={{maxWidth: "150px", textAlign: "right"}}>Changed commission rate for this (User)</p>
                </div>
               
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          {/* <div className="footer-content">
            <div className="left-f">
            </div>
            <nav className="right-f">
              <ul className="pagination">
                <li className="page-item">
                  <button
                    onClick={() => getPrevData(currentPage)}
                    className="page-link arrowssss scsdsdfefssdvsdvsd"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none"><path d="M10 13L5 8L10 3" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></svg>

                  </button>
                </li>
                {pages?.map((item, index) => {
                  return (
                    <li key={index} className="page-item cursor-pointer">
                      <p
                        className={
                          "page-link " +
                          (index + 1 === parseInt(currentPage)
                            ? "active-pag"
                            : "")
                        }
                        onClick={() => GetAllRounds(index + 1)}
                        style={{ fontSize: "13px !important" }}
                      >
                        {index + 1}
                      </p>
                    </li>
                  );
                })}
                <li className="page-item">
                  <button
                    onClick={() => getNextData(currentPage)}
                    className="page-link arrowssss"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none"><path d="M5 13L10 8L5 3" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></svg>

                  </button>
                </li>
              </ul>
            </nav>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default DataTable;
