import React, { useState, useEffect } from "react";
import "./datatable.scss";
import { Link } from "react-router-dom";
import tableup from "../../../../assets/tableup.svg";
import tabledown from "../../../../assets/tabledown.svg";
import { Accordion, Modal } from "react-bootstrap";

import axios from "axios";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactPaginate from "react-paginate";
import { Api_Url } from "../../../../utils/ApiUrl";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
const DataTable = ({
  project,
  setRoutes,
  setShow,
  agents,
  leadersLoader,
  setAgents,
  pages,
  handlePageChange,
  page,
  pageCount,
  currentPage,
  GetAllRounds,
  setUserDel,
  sub,
  setOrderDirection,
  setOrderField,
  setBlockUnblock,
  blockUnblock,
  tomiPrice,
  usdPrice,
  btcPrice,
  pepePrice,
  linkPrice,
  uniPrice,
  dopPrice,
  gemsPrice,

  pagination,
  tok,

  searchBy,
  searchQuery,
  startDate,
  endDate,
  privateSaleFilter,
  selectedLeaders,
  selectedRoles,

  activeFilter,
  premiumFilter,
  orderDirection,
  orderField,
}) => {
  // const [usdPrice, setUsdPrice] = useState(0);
  const [rend, setRend] = useState(false);

  // useEffect(() => {
  //   axios
  //     .get("https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD")
  //     .then((res) => {
  //       setUsdPrice(res?.data?.USD);
  //       // setEthUsd(res.data);
  //     });
  // }, []);
  const [modalDetail, setModalDetail] = useState({});

  const [claimsCSVData, setClaimsCSVData] = useState([]);
  const [claimsCSVDataHeaders, setClaimsCSVDataHeaders] = useState([]);
  const [data, setexceldata] = useState([]);

  const [showbtn, setShowBtn] = useState(true);
  const [loading, setLoading] = useState("");

  const [copy, setCopy] = useState(false);

  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleClose1 = () => {
    setShow1(false);
  };

  const handleClose2 = () => {
    setShow2(false);
  };
  const textCopiedFun = (index) => {
    setCopy(index);

    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };
  function convertToTitleCase(input) {
    if (input) {
      let words = input
        .split("_")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );

      if (words[0] === "mini" || words[0] === "Mini") {
        return "Ambassador";
      }
      if (
        words[words.length - 1].toLowerCase() === "agent" &&
        (words[0] != "mini" || words[0] != "Mini")
      ) {
        words[words.length - 1] = "Leader";
      }
      if (words[0] === "super" || words[0] === "Super") {
        return "Super";
      } else if (words[0] !== "agent") {
        return words.join(" ");
      } else {
        return "Leader";
      }
    } else {
      return "";
    }
  }

  function convertDateFormat(input) {
    const date = new Date(input);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    return `${day}/${month}/${year}\n${hours}:${minutes} UTC`;
  }

  const toastErrorFunc = () => {
    toast.error("No data found to export");
  };
  const getAllClaimsData = async () => {
    console.log("export csv: ", project);

    setLoading(true);
    setShowBtn(false);
    var config = {
      method: "post",
      url: `${Api_Url}/users/admin/leaders`,
      data: {
        limit: 5000,
        offset: 1,
        ...(activeFilter !== null && { isActive: activeFilter }),

        ...(selectedLeaders?.length > 0 && { nameFilter: selectedLeaders }),
        ...(selectedRoles?.length > 0 && { roleFilter: selectedRoles }),
        ...(premiumFilter !== null && { isPremium: premiumFilter }),
        ...(searchQuery &&
          searchBy == "walletAddress" && {
            walletAddressFilter: searchQuery,
          }),
        ...(searchQuery &&
          searchBy == "accessCode" && { accessCodeFilter: searchQuery }),

        // walletAddressFilter:,
        // accessCodeFilter:,
        ...(privateSaleFilter?.length > 0 && {
          privateSaleFilter: privateSaleFilter,
        }),

        ...(startDate && {
          startDate: startDate,
        }),

        ...(endDate && {
          endDate: endDate,
        }),

        orderField: orderField,
        orderDirection: orderDirection,
      },
      headers: {
        Authorization: "Bearer " + tok,
      },
    };

    axios(config)
      .then(function (response) {
        const resData = response?.data?.data?.data?.leaders;

        const dataForCsv = resData?.map((item) => {
          let name = item?.name;
          let role =
            item?.role === "mini_agent"
              ? "Ambassador"
              : item?.role === "god_agent"
              ? "God Leader"
              : item?.role === "super_agent"
              ? "Super Leader"
              : item?.role === "agent"
              ? "Leader"
              : item?.role === "mega_agent"
              ? "Mega Leader"
              : "";
          let email = item?.email;

          let code = item?.accessCode;
          let commission = item?.commission;
          let Purchases = item?.directSales;
          let Earning = item?.totalAmountEarned;
          let myEarnings = item?.myEarnings;
          let totalSales = item?.directAmountInUsd;
          let DirectSales = item?.directAmountInUsd;
          let indirectSales = item?.indirectAmountInUsd;
          let amount = item?.totalDop;
          return {
            name: name,
            role: role,
            email: email,
            code: code,
            commission: commission,
            Purchases: Purchases,
            Earning: Earning,
            myEarnings: myEarnings,
            totalSales: totalSales,
            DirectSales: DirectSales,
            indirectSales: indirectSales,
            amount: amount,
          };
        });
        const headers = [
          { label: "name", key: "name" },
          { label: "role", key: "role" },
          { label: "leadersEmail", key: "email" },

          { label: "code", key: "code" },
          { label: "commission", key: "commission" },
          { label: "Purchases", key: "Purchases" },
          { label: "Earning", key: "Earning" },
          { label: "myEarnings", key: "myEarnings" },
          { label: "totalSales", key: "totalSales" },
          { label: "DirectSales", key: "DirectSales" },
          { label: "indirectSales", key: "indirectSales" },
          { label: "amount", key: "amount" },
        ];
        setClaimsCSVData(dataForCsv);
        setClaimsCSVDataHeaders(headers);

        setLoading(false);
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          // localStorage.removeItem("accessToken");
          // history.push("/");
        }
        setLoading(false);
      });
  };

  const tokenData = [
    { key: "totalEthDirect", name: "ETH", img: "/assets/eth.svg", decimals: 5 },
    {
      key: "totalUsdtDirect",
      name: "USDT",
      img: "/assets/usdt.svg",
      decimals: 2,
    },
    {
      key: "totalUsdcDirect",
      name: "USDC",
      img: "/assets/usdc.svg",
      decimals: 2,
    },
    {
      key: "totalGemsDirect",
      name: "GEMS",
      img: "/assets/gems.svg",
      decimals: 1,
    },
    {
      key: "totalWbtcDirect",
      name: "WBTC",
      img: "/assets/wbtc.svg",
      decimals: 6,
    },
    {
      key: "totalLinkDirect",
      name: "LINK",
      img: "/assets/link.svg",
      decimals: 2,
    },
    {
      key: "totalTomiDirect",
      name: "TOMI",
      img: "/assets/tomi.svg",
      decimals: 2,
    },
    {
      key: "totalPepeDirect",
      name: "PEPE",
      img: "/assets/pepe.svg",
      decimals: 2,
    },
    { key: "totalUniDirect", name: "UNI", img: "/assets/uni.svg", decimals: 2 },
    {
      key: "totalDopTokenDirect",
      name: "DOP",
      img: "/assets/dop.svg",
      decimals: 2,
    },
  ];

  const tokenDataEarnings = [
    { key: "myEarningsEth", name: "ETH", img: "/assets/eth.svg", decimals: 5 },
    {
      key: "myEarningsUsdt",
      name: "USDT",
      img: "/assets/usdt.svg",
      decimals: 2,
    },
    {
      key: "myEarningsUsdc",
      name: "USDC",
      img: "/assets/usdc.svg",
      decimals: 2,
    },
    {
      key: "myEarningsGems",
      name: "GEMS",
      img: "/assets/gems.svg",
      decimals: 1,
    },
    {
      key: "myEarningsWbtc",
      name: "WBTC",
      img: "/assets/wbtc.svg",
      decimals: 6,
    },
    {
      key: "myEarningsLink",
      name: "LINK",
      img: "/assets/link.svg",
      decimals: 2,
    },
    {
      key: "myEarningsTomi",
      name: "TOMI",
      img: "/assets/tomi.svg",
      decimals: 2,
    },
    {
      key: "myEarningsPepe",
      name: "PEPE",
      img: "/assets/pepe.svg",
      decimals: 2,
    },
    { key: "myEarningsUni", name: "UNI", img: "/assets/uni.svg", decimals: 2 },
    {
      key: "myEarningsDopToken",
      name: "DOP",
      img: "/assets/dop.svg",
      decimals: 2,
    },
  ];

  function formatTokenValue(tokenValue, decimals) {
    const num = parseFloat(tokenValue);
    console.log("number incoming: ", num);

    if (isNaN(num) || num === 0) return null; // Hide zero values

    console.log("number calculatiing: ", num);

    if (num >= 1000000) {
      return (num / 1000000)?.toFixed(2) + "M";
    } else if (num >= 1000) {
      console.log("number in thousands: ", num);

      return (num / 1000)?.toFixed(2) + "K";
    } else {
      console.log("number in else: ", num);

      return num?.toFixed(decimals); // Apply specific decimal formatting
    }
  }

  return (
    <>
      <section className="datatable">
        {/* {agents?.length != 0 && (
          <div className="mb-3">
            <div className="upperbtn">
              {showbtn && (
                <button onClick={getAllClaimsData} className="mygreybtn">
                  export
                </button>
              )}
              {loading ? (
                <button className="mygreybtn">
                  <div className="text-center">
                    <div class="d-flex align-items-center justify-content-center">
                      <div class="spinner-border text-info" role="status">
                        <span class="sr-only"></span>
                      </div>
                    </div>
                  </div>
                </button>
              ) : (
                <>
                  {claimsCSVData && claimsCSVData.length > 0 && (
                    <CSVLink
                      data={claimsCSVData}
                      headers={claimsCSVDataHeaders}
                      filename={"Leaders_data.csv"}
                      className="mygreybtn"
                    >
                      Export CSV
                    </CSVLink>
                  )}
                </>
              )}
            </div>
          </div>
        )} */}
        <div
          className="table-responsive foroverflowvisibleclasss"
          // style={{ minHeight: "60vh" }}
        >
          <table class="table">
            <thead>
              <tr>
                <th>
                  <div className="parent-tag">
                    Date/Time{" "}
                    <div
                      className="filter-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setOrderField("createdAt");
                        setOrderDirection((prev) =>
                          prev == -1 ? 1 : prev == 1 ? -1 : -1
                        );
                      }}
                    >
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#77868B"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#77868B"
                        />
                      </svg>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Name/Wallet{" "}
                    <div
                      className="filter-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setOrderField("name");
                        setOrderDirection((prev) =>
                          prev == -1 ? 1 : prev == 1 ? -1 : -1
                        );
                      }}
                    >
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#77868B"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#77868B"
                        />
                      </svg>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Leader's Email{" "}
                    {/* <div className="filter-btn">
                      <img src={tableup} alt="img" className="img-fluid" />
                      <img src={tabledown} alt="img" className="img-fluid" />
                    </div> */}
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Role{" "}
                    {/* <div className="filter-btn">
                      <img src={tableup} alt="img" className="img-fluid" />
                      <img src={tabledown} alt="img" className="img-fluid" />
                    </div> */}
                  </div>
                </th>

                <th>
                  <div className="parent-tag">
                    Plan
                    <div
                      className="filter-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setOrderField("UserPremiumPlan");
                        setOrderDirection((prev) =>
                          prev == -1 ? 1 : prev == 1 ? -1 : -1
                        );
                      }}
                    >
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#77868B"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#77868B"
                        />
                      </svg>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Tokens
                    <div
                      className="filter-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setOrderField("totalDopDirect");
                        setOrderDirection((prev) =>
                          prev == -1 ? 1 : prev == 1 ? -1 : -1
                        );
                      }}
                    >
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#77868B"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#77868B"
                        />
                      </svg>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Leader Earning{" "}
                    <div
                      className="filter-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setOrderField("totalAmountEarned");
                        setOrderDirection((prev) =>
                          prev == -1 ? 1 : prev == 1 ? -1 : -1
                        );
                      }}
                    >
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#77868B"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#77868B"
                        />
                      </svg>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Total Purchases{" "}
                    <div
                      className="filter-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setOrderField("totalAmountInUsd");
                        setOrderDirection((prev) =>
                          prev == -1 ? 1 : prev == 1 ? -1 : -1
                        );
                      }}
                    >
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#77868B"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#77868B"
                        />
                      </svg>
                    </div>
                  </div>
                </th>
                {sub != true ? <th>Action</th> : null}
              </tr>
            </thead>
            <tbody>
              {agents?.length > 0 ? (
                agents?.map((item, index) => {
                  // let totalDirectAmount =
                  //   item?.totalEthDirect * usdPrice +
                  //   item?.totalUsdtDirect +
                  //   (item?.totalDopTokenDirect || 0) * dopPrice +
                  //   item?.totalWbtcDirect * btcPrice +
                  //   item?.totalUsdcDirect +
                  //   (item?.totalPepeDirect || 0) * pepePrice +
                  //   (item?.totalLinkDirect || 0) * linkPrice +
                  //   (item?.totalUniDirect || 0) * uniPrice +
                  //   (item?.totalTomiDirect || 0) * tomiPrice +
                  //   (item?.totalGemsDirect || 0) * gemsPrice;
                  let totalEarningsAmount =
                    item?.myEarningsEth * usdPrice +
                    item?.myEarningsUsdt +
                    item?.myEarningsUsdc +
                    item?.myEarningsWbtc * btcPrice +
                    (item?.myEarningsPepe || 0) * pepePrice +
                    (item?.myEarningsUni || 0) * uniPrice +
                    (item?.myEarningsLink || 0) * linkPrice +
                    (item?.myEarningsDopToken || 0) * dopPrice +
                    (item?.myEarningsTomi || 0) * tomiPrice +
                    (item?.myEarningsGems || 0) * gemsPrice;

                  return (
                    <tr key={index}>
                      <td>
                        <div className="twice-text">
                          <h6>
                            {convertDateFormat(item?.createdAt)?.slice(0, 10)}
                          </h6>
                          <p>
                            {convertDateFormat(item?.createdAt)?.slice(12, 20)}
                          </p>
                        </div>
                      </td>
                      <td>
                        {item?.name}
                        <div className="walletaddresspara">
                          <p>
                            {copy ? (
                              <span className="adadasdasdasdasdasdsad">
                                {item?.walletAddress?.slice(0, 11)}...
                                {item?.walletAddress?.slice(
                                  item?.walletAddress?.length - 3,
                                  item?.walletAddress?.length
                                )}{" "}
                                <svg
                                  style={{ cursor: "pointer" }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  className={copy === index && "copy-button "}
                                >
                                  <path
                                    d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                                    stroke="#77868B"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                                    stroke="#77868B"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                                {copy === index && (
                                  <p className="copy-text">Copied</p>
                                )}
                              </span>
                            ) : (
                              <>
                                {item?.walletAddress && (
                                  <>
                                    <CopyToClipboard
                                      text={item?.walletAddress}
                                      onCopy={(e) => textCopiedFun(index)}
                                    >
                                      <span className="adadasdasdasdasdasdsad">
                                        {item?.walletAddress?.slice(0, 11)}...
                                        {item?.walletAddress?.slice(
                                          item?.walletAddress?.length - 3,
                                          item?.walletAddress?.length
                                        )}{" "}
                                        <svg
                                          style={{ cursor: "pointer" }}
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                          className={
                                            copy === index && "copy-button "
                                          }
                                        >
                                          <path
                                            d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                                            stroke="#77868B"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <path
                                            d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                                            stroke="#77868B"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </svg>
                                      </span>
                                    </CopyToClipboard>
                                    {copy === index && (
                                      <p className="copy-text">Copied</p>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </p>
                        </div>
                      </td>
                      <td>{item?.email}</td>
                      <td>{convertToTitleCase(item?.role)}</td>

                      <td>{item?.UserPremiumPlan ? "Premium" : "Basic"} </td>
                      <td>
                        {parseFloat(
                          (item?.totalDop || 0)?.toFixed(2)
                        )?.toLocaleString()}{" "}
                        {item?.privateSale}
                      </td>

                      <td>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          $
                          {totalEarningsAmount > 0 ? (
                            <>
                              {formatTokenValue(totalEarningsAmount, 2)}
                              <div class="dropdown dropdownnodesminers">
                                <button
                                  class="dropdown-toggle"
                                  type="button"
                                  id="dropdownMenuButton1"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="19"
                                    viewBox="0 0 18 19"
                                    fill="none"
                                  >
                                    <path
                                      d="M9 17.249C13.1421 17.249 16.5 13.8912 16.5 9.74902C16.5 5.60689 13.1421 2.24902 9 2.24902C4.85786 2.24902 1.5 5.60689 1.5 9.74902C1.5 13.8912 4.85786 17.249 9 17.249Z"
                                      stroke="#77868B"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M9 12.749V9.74902"
                                      stroke="#77868B"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M9 6.74902H9.00833"
                                      stroke="#77868B"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </button>
                                <ul
                                  class="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton1"
                                >
                                  <div className="py-2">
                                    {item?.myEarningsEth != 0 ? (
                                      <div className="twice">
                                        <p>ETH</p>
                                        <h6>
                                          {item?.myEarningsEth
                                            ? parseFloat(
                                                item.myEarningsEth
                                              ).toFixed(5)
                                            : "0"}
                                        </h6>
                                      </div>
                                    ) : null}
                                    {item?.myEarningsUsdt != 0 ? (
                                      <div className="twice">
                                        <p>USDT</p>
                                        <h6>
                                          {item?.myEarningsUsdt
                                            ? parseFloat(
                                                item.myEarningsUsdt
                                              ).toFixed(2)
                                            : "0"}
                                        </h6>
                                      </div>
                                    ) : null}
                                    {item?.myEarningsUsdc != 0 ? (
                                      <div className="twice">
                                        <p>USDC</p>
                                        <h6>
                                          {item?.myEarningsUsdc
                                            ? parseFloat(
                                                item.myEarningsUsdc
                                              ).toFixed(2)
                                            : "0"}
                                        </h6>
                                      </div>
                                    ) : null}
                                    {/* {item?.totalWbtc != 0 ? (
                                  <div className="twice">
                                    <p>WBTC</p>
                                    <h6>
                                      {item?.totalWbtc
                                        ? parseFloat(item.totalWbtc).toFixed(6)
                                        : "0"}
                                    </h6>
                                  </div>
                                ) : null} */}
                                    {item?.totalGems != 0 ? (
                                      <div className="twice">
                                        <p>GEMS</p>
                                        <h6>
                                          {item?.totalGems
                                            ? parseFloat(
                                                item.totalGems
                                              ).toFixed(1)
                                            : "0"}
                                        </h6>
                                      </div>
                                    ) : null}
                                  </div>

                                  <a
                                    style={{
                                      color: "#329879",
                                      justifyContent: "center",
                                    }}
                                    onClick={() => {
                                      setModalDetail(item);
                                      setShow1(true);
                                    }}
                                  >
                                    View All
                                  </a>
                                </ul>
                              </div>
                            </>
                          ) : (
                            "0"
                          )}
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          $
                          {item?.directAmountInUsd > 0 ? (
                            <>
                              {formatTokenValue(item?.directAmountInUsd, 2)}
                              <div class="dropdown dropdownnodesminers">
                                <button
                                  class="dropdown-toggle"
                                  type="button"
                                  id="dropdownMenuButton1"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="19"
                                    viewBox="0 0 18 19"
                                    fill="none"
                                  >
                                    <path
                                      d="M9 17.249C13.1421 17.249 16.5 13.8912 16.5 9.74902C16.5 5.60689 13.1421 2.24902 9 2.24902C4.85786 2.24902 1.5 5.60689 1.5 9.74902C1.5 13.8912 4.85786 17.249 9 17.249Z"
                                      stroke="#77868B"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M9 12.749V9.74902"
                                      stroke="#77868B"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M9 6.74902H9.00833"
                                      stroke="#77868B"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </button>
                                <ul
                                  class="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton1"
                                >
                                  <div className="py-2">
                                    {item?.totalEthDirect != 0 ? (
                                      <div className="twice">
                                        <p>ETH</p>
                                        <h6>
                                          {item?.totalEthDirect
                                            ? parseFloat(
                                                item.totalEthDirect
                                              ).toFixed(5)
                                            : "0"}
                                        </h6>
                                      </div>
                                    ) : null}
                                    {item?.totalUsdtDirect != 0 ? (
                                      <div className="twice">
                                        <p>USDT</p>
                                        <h6>
                                          {item?.totalUsdtDirect
                                            ? parseFloat(
                                                item.totalUsdtDirect
                                              ).toFixed(2)
                                            : "0"}
                                        </h6>
                                      </div>
                                    ) : null}
                                    {item?.totalUsdcDirect != 0 ? (
                                      <div className="twice">
                                        <p>USDC</p>
                                        <h6>
                                          {item?.totalUsdcDirect
                                            ? parseFloat(
                                                item.totalUsdcDirect
                                              ).toFixed(2)
                                            : "0"}
                                        </h6>
                                      </div>
                                    ) : null}
                                    {/* {item?.totalWbtc != 0 ? (
                                  <div className="twice">
                                    <p>WBTC</p>
                                    <h6>
                                      {item?.totalWbtc
                                        ? parseFloat(item.totalWbtc).toFixed(6)
                                        : "0"}
                                    </h6>
                                  </div>
                                ) : null} */}
                                    {item?.totalGems != 0 ? (
                                      <div className="twice">
                                        <p>GEMS</p>
                                        <h6>
                                          {item?.totalGems
                                            ? parseFloat(
                                                item.totalGems
                                              ).toFixed(1)
                                            : "0"}
                                        </h6>
                                      </div>
                                    ) : null}
                                  </div>

                                  <a
                                    style={{
                                      color: "#329879",
                                      justifyContent: "center",
                                    }}
                                    onClick={() => {
                                      setModalDetail(item);
                                      setShow1(true);
                                    }}
                                  >
                                    View All
                                  </a>
                                </ul>
                              </div>
                            </>
                          ) : (
                            "0"
                          )}
                        </div>
                      </td>
                      {sub != true ? (
                        <td>
                          <div class="dropdown">
                            <button
                              class="dropdown-toggle"
                              type="button"
                              onClick={() => {
                                setRoutes(true);
                                setUserDel(item);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17"
                                height="16"
                                viewBox="0 0 17 16"
                                fill="none"
                              >
                                <path
                                  d="M3.16683 8.66669L11.2802 8.66669L7.5535 12.3934L8.50016 13.3334L13.8335 8.00002L8.50016 2.66669L7.56016 3.60669L11.2802 7.33335L3.16683 7.33335L3.16683 8.66669Z"
                                  fill="#02171D"
                                />
                              </svg>
                            </button>
                          </div>
                        </td>
                      ) : null}
                    </tr>
                  );
                })
              ) : leadersLoader ? (
                <tr>
                  <td colSpan="12" className="text-center">
                    <div className="text-center">
                      <div class="d-flex align-items-center justify-content-center">
                        <div class="spinner-border text-info" role="status">
                          <span class="sr-only"></span>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : (
                <tr>
                  <td colSpan="10" className="text-center">
                    <div className="text-center">
                      <h6
                        className=""
                        style={{
                          color: "#000",
                          textAlign: "center",
                          marginTop: 60,
                        }}
                      >
                        No data found!
                      </h6>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {pagination ? (
          <div className="footer-content mblviewdisplaynone mt-2">
            <div className="left-f">
              {/* <h6>SHOWING 1-10 OF 145</h6> */}
              {/* <h6>SHOWING 1-10 OF 145</h6> */}
            </div>
            <div className="right-f">
              <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <nav aria-label="Page navigation example">
                    <ul class="text-center">
                      <ReactPaginate
                        previousLabel={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="16"
                            viewBox="0 0 17 16"
                            fill="none"
                          >
                            <path
                              d="M7.88904 1.93555L5.38763 1.93555L1.99459 7.99682L4.496 7.99682L7.88904 1.93555Z"
                              fill="#329879"
                            />
                            <path
                              d="M7.88904 14.0703L5.38763 14.0703L1.99459 8.00904L3.57108 6.96117L4.496 8.00904L7.88904 14.0703Z"
                              fill="#329879"
                            />
                            <path
                              d="M8.01655 6.85742H14.9616L13.6869 9.14313H6.74182L8.01655 6.85742Z"
                              fill="#329879"
                            />
                          </svg>
                        }
                        nextLabel={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="16"
                            viewBox="0 0 17 16"
                            fill="none"
                          >
                            <path
                              d="M9.11108 1.93555L11.6125 1.93555L15.0055 7.99682L12.5041 7.99682L9.11108 1.93555Z"
                              fill="#329879"
                            />
                            <path
                              d="M9.11108 14.0703L11.6125 14.0703L15.0055 8.00904L13.429 6.96117L12.5041 8.00904L9.11108 14.0703Z"
                              fill="#329879"
                            />
                            <path
                              d="M3.31318 6.85742H10.2582L8.98351 9.14313H2.03845L3.31318 6.85742Z"
                              fill="#329879"
                            />
                          </svg>
                        }
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                        containerClassName="pagination"
                        activeClassName="active"
                        forcePage={page}
                      ></ReactPaginate>
                    </ul>
                  </nav>
                </ul>
              </nav>
            </div>
          </div>
        ) : null}
        <div className="mobiledesignss  d-none">
          {agents?.length > 0 ? (
            agents?.map((item, index) => {
              return (
                <div className="new-accordionmobile dsuygfdsyufgsdyuf d-none">
                  <div className="upper-body">
                    <div className="inner-text">
                      <h6>Date and time</h6>
                      <p> {convertDateFormat(item?.createdAt)?.slice(0, 10)}</p>
                    </div>
                    <div className="inner-text">
                      <h6>Leader</h6>
                      <p>{item?.name}</p>
                    </div>
                    <div className="inner-text">
                      <h6>Leader's Email</h6>
                      <p>{item?.email}</p>
                    </div>
                    <div className="inner-text">
                      <h6>Role</h6>
                      <p>{convertToTitleCase(item?.role)}</p>
                    </div>

                    <div className="inner-text">
                      <h6>Wallet Address</h6>
                      {copy ? (
                        <p className="adadasdasdasdasdasdsad">
                          {item?.walletAddress?.slice(0, 11)}...
                          {item?.walletAddress?.slice(
                            item?.walletAddress?.length - 3,
                            item?.walletAddress?.length
                          )}
                          <svg
                            style={{ cursor: "pointer" }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            className=""
                          >
                            <path
                              d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                              stroke="#77868B"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                              stroke="#77868B"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </p>
                      ) : (
                        <>
                          {item?.walletAddress && (
                            <CopyToClipboard
                              text={item?.walletAddress}
                              onCopy={(e) => textCopiedFun(index)}
                            >
                              <p className="adadasdasdasdasdasdsad">
                                {item?.walletAddress?.slice(0, 11)}...
                                {item?.walletAddress?.slice(
                                  item?.walletAddress?.length - 3,
                                  item?.walletAddress?.length
                                )}
                                <svg
                                  style={{ cursor: "pointer" }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  className=""
                                >
                                  <path
                                    d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                                    stroke="#77868B"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                                    stroke="#77868B"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </p>
                            </CopyToClipboard>
                          )}
                        </>
                      )}
                    </div>
                    {/* <div className="inner-text">
                    <h6>Wallet Address</h6>
                    <p className="textright">
                      {item?.walletAddress?.slice(0, 11)}...
                      {item?.walletAddress?.slice(
                        item?.walletAddress?.length - 3,
                        item?.walletAddress?.length
                      )}
                      <img
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigator.clipboard.writeText(item?.walletAddress);
                        }}
                        src="\assets\copy.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </p>
                  </div> */}
                  </div>
                  <div className="bottom-body">
                    <Accordion>
                      <Accordion.Item eventKey={index}>
                        <Accordion.Header>
                          More Info{" "}
                          <img
                            src="\assets\arrow-down.svg"
                            alt="img"
                            className="img-fluid"
                          />
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="inner-textt">
                            <p>Tokens Purchased</p>

                            <h6 className="textright">
                              {(item?.totalDop || 0)?.toFixed(4)}{" "}
                              {item?.privateSale}
                            </h6>
                          </div>
                          <div className="inner-textt">
                            <p>Leader Earnings</p>

                            {usdPrice ? (
                              <div className="twice-text">
                                <>
                                  <h6>
                                    $
                                    {(
                                      item?.myEarningsEth * usdPrice +
                                      item?.myEarningsUsdt +
                                      item?.myEarningsUsdc +
                                      item?.myEarningsWbtc * btcPrice +
                                      (item?.myEarningsPepe || 0) * pepePrice +
                                      (item?.myEarningsUni || 0) * uniPrice +
                                      (item?.myEarningsLink || 0) * linkPrice +
                                      (item?.myEarningsDopToken || 0) *
                                        dopPrice +
                                      (item?.myEarningsTomi || 0) * tomiPrice +
                                      (item?.myEarningsGems || 0) * gemsPrice
                                    )?.toFixed(4)}
                                  </h6>

                                  <p>
                                    {[
                                      item?.myEarningsEth > 0
                                        ? `${item?.myEarningsEth?.toFixed(
                                            5
                                          )} ETH`
                                        : null,
                                      item?.myEarningsUsdt > 0
                                        ? `${item?.myEarningsUsdt?.toFixed(
                                            2
                                          )} USDT`
                                        : null,
                                      item?.myEarningsUsdc > 0
                                        ? `${item?.myEarningsUsdc?.toFixed(
                                            2
                                          )} USDC`
                                        : null,
                                      item?.myEarningsDopToken > 0
                                        ? `${item?.myEarningsDopToken?.toFixed(
                                            2
                                          )} DOP`
                                        : null,
                                      item?.myEarningsWbtc > 0
                                        ? `${item?.myEarningsWbtc?.toFixed(
                                            6
                                          )} WBTC`
                                        : null,
                                      item?.myEarningsPepe > 0
                                        ? `${item?.myEarningsPepe?.toFixed(
                                            2
                                          )} PEPE`
                                        : null,
                                      item?.myEarningsLink > 0
                                        ? `${item?.myEarningsLink?.toFixed(
                                            2
                                          )} LINK`
                                        : null,
                                      item?.myEarningsUni > 0
                                        ? `${item?.myEarningsUni?.toFixed(
                                            2
                                          )} UNI`
                                        : null,
                                      item?.myEarningsTomi > 0
                                        ? `${item?.myEarningsTomi?.toFixed(
                                            2
                                          )} TOMI`
                                        : null,
                                      item?.myEarningsGems > 0
                                        ? `${item?.myEarningsGems?.toFixed(
                                            1
                                          )} GEMS`
                                        : null,
                                    ]
                                      .filter(Boolean) // Removes null values
                                      .join(" + ")}
                                  </p>
                                  {/* <p>
                                    ({item?.myEarningsEth?.toFixed(5)} ETH +{" "}
                                    {item?.myEarningsUsdt?.toFixed(2)} USDT +{" "}
                                    {item?.myEarningsUsdc?.toFixed(2)} USDC +{" "}
                                    {item?.myEarningsDopToken?.toFixed(2)} DOP +{" "}
                                    {item?.myEarningsWbtc?.toFixed(2)} WBTC +{" "}
                                    {item?.myEarningsPepe?.toFixed(2)} PEPE +{" "}
                                    {item?.myEarningsLink?.toFixed(2)} LINK +{" "}
                                    {item?.myEarningsUni?.toFixed(2)} UNI +{" "}
                                    {item?.myEarningsTomi?.toFixed(2)} TOMI +{" "}
                                    {item?.myEarningsGems?.toFixed(2)} GEMS)
                                  </p> */}
                                </>
                              </div>
                            ) : (
                              <div className="twice-text">
                                <>
                                  <h6>$0</h6>
                                  {/* <p>
                                    (0 ETH + 0 USDT + 0 USDC + 0 DOP + 0 PEPE +
                                    0 LINK + 0 UNI + 0 TOMI)
                                  </p> */}
                                </>
                              </div>
                            )}
                          </div>
                          {/* totalPurchaseUsdt */}
                          <div className="inner-textt">
                            <p>Total Purchases</p>
                            {usdPrice ? (
                              <div className="twice-text">
                                <>
                                  <h6>
                                    $
                                    {(
                                      item?.totalEthDirect * usdPrice +
                                      item?.totalUsdtDirect +
                                      (item?.totalDopTokenDirect || 0) *
                                        dopPrice +
                                      item?.totalWbtcDirect * btcPrice +
                                      item?.totalUsdcDirect +
                                      item?.totalPepeDirect * pepePrice +
                                      item?.totalLinkDirect * linkPrice +
                                      item?.totalUniDirect * uniPrice +
                                      item?.totalTomiDirect * tomiPrice +
                                      item?.totalGemsDirect * gemsPrice
                                    )?.toFixed(4)}
                                  </h6>

                                  <p>
                                    {[
                                      item?.totalEthDirect > 0
                                        ? `${item?.totalEthDirect?.toFixed(
                                            5
                                          )} ETH`
                                        : null,
                                      item?.totalUsdtDirect > 0
                                        ? `${item?.totalUsdtDirect?.toFixed(
                                            2
                                          )} USDT`
                                        : null,
                                      item?.totalUsdcDirect > 0
                                        ? `${item?.totalUsdcDirect?.toFixed(
                                            2
                                          )} USDC`
                                        : null,
                                      item?.totalDopTokenDirect > 0
                                        ? `${item?.totalDopTokenDirect?.toFixed(
                                            2
                                          )} DOP`
                                        : null,
                                      item?.totalWbtcDirect > 0
                                        ? `${item?.totalWbtcDirect?.toFixed(
                                            6
                                          )} WBTC`
                                        : null,
                                      item?.totalPepeDirect > 0
                                        ? `${item?.totalPepeDirect?.toFixed(
                                            2
                                          )} PEPE`
                                        : null,
                                      item?.totalLinkDirect > 0
                                        ? `${item?.totalLinkDirect?.toFixed(
                                            2
                                          )} LINK`
                                        : null,
                                      item?.totalUniDirect > 0
                                        ? `${item?.totalUniDirect?.toFixed(
                                            2
                                          )} UNI`
                                        : null,
                                      item?.totalTomiDirect > 0
                                        ? `${item?.totalTomiDirect?.toFixed(
                                            2
                                          )} TOMI`
                                        : null,
                                      item?.totalGemsDirect > 0
                                        ? `${item?.totalGemsDirect?.toFixed(
                                            1
                                          )} GEMS`
                                        : null,
                                    ]
                                      .filter(Boolean) // Removes null values
                                      .join(" + ")}
                                  </p>
                                </>
                              </div>
                            ) : (
                              <div className="twice-text">
                                <>
                                  <h6>$0</h6>
                                  {/* <p>
                                    (0 ETH + 0 USDT + 0 USDC + 0 DOP + 0 PEPE +
                                    0 LINK + 0 UNI + 0 TOMI)
                                  </p> */}
                                </>
                              </div>
                            )}
                          </div>
                          {sub != true ? (
                            <div className="inner-textt">
                              <button
                                onClick={() => {
                                  setRoutes(true);
                                  setUserDel(item);
                                  setBlockUnblock(item?.isDeleted);
                                }}
                                className="view-btn"
                              >
                                View Details
                              </button>
                            </div>
                          ) : null}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
                // <Accordion.Item key={index} eventKey={index}>
                //   <Accordion.Header>
                //     <div className="accheadermain">
                //       <p className="acctext">
                //         {convertDateFormat(item?.createdAt)?.slice(0, 10)}
                //       </p>
                //       <p className="acctext">{item?.name}</p>
                //       <p className="acctext">
                //         {convertToTitleCase(item?.role)}
                //       </p>
                //     </div>
                //   </Accordion.Header>
                //   <Accordion.Body>
                //     <div className="acctexts">
                //       <h6 className="textleft">Wallet Address</h6>
                //       <p className="textright">
                //         {item?.walletAddress?.slice(0, 11)}...
                //         {item?.walletAddress?.slice(
                //           item?.walletAddress?.length - 3,
                //           item?.walletAddress?.length
                //         )}
                //         <img
                //           style={{ cursor: "pointer" }}
                //           onClick={() => {
                //             navigator.clipboard.writeText(item?.walletAddress);
                //           }}
                //           src="\assets\copy.svg"
                //           alt="img"
                //           className="img-fluid"
                //         />
                //       </p>
                //     </div>
                //     <div className="acctexts">
                //       <h6 className="textleft">DOP Purchased</h6>
                //       <p className="textright">{item?.totalDop} DOP</p>
                //     </div>
                //     <div className="acctexts">
                //       <h6 className="textleft">Leader Earning</h6>
                //       <div className="twice-text">
                //         <h6>
                //           $
                //           {(
                //             item?.myEarningsEth * usdPrice +
                //             item?.myEarningsUsdt
                //           )?.toFixed(4)}
                //         </h6>
                //         <p>
                //           ({item?.myEarningsEth} ETH + {item?.myEarningsUsdt}{" "}
                //           USDT)
                //         </p>
                //       </div>
                //     </div>
                //     <div className="acctexts">
                //       <h6 className="textleft">Total Purchases</h6>
                //       <div className="twice-text">
                //         <h6>
                //           {(
                //             item?.totalEth * usdPrice +
                //             item?.totalUsdt
                //           )?.toFixed(4)}{" "}
                //         </h6>
                //         <p>
                //           ({item?.totalEth} ETH + {item?.totalUsdt} USDT){" "}
                //         </p>
                //       </div>
                //     </div>
                //     <div className="acctexts">
                //       <button
                //          onClick={() => {
                //           setRoutes(true);
                //           setUserDel(item);
                //         }}
                //         className="view-btn"
                //       >
                //         View Details
                //       </button>
                //     </div>
                //   </Accordion.Body>
                // </Accordion.Item>
              );
            })
          ) : leadersLoader ? (
            <tr>
              <td colSpan="12" className="text-center">
                <div className="text-center">
                  <div class="d-flex align-items-center justify-content-center">
                    <div class="spinner-border text-info" role="status">
                      <span class="sr-only"></span>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          ) : (
            <tr>
              <td colSpan="10" className="text-center">
                <div className="text-center">
                  <h6
                    className=""
                    style={{
                      color: "#000",
                      textAlign: "center",
                      marginTop: 60,
                    }}
                  >
                    No data found!
                  </h6>
                </div>
              </td>
            </tr>
          )}
          {pagination ? (
            <div className="footer-content mt-2">
              <div className="left-f">
                {/* <h6>SHOWING 1-10 OF 145</h6> */}
                {/* <h6>SHOWING 1-10 OF 145</h6> */}
              </div>
              <div className="right-f">
                <nav aria-label="Page navigation example">
                  <ul class="pagination">
                    <nav aria-label="Page navigation example">
                      <ul class="text-center">
                        <ReactPaginate
                          previousLabel={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M7.38916 1.93555L4.88776 1.93555L1.49471 7.99682L3.99612 7.99682L7.38916 1.93555Z"
                                fill="#329879"
                              />
                              <path
                                d="M7.38916 14.0703L4.88776 14.0703L1.49471 8.00904L3.0712 6.96117L3.99612 8.00904L7.38916 14.0703Z"
                                fill="#329879"
                              />
                              <path
                                d="M7.51642 6.85742H14.4615L13.1868 9.14313H6.2417L7.51642 6.85742Z"
                                fill="#329879"
                              />
                            </svg>
                          }
                          nextLabel={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M8.61084 1.93555L11.1122 1.93555L14.5053 7.99682L12.0039 7.99682L8.61084 1.93555Z"
                                fill="#329879"
                              />
                              <path
                                d="M8.61084 14.0703L11.1122 14.0703L14.5053 8.00904L12.9288 6.96117L12.0039 8.00904L8.61084 14.0703Z"
                                fill="#329879"
                              />
                              <path
                                d="M2.81306 6.85742H9.75811L8.48338 9.14313H1.53833L2.81306 6.85742Z"
                                fill="#329879"
                              />
                            </svg>
                          }
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={handlePageChange}
                          containerClassName="pagination"
                          activeClassName="active"
                          forcePage={page}
                        ></ReactPaginate>
                      </ul>
                    </nav>
                  </ul>
                </nav>
              </div>
            </div>
          ) : null}
        </div>
      </section>
      <Modal
        className="global-modal-scss tokenslist-modal"
        show={show1}
        onHide={handleClose1}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Total Purchases</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-tokendata">
            <div className="upper-twice-card">
              <div className="single-card">
                <p>Leader Name</p>
                <h6>{modalDetail?.name}</h6>
              </div>
              <div className="single-card">
                <p>Total Purchases</p>
                <h6>~${formatTokenValue(modalDetail?.totalAmountInUsd)} </h6>
              </div>
            </div>
            <div className="bottom-twice">
              <div className="upper-head">
                <p>Token</p>
                <p>Amount</p>
              </div>
              {tokenData
                .map(({ key, name, img, decimals }) => ({
                  value: formatTokenValue(modalDetail?.[key], decimals),
                  name,
                  img,
                }))
                .filter(({ value }) => value !== null) // Remove zero values
                .map(({ name, value, img }) => (
                  <div className="bottom-tokens" key={name}>
                    <div className="left-token">
                      <img src={img} alt={name} className="img-fluid" />
                      <h6>{name}</h6>
                    </div>
                    <div className="right-token">
                      <p>
                        {value} {name}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className="twice-btn">
            <button
              className="btn-add w-100"
              style={{ flex: "0 0 100%" }}
              onClick={() => {
                handleClose1();
              }}
            >
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="global-modal-scss tokenslist-modal"
        show={show2}
        onHide={handleClose2}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Leader Earnings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-tokendata">
            <div className="upper-twice-card">
              <div className="single-card">
                <p>Leader Name</p>
                <h6>{modalDetail?.name}</h6>
              </div>
              <div className="single-card">
                <p>Total Earnings</p>
                <h6>~${formatTokenValue(modalDetail?.totalAmountEarned)} </h6>
              </div>
            </div>
            <div className="bottom-twice">
              <div className="upper-head">
                <p>Token</p>
                <p>Amount</p>
              </div>
              {tokenDataEarnings
                .map(({ key, name, img, decimals }) => ({
                  value: formatTokenValue(modalDetail?.[key], decimals),
                  name,
                  img,
                }))
                .filter(({ value }) => value !== null) // Remove zero values
                .map(({ name, value, img }) => (
                  <div className="bottom-tokens" key={name}>
                    <div className="left-token">
                      <img src={img} alt={name} className="img-fluid" />
                      <h6>{name}</h6>
                    </div>
                    <div className="right-token">
                      <p>
                        {value} {name}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className="twice-btn">
            <button
              className="btn-add w-100"
              style={{ flex: "0 0 100%" }}
              onClick={() => {
                handleClose2();
              }}
            >
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DataTable;
